import { useEffect, useState } from 'react'
import {
  Backdrop,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { ScrollRestoration, useNavigate } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { SentimentVeryDissatisfied } from '@mui/icons-material'
import { CompilationContainer } from '../../model/CompilationContainer'
import { getAvailableSurveys } from '../../services/surveyCompilationServices'
import { Box } from '@mui/system'
import cover from '../../assets/Customer-Survey.gif'

export function AvailableSurveys() {
  const [format, setFormat] = useState<string>('DD-MM-YYYY / HH:mm')
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const [surveys, setSurveys] = useState<CompilationContainer[] | undefined>(undefined)
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!surveys) {
      setLoading(true)
      getAvailableSurveys()
        .then((surveys) => {
          setSurveys(surveys)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [surveys, setSurveys])

  function redirectToCompilation(id: string) {
    navigate('/survey/available-surveys/' + id + '/compilation')
  }

  function formatDateChip(date: Dayjs, label: string) {
    return (
      <Chip
        sx={{
          marginTop: 1,
        }}
        label={label + dayjs(date).tz('Europe/Rome').format(format)}
        size={'small'}
      />
    )
  }

  return (
    <div style={{ height: '100vh' }}>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
          maxHeight: '89dvh',
          paddingTop: 1,
          display: 'flex',
          flexDirection: 'column',
          ml: { xs: 4 },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            alignSelf: 'center',
            width: { xs: '80%', md: '50%' },
            padding: 1,
            backgroundColor: 'transparent',
          }}
        >
          {loading && (
            <div style={{ height: '100vh' }}>
              <Backdrop open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}

          <Box
            sx={{
              height: surveys && surveys.length > 0 ? '20vh' : '40vh',
              width: '100%',
              display: 'flex',
              backgroundImage: `url(${cover})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              mb: 4,
            }}
          ></Box>

          {surveys && (
            <>
              {surveys.length > 0 ? (
                <Stack direction={'column'} spacing={1} alignItems={'center'}>
                  {surveys.map((survey: CompilationContainer, index: number) => {
                    return (
                      <Card sx={{ boxShadow: 4, width: '85%' }} key={index}>
                        <CardActionArea onClick={() => redirectToCompilation(survey.id)}>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={12} md={10}>
                                <Typography variant="h5" sx={{ wordBreak: 'break-word' }} paragraph>
                                  Survey: {survey.template.title}
                                </Typography>
                              </Grid>
                              <Grid item xs={5} md={2}>
                                <Chip
                                  sx={{
                                    width: '100%',
                                  }}
                                  label={survey.type === 'DEFAULT' ? 'Default' : 'Anonymous'}
                                  size={'small'}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {formatDateChip(survey.endingDate, 'Ending Date: ')}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    )
                  })}
                </Stack>
              ) : (
                <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant={'h5'}>No available surveys &nbsp;</Typography>
                  <SentimentVeryDissatisfied />
                </Container>
              )}
            </>
          )}
        </Paper>
      </Container>
      <ScrollRestoration />
    </div>
  )
}
