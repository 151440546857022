import React from 'react'
import { useEffect, useState } from 'react'
import { Backdrop, Button, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import dayjs from 'dayjs'
import { Template } from '../../../model/Template'
import { createTemplate, deleteTemplate, getTemplates } from '../../../services/surveyCompilationServices'
import TemplateCard from './TemplateCard'
import { TemplateCreatorModal } from './TemplateCreatorModal'
import ConfirmDialog from '../ConfirmDialog'
import { publishApiError } from '../../../services/eventSercices'
import { ApiError } from '../../../errors/ApiError'
import { Box, useTheme } from '@mui/system'
import SearchBar from '../../../utilsComponents/SearchBar'

type TemplateListProps = {
  setSelectedTemplate: (template?: Template) => void
  onCompilationContainerCreate: (template: Template) => void
  modifiedTemplate?: Template
  setModifiedTemplate: (modifiedTemplate?: Template) => void
}

export function TemplateList(props: TemplateListProps) {
  const [templates, setTemplates] = useState<Template[] | undefined>(undefined)
  const [showTemplateCreator, setShowTemplateCreator] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('')
  const [erroreMessages, setErroreMessages] = useState<string>('')

  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()

  const paramId = searchParams.get('templateId')

  useEffect(() => {
    getTemplates()
      .then((response) => {
        const sortedTemplates = response.sort((a, b) => {
          const aDate = dayjs(a.creationDate)
          const bDate = dayjs(b.creationDate)
          if (bDate.isBefore(aDate)) {
            return -1
          }
          return aDate.isAfter(bDate) ? 1 : 0
        })
        setTemplates(sortedTemplates)
      })
      .catch((error: ApiError) => {
        publishApiError(error.details)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    if (paramId) {
      props.setSelectedTemplate(templates?.find((template) => template.id === paramId))
    }
  }, [paramId, props, templates])

  useEffect(() => {
    if (templates) {
      if (paramId) {
        props.setSelectedTemplate(templates?.find((template) => template.id === paramId))
      }
    }
  }, [templates, paramId, props])

  useEffect(() => {
    // console.log("Changed prop ")
    // console.log(templates)
    // console.log(props)
    if (templates && props.modifiedTemplate) {
      templates.forEach((template) => {
        if (template.id === props.modifiedTemplate?.id) {
          template.title = props.modifiedTemplate?.title
          template.description = props.modifiedTemplate?.description
          template.referencePeriod = props.modifiedTemplate?.referencePeriod
          template.lastModificationDate = props.modifiedTemplate?.lastModificationDate
        }
      })
      props.setModifiedTemplate(undefined)
    }
  }, [props, props.modifiedTemplate, templates])

  function addTemplate(template: Template) {
    if (templates === undefined) {
      setTemplates([template])
      return
    }
    templates.unshift(template)
    setTemplates(templates)
    setSearchParams({ templateId: template.id })
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)
  const [onConfirm, setOnConfirm] = useState<(() => void) | undefined>(undefined)

  function handleDeleteTemplate(template: Template) {
    setOpenConfirmDialog(true)
    setOnConfirm(() => () => {
      deleteTemplate(template.id).then(() => {
        const newTemplates = templates?.filter((templateRecord) => templateRecord.id !== template.id)
        setTemplates(newTemplates)
        if (searchParams.get('templateId') === template.id) {
          props.setSelectedTemplate(undefined)
          setSearchParams({})
        }
      })
    })
  }

  function handleTemplateClick(template: Template) {
    setSelectedTemplateId(template.id)
    setSearchParams({ templateId: template.id })
  }

  async function handleTemplateDuplicate(template: Template) {
    if (!templates) {
      return
    }
    const baseTitle = template.title.replace(/\s*\(\d+\)$/, '')
    let newTitle = `${baseTitle} (1)`
    let i = 1

    while (templates.find((t) => t.title === newTitle)) {
      i++
      newTitle = `${baseTitle} (${i})`
    }

    try {
      const response = await createTemplate(
        newTitle,
        template.description,
        template.referencePeriod,
        template.owner,
        template.questions
      )
      addTemplate(response)
      setErroreMessages('')
    } catch (error: any) {
      let errorMessage = 'Errore sconosciuto.'
      if (error instanceof Error) {
        errorMessage = error.message
      } else if (error.response && error.response.data) {
        errorMessage = error.response.data
      }
      setErroreMessages(errorMessage)
    }
  }

  const [searchTitle, setSearchTitle] = useState('')
  const [searchReferencePeriod, setSearchReferencePeriod] = useState('')
  const [searchCreationDate, setSearchCreationDate] = useState('')
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSearchChange = (type: string) => (event: any, newValue: React.SetStateAction<string>) => {
    if (type === 'title') setSearchTitle(newValue)
    else if (type === 'referencePeriod') setSearchReferencePeriod(newValue)
    else if (type === 'creationDate') setSearchCreationDate(newValue)
  }

  const filteredTemplates = (templates || []).filter((template) => {
    const formattedCreationDate = dayjs(template.creationDate).format('DD-MM-YYYY')
    return (
      template.title.toLowerCase().includes(searchTitle.toLowerCase()) &&
      template.referencePeriod.toLowerCase().includes(searchReferencePeriod.toLowerCase()) &&
      formattedCreationDate.includes(searchCreationDate)
    )
  })

  // console.log(templates);

  const groupTemplatesByReferencePeriod = (templates: Template[]) => {
    return templates.reduce((acc: Record<string, Template[]>, template: Template) => {
      const { referencePeriod } = template
      acc[referencePeriod] = acc[referencePeriod] || []
      acc[referencePeriod].push(template)
      return acc
    }, {})
  }

  const groupedTemplates = groupTemplatesByReferencePeriod(filteredTemplates)

  return (
    <>
      <Grid
        xs={12}
        item
        sx={{
          margin: '0 auto',
          mb: 4,
        }}
      >
        {templates && templates.length > 0 && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                pt: 3,
                mb: 1,
                pb: 2,
                top: { xs: 6, sm: 8 },
                zIndex: 1060,
              }}
            >
              <SearchBar
                options={(templates || []).map((template) => template.title)}
                handleSearchChange={handleSearchChange('title')}
                label="Cerca per Titolo"
                width={{ xs: '60%', sm: '50%', md: '30%' }}
              />

              <Button
                size="medium"
                variant="outlined"
                onClick={() => {
                  setShowTemplateCreator(!showTemplateCreator)
                }}
              >
                {isXsScreen ? <AddIcon /> : 'Aggiungi Template'}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
          </>
        )}

        {Object.entries(groupedTemplates)
          .sort((a, b) => Number(b[0]) - Number(a[0]))
          .map(([referencePeriod, templatesOfPeriod], index, array) => (
            <React.Fragment key={referencePeriod}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                  ReferencePeriod {referencePeriod}
                </Typography>
                <Grid container spacing={2} justifyContent="flex-start">
                  {templatesOfPeriod.map((template: Template) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={template.id}>
                      <TemplateCard
                        template={template}
                        onCompilationContainerCreate={props.onCompilationContainerCreate}
                        onCardClick={handleTemplateClick}
                        onDelete={handleDeleteTemplate}
                        onDuplicate={handleTemplateDuplicate}
                        isSelected={selectedTemplateId === template.id}
                      />
                    </Grid>
                  ))}
                </Grid>
                {index !== array.length - 1 && <Divider variant="middle" sx={{ mt: 8 }} />}
              </Grid>
            </React.Fragment>
          ))}

        {!templates && (
          <div style={{ height: '100vh' }}>
            <Backdrop open={!templates}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}

        {templates !== undefined && templates.length === 0 && (
          <Box>
            There are no templates here, you should create one &nbsp;
            <Typography
              variant={'body1'}
              component={'span'}
              onClick={() => setShowTemplateCreator(!showTemplateCreator)}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              here
            </Typography>
          </Box>
        )}
      </Grid>

      <TemplateCreatorModal
        open={showTemplateCreator}
        onClose={() => setShowTemplateCreator(false)}
        addTemplate={addTemplate}
      />

      <ConfirmDialog
        openValue={openConfirmDialog}
        onConfirm={onConfirm}
        message={'Are you sure you want to delete this template?'}
        onClose={() => {
          setOpenConfirmDialog(false)
          setOnConfirm(undefined)
        }}
      />
    </>
  )
}
