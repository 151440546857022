import React from 'react'
import { Alert, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { Question, TextualQuestion } from '../../../../model/Question'
import { TitleField } from './fields/TitleField'
import MandatoryCheckBox from './fields/MandatoryCheckBox'
// import {Question, TextualQuestion} from "../../../model/Question.ts";
// import {TitleField} from "./fields/TitleField.tsx";
// import MandatoryCheckBox from "./fields/MandatoryCheckBox.tsx";

type TextualQuestionFormProps = {
  questionToUpdate?: Question
  newQuestionCallBack: (question: TextualQuestion, errors: boolean) => void
  submit: boolean
  onSubmitErrors: boolean
  setSubmitErrors: (value: boolean) => void
}

export default function TextualQuestionForm({
  questionToUpdate,
  newQuestionCallBack,
  submit,
  onSubmitErrors,
  setSubmitErrors,
}: TextualQuestionFormProps) {
  const [title, setTitle] = useState<string>(questionToUpdate?.title ?? '')
  const [defaultInput, setDefaultInput] = useState<boolean>(true)
  const [isMandatory, setIsMandatory] = useState<boolean>(questionToUpdate?.isMandatory ?? false)
  const [titleError, setTitleError] = useState<boolean>(false)
  let newQuestion: TextualQuestion
  const titleErrorMessage: string = 'Question title is mandatory and cannot start with a space'

  useEffect(() => {
    newQuestion = {
      title: title,
      isMandatory: isMandatory,
      questionType: 'TEXTUAL',
    }
    newQuestionCallBack(newQuestion, titleError)
  }, [submit])

  useEffect(() => {
    checkTitleError()
    setDefaultInput(false)
  }, [title, titleError, isMandatory])

  function handleMandatoryCheckBox(value: boolean) {
    setIsMandatory(value)
  }

  function checkTitleError() {
    if ((title.length === 0 || title.startsWith(' ')) && !defaultInput) {
      setTitleError(true)
      return
    }
    setTitleError(false)
  }

  const handleTitleChange = (event: string) => {
    setTitle(event)
    setSubmitErrors(false)
  }

  return (
    <Grid margin={'auto'} container rowSpacing={1} width={'100%'}>
      <Grid item xs={12}>
        <TitleField title={title} setTitleCallBack={handleTitleChange} titleError={titleError} />
      </Grid>
      <Grid item lg={3} md={3} xs={12}>
        <MandatoryCheckBox isMandatory={isMandatory} handleOnClick={handleMandatoryCheckBox}></MandatoryCheckBox>
      </Grid>

      {(titleError || onSubmitErrors) && (
        <Grid item lg={9} md={9} xs={12}>
          <Alert variant="filled" severity="error">
            {onSubmitErrors && 'Error during submitting'}
            {!onSubmitErrors && titleErrorMessage}
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}
