import { Drawer, Grid } from '@mui/material'
import { useState } from 'react'
import { Template } from '../../model/Template'
import { ScrollRestoration, useSearchParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { TemplateList } from '../../components/surveyComponents/templates/TemplateList'
import FullTemplate from '../../components/surveyComponents/templates/FullTemplate'
import { CreateCompilationContainer } from '../../components/surveyComponents/compilations/CreateCompilationContainer'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { styled } from '@mui/material/styles'
import React from 'react'

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: theme.spacing(7) + 1,
  ...(open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: '90vw',
    [theme.breakpoints.up('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '60dvh',
    },
  }),
  ...(!open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  }),
}))

export default function Templates() {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined)

  const [compilationContainerTemplate, setCompilationContainerTemplate] = useState<Template | undefined>(undefined)

  const [modifiedTemplate, setModifiedTemplate] = useState<Template | undefined>(undefined)

  const [, setSearchParams] = useSearchParams()

  function handleCompilationContainerCreate(template: Template) {
    setCompilationContainerTemplate(template)
  }

  function clearSelectedTemplate() {
    setSelectedTemplate(undefined)
    setSearchParams({})
  }

  function handleTemplateChange(template: Template) {
    // console.log("handleTemplateChange: ", template)
    setModifiedTemplate(template)
  }

  function getSelectedTemplateNode() {
    if (!selectedTemplate) {
      return null
    }
    return (
      <StyledDrawer
        anchor={'right'}
        open
        onClose={clearSelectedTemplate}
        sx={{
          zIndex: 1300,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            display: 'flex',
            alignItems: 'flex-start',
            width: { xs: '90vw', sm: '80vw', md: '60vw' },
          },
        }}
      >
        <IconButton
          onClick={clearSelectedTemplate}
          sx={{
            marginLeft: 1,
            marginTop: 1,
            '& .MuiSvgIcon-root': {
              fontSize: 40,
            },
          }}
        >
          <ChevronRightIcon />
        </IconButton>
        <FullTemplate template={selectedTemplate} onTemplateChange={handleTemplateChange} disableButtons={false} />
      </StyledDrawer>
    )
  }

  return (
    <Grid
      container
      item
      xs={11}
      sx={{
        mt: '2.5em',
        display: 'flex',
        marginX: 'auto',
      }}
    >
      <TemplateList
        onCompilationContainerCreate={handleCompilationContainerCreate}
        setSelectedTemplate={setSelectedTemplate}
        modifiedTemplate={modifiedTemplate}
        setModifiedTemplate={setModifiedTemplate}
      />

      {getSelectedTemplateNode()}

      <CreateCompilationContainer
        open={compilationContainerTemplate !== undefined}
        templateId={compilationContainerTemplate?.id || ''}
        onClose={() => setCompilationContainerTemplate(undefined)}
      />

      <ScrollRestoration />
    </Grid>
  )
}
