import React from 'react'
import { Box, TextField } from '@mui/material'
import { TextualQuestion } from '../../../../model/Question'
import { AnswerType } from '../TemplateCompilation'
// import {TextualQuestion} from "../../../model/Question.ts";
// import {AnswerType} from "../TemplateCompilation.tsx";

type TextualAnswerProps = {
  question: TextualQuestion
  value: string
  onChange: (value: AnswerType, index: number) => void
  index: number
  error: boolean
  onError: (error: boolean, index: number) => void
}

export function TextualAnswer(props: TextualAnswerProps) {
  function handleValueChange(value: string) {
    props.onError(props.question.isMandatory && value === '', props.index!)
    props.onChange?.(value, props.index!)
  }

  return (
    <Box>
      <TextField
        required={props.question.isMandatory}
        label={'Answer'}
        fullWidth={true}
        value={props.value}
        onChange={(event) => handleValueChange(event.target.value)}
        error={props.error}
      />
    </Box>
  )
}
