// import {PieGraph} from "../../../model/Graph.ts";
import { useState } from 'react'
import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart'
import { DefaultizedPieValueType } from '@mui/x-charts'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { PieGraph } from '../../../../model/Graph'

export type PieData = {
  id?: number
  value: number
  label: string
}

export function Pie(graph: PieGraph) {
  const [data] = useState(initData())
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  function initData(): PieData[] {
    const newData: PieData[] = []
    Object.keys(graph.values).forEach((key) => {
      newData.push({
        value: graph.values[key],
        label: key,
      })
    })
    return newData
  }

  function arcLabel(item: DefaultizedPieValueType): string {
    let value = item.value.toString()
    if (item.value.toString().length > 5) {
      value = value.substring(0, 5)
    }
    return `${value}%`
  }

  return (
    <PieChart
      series={[
        {
          data: data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          arcLabel: arcLabel,
          arcLabelMinAngle: 20,
        },
      ]}
      margin={
        isMobile
          ? { top: 0, right: 0, bottom: 0, left: 0 }
          : {
              top: 50,
              right: 50,
              bottom: 20,
              left: 50,
            }
      }
      height={300}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontWeight: 'bold',
        },
      }}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
          hidden: isMobile,
        },
      }}
    />
  )
}
