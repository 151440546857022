import { FormControlLabel, FormHelperText } from '@mui/material'
// import {MultipleChoiceQuestion, WeightedChoice} from "../../../model/Question.ts";
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import React, { useEffect, useState } from 'react'
import { MultipleChoiceQuestion, WeightedChoice } from '../../../../model/Question'

type MultipleChoiceAnswerProps = {
  question: MultipleChoiceQuestion
  value: string[]
  onChange: (value: string[], index: number) => void
  index: number
  error: boolean
  onError: (error: boolean, index: number) => void
}

export function MultipleChoiceAnswer(props: MultipleChoiceAnswerProps) {
  const [checked, setChecked] = useState<boolean[]>(initChecked)
  const hasMinAndMax = props.question.minChoices !== 0 && props.question.maxChoices !== 0

  function initChecked(): boolean[] {
    const result: boolean[] = []
    props.question.choiceSelection.choices.forEach(() => {
      result.push(false)
    })
    return result
  }

  function handleCheckChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const newChecked = [...checked]
    newChecked[index] = event.target.checked
    setChecked(newChecked)
    if (hasMinAndMax) {
      if (!props.question.isMandatory && newChecked.filter((value) => value).length === 0) {
        props.onError(false, props.index)
      } else {
        props.onError(
          newChecked.filter((value) => value).length < props.question.minChoices ||
            newChecked.filter((value) => value).length > props.question.maxChoices,
          props.index
        )
      }
    }
  }

  useEffect(() => {
    const newValue: string[] = []
    checked.forEach((value, index) => {
      if (value) {
        const choice = props.question.choiceSelection.choices[index]
        typeof choice === 'string' ? newValue.push(choice) : newValue.push((choice as WeightedChoice).choice)
      }
    })
    props.onChange(newValue, props.index)
  }, [checked])

  function getLabelFromChoice(choice: string | WeightedChoice) {
    return typeof choice === 'string' ? choice : (choice as WeightedChoice).choice
  }

  function getNodes() {
    return props.question.choiceSelection.choices.map((choice, index) => {
      return (
        <FormControlLabel
          key={index}
          control={<Checkbox checked={checked[index]} onChange={(event) => handleCheckChange(event, index)} />}
          label={getLabelFromChoice(choice)}
          value={choice}
        />
      )
    })
  }

  return (
    <FormControl error={props.error}>
      {hasMinAndMax && (
        <FormHelperText>
          min: {props.question.minChoices}, max: {props.question.maxChoices}
        </FormHelperText>
      )}
      {getNodes()}
    </FormControl>
  )
}
