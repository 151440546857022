import { Card, CardActions, CardContent, Chip, Grid, Typography } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { MultipleChoiceQuestion, NumericQuestion, Question, SingleChoiceQuestion } from '../../../../model/Question'
import { NumericQuestionCard } from './NumericQuestionCard'
import { SingleChoiceQuestionCard } from './SingleChoiceQuestionCard'
import { MultipleChoiceQuestionCard } from './MultipleChoiceQuestionCard'
import QuestionForm from '../form/QuestionForm'

type QuestionCardProps = {
  question: Question
  onClickDelete: (questionIndex: number) => void
  updateQuestion: (question: Question, index: number) => void
  index: number
  disableButtons: boolean
}

export function QuestionCard(props: QuestionCardProps) {
  const [openUpdateQuestionDialog, setOpenUpdateQuestionDialog] = useState(false)

  function getQuestionCard(question: Question) {
    return (function () {
      switch (question.questionType) {
        case 'NUMERIC':
          return <NumericQuestionCard question={question as NumericQuestion} />
        case 'SINGLE_CHOICE':
          return <SingleChoiceQuestionCard question={question as SingleChoiceQuestion} />
        case 'MULTIPLE_CHOICE':
          return <MultipleChoiceQuestionCard question={question as MultipleChoiceQuestion} />
        default:
          return null
      }
    })()
  }

  function getQuestionType() {
    let result = props.question.questionType.toLowerCase()
    result = result.replace('_', ' ')
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const [mandatoryLabel, setMandatoryLabel] = useState<string>(props.question.isMandatory ? 'Mandatory' : 'Optional')
  const [questionType, setQuestionType] = useState<string>(getQuestionType)

  function handleDelete() {
    props.onClickDelete(props.index)
  }

  function handleOpen() {
    setOpenUpdateQuestionDialog(true)
  }

  return (
    <Card
      raised
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid gap={3} sx={{ display: 'flex', flexDirection: 'row' }} id="itemChip">
            <Chip label={mandatoryLabel} size={'small'} />
            <Chip label={questionType} size={'small'} />
          </Grid>

          <Grid sx={{ display: 'flex' }}>
            <Typography
              gutterBottom
              sx={{
                wordBreak: 'break-word',
                pt: 2,
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
                hyphens: 'auto',
              }}
              paragraph
              variant="h6"
            >
              {props.question.title}
            </Typography>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
            {getQuestionCard(props.question)}
          </Grid>
        </Grid>
      </CardContent>
      {!props.disableButtons && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <IconButton size={'small'} onClick={handleOpen}>
            <Edit />
          </IconButton>
          <IconButton size={'small'} onClick={handleDelete}>
            <Delete />
          </IconButton>
        </CardActions>
      )}
      {openUpdateQuestionDialog && (
        <QuestionForm
          setClose={() => setOpenUpdateQuestionDialog(false)}
          updateQuestion={props.updateQuestion}
          index={props.index}
          questionToUpdate={props.question}
          setCardQuestionType={setQuestionType}
          setCardMandatoryLabel={setMandatoryLabel}
        />
      )}
    </Card>
  )
}
