import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Button, ButtonGroup, Grid } from '@mui/material'
import ListChiusure from '../../components/reservationComponents/ListChiusure'
import NuovoCalendario from '../../components/reservationComponents/NuovoCalendario'
import SaveIcon from '@mui/icons-material/Save'
import RestorePageRoundedIcon from '@mui/icons-material/RestorePageRounded'
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import { getChiusure, postChiusure } from '../../services/reservationServices'
import { useTheme } from '@emotion/react'

function FormChiusureSettimanali(props) {
  const theme = useTheme()
  const [chiusure, setChiusure] = useState([])
  const [doRefresh, setDoRefresh] = useState(true)
  const [disableSave, setDisableSave] = useState(false)

  useEffect(() => {
    if (!doRefresh) {
      return
    }
    setDoRefresh(false)
    getChiusure()
      .then((chiusure) => {
        setChiusure(chiusure)
        setDisableSave(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [doRefresh])

  const handleRestore = () => {
    setDoRefresh(true)
  }

  const handleSave = () => {
    postChiusure(chiusure)
      .then((chiusure) => {
        setChiusure(chiusure)
        setDisableSave(true)
      })
      .catch((err) => {
        let errorMessage = 'Non è stato possibile salvare le modifiche.'
        if (err.response && err.response.data) {
          errorMessage = err.response.data.message || errorMessage
        }
        console.log(err)
      })
  }

  const handleClear = () => {
    setChiusure([])
    setDisableSave(false)
  }

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: '2.5em',
        display: 'flex',
        flexWrap: { xs: 'wrap', sm: 'wrap' },
        marginX: 'auto',
      }}
    >
      <Grid item display={'flex'} flexDirection={'column'} xs={12} sm={8} md={7} lg={5}>
        <NuovoCalendario
          shouldDisableDate={isDateDisabled}
          selectedDays={chiusure}
          onChangee={(nuovechiusure) => {
            setChiusure(nuovechiusure.sort())
            setDisableSave(false)
          }}
        />
        <Grid
          item
          id="gridButton"
          display={'flex'}
          justifyContent={{ xs: 'space-around', sm: 'space-around' }}
          sx={{ height: '50px' }}
        >
          <ButtonGroup id="buttonGroup" sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleSave} disabled={disableSave}>
              <SaveIcon />
            </Button>
            <Button variant="outlined" onClick={handleRestore} disabled={chiusure.length === 0}>
              <RestorePageRoundedIcon />
            </Button>
            <Button variant="outlined" onClick={handleClear} disabled={chiusure.length === 0}>
              <BackspaceRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid
        item
        display={'flex'}
        flexDirection={'column'}
        alignItems={{ xs: 'center', sm: 'center' }}
        xs={12}
        sm={12}
        md={4}
        lg={7}
      >
        <h2>Date selezionate : </h2>
        <ListChiusure
          value={chiusure}
          onValueChange={(newValue) => {
            setChiusure(newValue)
            setDisableSave(false)
          }}
        />
      </Grid>
    </Grid>
  )
}

export default FormChiusureSettimanali

const chiusureSettimanali = ['SUNDAY', 'SATURDAY']

const currentLocalDate = dayjs().format('YYYY-MM-DD')
const isDateDisabled = (dateDayjs) => {
  const localDate = dateDayjs.format('YYYY-MM-DD')
  if (localDate < currentLocalDate) {
    return true
  }
  if (
    chiusureSettimanali.includes(
      ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][dateDayjs.day()]
    )
  ) {
    return true
  }
  return false
}
