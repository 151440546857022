import React, { useEffect, useState } from 'react'
import { Alert, Grid, TextField, Typography } from '@mui/material'
import { ChoiceSelection, MultipleChoiceQuestion } from '../../../../model/Question'
import { checkTitleError } from './utils/FieldsInputCheck'
import { TitleField } from './fields/TitleField'
import ChoiceFields from './fields/ChoiceFields'
import MandatoryCheckBox from './fields/MandatoryCheckBox'
// import {ChoiceSelection, MultipleChoiceQuestion} from "../../../model/Question.ts";
// import {checkTitleError} from "./utils/FieldsInputCheck.ts";
// import {TitleField} from "./fields/TitleField.tsx";
// import MandatoryCheckBox from "./fields/MandatoryCheckBox.tsx";
// import ChoiceFields from "./fields/ChoiceFields.tsx";

type MultipleCHOICEQuestionFormProps = {
  questionToUpdate?: MultipleChoiceQuestion
  newQuestionCallBack: (question: MultipleChoiceQuestion, errors: boolean) => void
  submit: boolean
  onSubmitErrors: boolean
  setSubmitErrors: (value: boolean) => void
}

export function MultipleChoiceQuestionForm({
  questionToUpdate,
  newQuestionCallBack,
  submit,
  setSubmitErrors,
  onSubmitErrors,
}: MultipleCHOICEQuestionFormProps) {
  const [title, setTitle] = useState<string>(questionToUpdate?.title ?? '')
  const [minChoices, setMinChoices] = useState<number>(questionToUpdate?.minChoices ?? 0)
  const [maxChoices, setMaxChoices] = useState<number>(questionToUpdate?.maxChoices ?? 0)
  const [isMandatory, setIsMandatory] = useState<boolean>(questionToUpdate?.isMandatory ?? false)
  const [questionChoicesSelection, setQuestionChoicesSelection] = useState<ChoiceSelection>(
    questionToUpdate?.choiceSelection ?? {
      type: 'SIMPLE',
      choices: ['', ''],
    }
  )
  const [defaultInput, setDefaultInput] = useState<boolean>(true)
  const [minChoicesError, setMinChoicesError] = useState<boolean>(false)
  const [maxChoicesError, setMaxChoicesError] = useState<boolean>(false)
  const [titleError, setTitleError] = useState<boolean>(false)
  const [choicesError, setChoicesError] = useState<boolean>(false)

  useEffect(() => {
    const newQuestion: MultipleChoiceQuestion = {
      title: title,
      isMandatory: isMandatory,
      questionType: 'MULTIPLE_CHOICE',
      choiceSelection: questionChoicesSelection,
      minChoices: minChoices,
      maxChoices: maxChoices,
    }
    newQuestionCallBack(newQuestion, titleError || minChoicesError || maxChoicesError || choicesError)
  }, [
    choicesError,
    isMandatory,
    maxChoices,
    maxChoicesError,
    minChoices,
    minChoicesError,
    newQuestionCallBack,
    questionChoicesSelection,
    submit,
    title,
    titleError,
  ])

  useEffect(() => {
    setTitleError(checkTitleError(title, defaultInput))
    setDefaultInput(false)
  }, [defaultInput, title])

  useEffect(() => {
    setMinChoicesError(false)
    if (minChoices < 0 || minChoices > maxChoices || (isMandatory && minChoices === 0)) {
      setMinChoicesError(true)
    }
    setMaxChoicesError(false)
    if (maxChoices < minChoices || maxChoices > questionChoicesSelection.choices.length) {
      setMaxChoicesError(true)
    }
  }, [isMandatory, minChoices, maxChoices, questionChoicesSelection.choices.length])

  const handleTitleChange = (event: string) => {
    setTitle(event)
    setSubmitErrors(false)
  }

  const handleValuesFieldChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'maxValueField') setMaxChoices(Number(event.target.value))
    else setMinChoices(Number(event.target.value))
    setSubmitErrors(false)
  }

  const handleErrorMessages = () => {
    if (onSubmitErrors) return 'Error during submitting'
    if (titleError) return 'The title must be provided and should not begin with a space.'
    if (minChoicesError) return 'The minimum value should be both positive and less than the maximum value'
    return 'The maximum value should be less than the maximum number of choices and greater than the minimum value.'
  }

  return (
    <Grid margin={'auto'} container rowSpacing={1} width={'100%'} columns={15}>
      <Grid item xs={15}>
        <TitleField title={title} setTitleCallBack={handleTitleChange} titleError={titleError} />
      </Grid>
      <Grid item xs={15}>
        <ChoiceFields
          questionChoicesSelection={questionChoicesSelection}
          setQuestionChoicesSelection={setQuestionChoicesSelection}
          setChoicesError={setChoicesError}
        />
      </Grid>
      <Grid item xs={15} md={7} mt={1.5}>
        <TextField
          label={'Min Number Of Answer'}
          type={'number'}
          placeholder={'Insert minimum value'}
          defaultValue={minChoices}
          error={minChoicesError}
          id={'minValueField'}
          onChange={handleValuesFieldChanges}
          sx={{
            width: '100%',
          }}
        ></TextField>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={15} md={7} mt={1.5}>
        <TextField
          label={'Max Number of Answer'}
          type={'number'}
          placeholder={'Insert maximum value'}
          defaultValue={maxChoices}
          error={maxChoicesError}
          onChange={handleValuesFieldChanges}
          id={'maxValueField'}
          sx={{
            width: '100%',
          }}
        ></TextField>
      </Grid>
      <Grid item lg={4} md={4} xs={15}>
        <MandatoryCheckBox isMandatory={isMandatory} handleOnClick={setIsMandatory}></MandatoryCheckBox>
      </Grid>
      {(titleError || minChoicesError || maxChoicesError || onSubmitErrors) && (
        <Grid item md={11} xs={15}>
          <Alert variant="outlined" severity="info">
            <Typography>{handleErrorMessages()}</Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}
