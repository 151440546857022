export type ApiErrorDetails = {
  code: number
  message: string
  error: string
  severity: 'warning' | 'error'
}

export function subscribe<T>(eventName: string, listener?: (event: CustomEvent<T>) => void) {
  document.addEventListener(eventName, listener as EventListener)
}

export function unsubscribe<T>(eventName: string, listener?: (event: CustomEvent<T>) => void) {
  document.removeEventListener(eventName, listener as EventListener)
}

export function publish(event: string, data: ApiErrorDetails) {
  document.dispatchEvent(new CustomEvent(event, { detail: data }))
}

export function publishApiError(data: ApiErrorDetails) {
  publish('api-error', data)
}
