import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Template } from '../../../model/Template'
import { createTemplate } from '../../../services/surveyCompilationServices'
import { useAuth } from '../../authentication/AuthProvider'

type TemplateCreatorModalProps = {
  open: boolean
  onClose: () => void
  addTemplate: (template: Template) => void
}

export function TemplateCreatorModal(props: TemplateCreatorModalProps) {
  const { username } = useAuth()

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [referencePeriod, setReferencePeriod] = useState<string>('')
  const [referencePeriodError, setReferencePeriodError] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [erroreMessages, setErroreMessages] = useState<string>('')

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTitle(event.target.value)
    setError(false)
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDescription(event.target.value)
  }

  function checkFields(): boolean {
    let flag = true
    if (title.length === 0) {
      setTitleError(true)
      flag = false
    } else {
      setTitleError(false)
    }
    if (description.length === 0) {
      setDescriptionError(true)
      flag = false
    } else {
      setDescriptionError(false)
    }
    if (referencePeriod.length !== 4 || isNaN(Number(referencePeriod))) {
      setReferencePeriodError(true)
      flag = false
    } else {
      setReferencePeriodError(false)
    }
    return flag
  }

  async function onSubmit() {
    if (!checkFields()) {
      return
    }

    createTemplate(title.trim(), description, referencePeriod, username)
      .then((response) => {
        props.addTemplate(response)
        setError(false)
        setErroreMessages('')
        clearFields()
        props.onClose()
      })
      .catch((error) => {
        let errorMessage = 'Errore sconosciuto.'
        if (error instanceof Error) {
          errorMessage = error.message
        } else if (error.response && error.response.data) {
          errorMessage = error.response.data
        }
        setError(true)
        setErroreMessages(errorMessage)
      })
  }

  function clearFields() {
    setTitle('')
    setDescription('')
    setReferencePeriod('')
    setTitleError(false)
    setDescriptionError(false)
    setReferencePeriodError(false)
  }

  function handleClose() {
    clearFields()
    props.onClose()
  }

  async function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      await onSubmit()
    }
  }

  return (
    <Dialog open={props.open} onClose={handleClose} onKeyUp={handleKeyUp}>
      <DialogTitle>Create a new template</DialogTitle>
      <DialogContent>
        <Collapse in={error}>
          <Alert severity="error" variant={'outlined'}>
            <Typography>{erroreMessages}</Typography>
          </Alert>
        </Collapse>
        <TextField
          variant={'standard'}
          fullWidth
          label={'Title'}
          required
          value={title}
          autoFocus
          error={titleError}
          sx={{ marginTop: '5px' }}
          onChange={handleTitleChange}
        />
        <TextField
          variant={'standard'}
          fullWidth
          label={'Description'}
          required
          value={description}
          error={descriptionError}
          sx={{ marginTop: '5px' }}
          onChange={handleDescriptionChange}
        />
        <TextField
          variant={'standard'}
          fullWidth
          label={'Reference period'}
          placeholder={'Es. 2024'}
          required
          type="number"
          value={referencePeriod}
          error={referencePeriodError}
          helperText={referencePeriodError ? 'Inserire anno in formato YYYY' : ''}
          sx={{ marginTop: '5px' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setReferencePeriod(event.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
