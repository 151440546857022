import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { isMobileOnly } from 'react-device-detect'
import '../styles/Absence.css'

const useOrientationEffects = (isLandscape) => {
  const [previousOrientation, setPreviousOrientation] = useState(isLandscape)

  useEffect(() => {
    const handleOrientationChange = () => {
      const lastShown = localStorage.getItem('messageShowDate')
      // const oneDay = 30 * 1000; // 30 secondi
      // const oneDay = 24 * 60 * 60 * 1000; // 24 ore
      const oneDay = 5 * 60 * 1000 // 5 minuti

      if (isMobileOnly && !isLandscape && (!lastShown || Date.now() - lastShown > oneDay)) {
        Swal.fire({
          html: 'Per una migliore esperienza su cellulare ruota lo schermo!',
          confirmButtonText: 'Ok!',
          confirmButtonColor: '#289b38',
          toast: true,
          customClass: {
            container: 'my-sweet-alert-absence',
            confirmButton: 'swal-button',
          },
        })
        localStorage.setItem('messageShowDate', Date.now())
      }

      if (previousOrientation !== isLandscape) {
        window.location.reload()
      }
      setPreviousOrientation(isLandscape)
    }

    const handleViewportChange = () => {
      const viewport = document.querySelector('meta[name=viewport]')

      if (isLandscape) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=0.75')
      } else {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1')
      }
    }

    handleOrientationChange()
    handleViewportChange()
  }, [isLandscape, previousOrientation])
}

export default useOrientationEffects
