import React from 'react'
import { Box, TextField } from '@mui/material'
import { NumericQuestion } from '../../../../model/Question'
// import {NumericQuestion} from "../../../model/Question.ts";

type NumericAnswerProps = {
  question: NumericQuestion
  value: string
  onChange: (value: string, index: number) => void
  index: number
  error: boolean
  onError: (error: boolean, index: number) => void
}

export function NumericAnswer(props: NumericAnswerProps) {
  function handleValueChange(value: string) {
    if (props.question.isMandatory) {
      props.onError(value === '' || isNaN(Number(value)) || isNotBetweenMinAndMax(Number(value)), props.index!)
    } else {
      props.onError(value !== '' && (isNaN(Number(value)) || isNotBetweenMinAndMax(Number(value))), props.index!)
    }
    props.onChange?.(value, props.index!)
  }

  function isNotBetweenMinAndMax(value: number) {
    if (props.question.minValue === undefined || props.question.maxValue === undefined) {
      return false
    }
    return !(value >= props.question.minValue && value <= props.question.maxValue)
  }

  function hasMinAndMax() {
    return props.question.minValue !== -2147483648 && props.question.maxValue !== 2147483647
  }

  return (
    <Box>
      <TextField
        required={props.question.isMandatory}
        label={hasMinAndMax() ? `Answer (min: ${props.question.minValue}, max: ${props.question.maxValue})` : 'Answer'}
        fullWidth={true}
        value={props.value}
        onChange={(event) => handleValueChange(event.target.value)}
        error={props.error}
      />
    </Box>
  )
}
