import { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import {
  Backdrop,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Drawer,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Delete, QuestionMark } from '@mui/icons-material'
import dayjs, { Dayjs } from 'dayjs'
import { ScrollRestoration, useNavigate, useSearchParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { CompilationContainer } from '../../model/CompilationContainer'
import { deleteCompilationContainer, getCompilationContainers } from '../../services/surveyCompilationServices'
import { ApiError } from '../../errors/ApiError'
import { publishApiError } from '../../services/eventSercices'
import { SubmittedTemplateInfo } from '../../components/surveyComponents/submittedTemplateinfos/SubmittedTemplateInfo'
import ConfirmDialog from '../../components/surveyComponents/ConfirmDialog'
import { styled } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box } from '@mui/system'
import cover from '../../assets/select-surveys.jpeg'
import CustomLabel from '../../utilsComponents/CustomLabel'

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: theme.spacing(7) + 1,
  ...(open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: '90vw',
    [theme.breakpoints.up('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '60dvh',
    },
  }),
  ...(!open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  }),
}))

export function SubmittedTemplates() {
  const [submittedTemplates, setSubmittedTemplates] = useState<CompilationContainer[] | undefined>(undefined)

  const [searchParams, setSearchParams] = useSearchParams()

  const selectedSubmittedTemplateId = searchParams.get('id')

  const [submittedTemplate, setSubmittedTemplate] = useState<CompilationContainer | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedSubmittedTemplateId) {
      return
    }
    if (!submittedTemplates) {
      return
    }

    function getSubmittedTemplate(): CompilationContainer | undefined {
      if (!selectedSubmittedTemplateId || !submittedTemplates) {
        return undefined
      }
      return submittedTemplates.find((submittedTemplate) => submittedTemplate.id === selectedSubmittedTemplateId)
    }

    const newSubmittedTemplate = getSubmittedTemplate()
    if (!newSubmittedTemplate) {
      setSearchParams({})
      return
    }
    setSubmittedTemplate(null)
    setSubmittedTemplate(newSubmittedTemplate)
  }, [selectedSubmittedTemplateId, submittedTemplates, setSearchParams])

  const [submittedTemplatesError, setSubmittedTemplatesError] = useState<boolean>(false)

  useEffect(() => {
    if (!submittedTemplates) {
      getCompilationContainers()
        .then((response) => {
          const orderedByCreationDate = response.sort((a, b) => dayjs(b.creationDate).diff(dayjs(a.creationDate)))
          setSubmittedTemplates(orderedByCreationDate)
        })
        .catch((error: ApiError) => {
          publishApiError(error.details)
          setSubmittedTemplates([])
          setSubmittedTemplatesError(true)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [submittedTemplates])

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [onConfirm, setOnConfirm] = useState<(() => void) | undefined>(undefined)

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')

  // passo anche parametri beginningDate e endingDate
  const handleOpenConfirmationDialog = (id: string, beginningDate: Dayjs, endingDate: Dayjs) => {
    // verifico se sondaggio ongoing
    if (dayjs().isAfter(dayjs(beginningDate)) && dayjs().isBefore(dayjs(endingDate))) {
      setSnackbarMessage('Non è possibile cancellare un sondaggio in corso')
      setSnackbarOpen(true)
    } else {
      // procedo come prima
      setOpenConfirmationDialog(true)
      setOnConfirm(() => () => {
        deleteCompilationContainer(id)
          .then(() => {
            setSubmittedTemplates(submittedTemplates?.filter((submittedTemplate) => submittedTemplate.id !== id))
          })
          .catch((error: ApiError) => {
            let errorMessage = error.message || 'Si è verificato un errore'
            if (error.details && typeof error.details === 'object' && 'message' in error.details) {
              errorMessage = error.details.message
            }

            setSnackbarMessage(errorMessage)
            setSnackbarOpen(true)
          })
          .catch((error) => {
            console.log(error)
            setSnackbarMessage('Errore di rete o errore sconosciuto')
            setSnackbarOpen(true)
          })
      })
    }
  }

  function handleCloseConfirmationDialog() {
    setOpenConfirmationDialog(false)
    setOnConfirm(undefined)
  }

  const x = () => {
    if (isMobile) return 'body1'
    else return 'h6'
  }

  const [selectedTemplateId, setSelectedTemplateId] = useState('')
  // state per tener traccia dell'ID della card selezionata
  function mapSubmittedTemplates(submittedTemplate: CompilationContainer[]) {
    return submittedTemplate.map((submittedTemplate: CompilationContainer, index: number) => {
      //  questa è quella selezionata!
      const isSelected = selectedTemplateId === submittedTemplate.id

      return (
        <>
          <Grid item xs={9} sm={6} md={6} lg={6} key={index}>
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                backgroundColor: isSelected ? '#e0e0e0' : '#f0f0f0',
              }}
            >
              <CardActionArea
                onClick={() => {
                  setSelectedTemplateId(submittedTemplate.id)
                  setSearchParams({ id: submittedTemplate.id })
                }}
              >
                <CardContent sx={{ flexGrow: 1, display: 'flex' }}>
                  <Grid
                    container
                    item
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    justifyContent={'flex-end'}
                  >
                    <Grid item display={'flex'}>
                      <Typography gutterBottom variant={x()} sx={{ wordBreak: 'break-word', mb: 2 }}>
                        {submittedTemplate.template.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>

              <CardActions>
                <Grid
                  item
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-around'}
                  sx={{ width: '100%' }}
                >
                  <Grid
                    item
                    display={'flex'}
                    justifyContent={'flex-end'}
                    xs={submittedTemplate.type === 'DEFAULT' ? 6 : 7}
                    sm={submittedTemplate.type === 'DEFAULT' ? 5 : 6}
                    md={submittedTemplate.type === 'DEFAULT' ? 5 : 7}
                    lg={submittedTemplate.type === 'DEFAULT' ? 5 : 6}
                  >
                    {getTypeChip(submittedTemplate.type)}
                  </Grid>

                  <Grid item display={'flex'} justifyContent={'flex-start'} xs={5} sm={5} md={5} lg={4}>
                    {getStatusChip(submittedTemplate.beginningDate, submittedTemplate.endingDate)}
                  </Grid>
                </Grid>

                <Grid item display={'flex'} justifyContent={'flex-end'} xs={1} sm={1} md={1} lg={1}>
                  <IconButton
                    size={'small'}
                    onClick={() =>
                      handleOpenConfirmationDialog(
                        submittedTemplate.id,
                        submittedTemplate.beginningDate,
                        submittedTemplate.endingDate
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </>
      )
    })
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  function getTypeChip(type: string) {
    let icon: ReactElement | null = null
    switch (type) {
      case 'ANONYMOUS':
        icon = <QuestionMark />
    }
    return icon ? (
      <Chip icon={icon} label={submittedTypeLabel(type)} size={'small'} />
    ) : (
      <Chip label={submittedTypeLabel(type)} size={'small'} />
    )
  }

  function getStatusChip(beginningDate: Dayjs, endingDate: Dayjs) {
    let color = '#2695ef'
    let label = 'Not started'

    if (dayjs(beginningDate).isBefore(dayjs()) && dayjs(endingDate).isAfter(dayjs())) {
      color = '#79cc14'
      label = 'Ongoing'
    } else if (dayjs(endingDate).isBefore(dayjs())) {
      color = '#f29e30'
      label = 'Ended'
    }
    return <Chip sx={{ backgroundColor: color }} label={label} size={'small'} />
  }

  function submittedTypeLabel(type: string) {
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
  }

  function clearSubmittedTemplate() {
    setSearchParams({})
    setSubmittedTemplate(null)
    setSelectedTemplateId('')
  }

  function getSelectedSubmittedTemplateResponsiveNode() {
    if (!selectedSubmittedTemplateId) {
      return null
    }
    return (
      <StyledDrawer
        anchor={'right'}
        open
        onClose={clearSubmittedTemplate}
        sx={{
          zIndex: 1300,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            display: 'flex',
            alignItems: 'flex-start',
            width: { xs: '90vw', sm: '80vw', md: '80vw', lg: '70vw' },
          },
        }}
      >
        <IconButton
          onClick={clearSubmittedTemplate}
          sx={{
            marginLeft: 1,
            marginTop: 1,
            '& .MuiSvgIcon-root': {
              fontSize: 40,
            },
          }}
        >
          <ChevronRightIcon />
        </IconButton>
        {getSelectedSubmittedTemplateNode()}
      </StyledDrawer>
    )
  }

  function getSelectedSubmittedTemplateNode() {
    if (submittedTemplate === null) {
      return <></>
    }
    return <SubmittedTemplateInfo submittedTemplate={submittedTemplate} />
  }

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: '2.5em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        bgcolor: '',
      }}
    >
      <div style={{ height: '100vh' }}>
        <Backdrop open={!submittedTemplates}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <ConfirmDialog
        openValue={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        onConfirm={onConfirm}
        message={'Are you sure you want to delete this submitted template?'}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} message={snackbarMessage} />

      <Grid
        item
        sx={{
          width: { xs: '100%', md: '50%' },
          bgcolor: '',
        }}
      >
        {submittedTemplates && submittedTemplates.length > 0 ? (
          <>
            <Grid xs={12} sx={{ paddingX: { xs: 1 } }}>
              <CustomLabel text="Questionari">
                <Grid container item spacing={2} justifyContent={{ xs: 'center', md: 'flex-start' }} sx={{ mb: 2 }}>
                  {mapSubmittedTemplates(submittedTemplates)}
                </Grid>
              </CustomLabel>
            </Grid>
          </>
        ) : (
          <Typography variant={'body1'} align={'center'}>
            {submittedTemplatesError ? (
              'An error occurred while loading submitted templates'
            ) : (
              <>
                Nothing to see here, you should submit a template first, go to the &nbsp;
                <Typography
                  variant={'body1'}
                  component={'span'}
                  onClick={() => navigate('/survey/templates')}
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  templates page
                </Typography>
              </>
            )}
          </Typography>
        )}
      </Grid>

      {getSelectedSubmittedTemplateResponsiveNode()}

      {submittedTemplates && submittedTemplates.length > 0 ? (
        <Grid
          item
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          display={{
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
          }}
          sx={{
            bgcolor: '',
            width: { xs: '100%', md: '50%' },
          }}
        >
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
            <Typography sx={{ mb: { md: 4 } }} variant="h6">
              {' '}
              Seleziona un sondaggio dalla lista accanto
            </Typography>
          </Box>
          <Box
            sx={{
              height: '50vh',
              width: { md: '90%' },
              display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
              flexDirection: 'column',
              backgroundImage: `url(${cover})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              mixBlendMode: 'darken',
            }}
          ></Box>
        </Grid>
      ) : null}
      <ScrollRestoration />
    </Grid>
    // </Box>
  )
}
