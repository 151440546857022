import React from 'react'
import { Autocomplete, TextField, AutocompleteRenderInputParams } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon'

interface SearchBarProps {
  options: string[]
  handleSearchChange: (event: React.SyntheticEvent<Element, Event>, value: string) => void
  label: string
  width: string | number | object
  size?: 'small' | 'medium'
  icon?: React.ReactElement<SvgIconProps>
}

function SearchBar({ options, handleSearchChange, label, width, icon, size = 'medium' }: SearchBarProps) {
  return (
    <Autocomplete
      freeSolo
      open={false}
      options={options}
      onInputChange={(event, newValue) => handleSearchChange(event, newValue)}
      sx={{ width: width }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={label}
          size={size}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {icon}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default SearchBar
