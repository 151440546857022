import * as React from 'react'
import { styled } from '@mui/material/styles'
import { DateCalendar, itIT } from '@mui/x-date-pickers'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { Grid } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Rome')

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.common.black}`,
    // backgroundColor: 'lightgreen',
    // // color: theme.palette.common.white,
    // '&:focus': {
    //     backgroundColor: 'lightgreen'
    // },
    // '&:hover': {
    //     backgroundColor: 'green'
    // },
  }),
  ...(isFirstDay &&
    {
      // borderRadius: '50%',
    }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
  ...(!dayIsBetween &&
    {
      // '&:focus': {
      //     backgroundColor: theme.palette.background.default
      // },
      // '&:hover': {
      //     backgroundColor: theme.palette.background.default
      // },
    }),
}))

function Day(props) {
  const { day, selectedDays, ...other } = props

  const dayIsBetween = selectedDays.includes(day.format('YYYY-MM-DD'))

  return (
    <CustomPickersDay
      {...other}
      day={day}
      // sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      disableRipple
      focusRipple={false}
    />
    // {/*<Box sx={{*/}
    // {/*    width: '100%',*/}
    // {/*    height: '100%',*/}
    // {/*    border: '1px solid black',*/}
    // {/*    alignItems: 'center',*/}
    // {/*    justifyContent: 'center',*/}
    // {/*    display: 'flex'*/}
    // {/*}}>{day.format('D')}</Box>*/}
    // </CustomPickersDay>
  )
}

export default function CustomDay(props) {
  const { selectedDays, onChangee, shouldDisableDate } = props

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={'it'}
      localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <Grid item display={'flex'}>
        <DateCalendar
          sx={{
            width: '70%',
            // '@media (max-width:600px)': {
            // '.MuiDateCalendar-root': {
            //     width: '70%',
            //     padding: 1
            // },
            // },
          }}
          value={null}
          onChange={(targetValue) => {
            const targetKey = targetValue.format('YYYY-MM-DD')
            const newSelection = [...selectedDays]
            const index = newSelection.indexOf(targetKey)
            const wasSelected = index >= 0

            if (wasSelected) {
              newSelection.splice(index, 1)
            } else {
              newSelection.push(targetKey)
            }

            onChangee(newSelection)
          }}
          shouldDisableDate={shouldDisableDate ? shouldDisableDate : () => false}
          disableHighlightToday
          slots={{ day: Day }}
          slotProps={{
            day: {
              selectedDays: selectedDays,
            },
          }}
        />
      </Grid>
    </LocalizationProvider>
  )
}

const chiusureSettimanali = ['SUNDAY', 'SATURDAY']
const chiusureStraordinarie = [
  '2023-06-30',
  '2023-07-03',
  '2023-07-04',
  '2023-07-05',
  '2023-07-06',
  '2023-07-07',
  '2023-07-14',
  '2023-07-13',
  '2023-07-12',
  '2023-07-11',
  '2023-07-10',
  '2023-07-17',
]
const currentLocalDate = dayjs().format('YYYY-MM-DD')

const isDateDisabled = (dateDayjs) => {
  const localDate = dateDayjs.format('YYYY-MM-DD')
  if (localDate < currentLocalDate) {
    return true
  }
  if (
    chiusureSettimanali.includes(
      ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][dateDayjs.day()]
    )
  ) {
    return true
  }
  return chiusureStraordinarie.includes(localDate)
}
