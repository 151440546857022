import React from 'react'
import { Dayjs } from 'dayjs'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useState } from 'react'

type DateTimeRangePickerProps = {
  firstDate: Dayjs
  firstDateReadonly?: boolean
  minFirstDate?: Dayjs
  secondDate: Dayjs
  secondDateReadonly?: boolean
  onFirstDateChange?: (date: Dayjs) => void
  onSecondDateChange?: (date: Dayjs) => void
  onSecondDateError?: (error: boolean) => void
  firstDateLabel?: string
  secondDateLabel?: string
}

export function DateTimeRangePicker({
  firstDate,
  secondDate,
  onFirstDateChange,
  onSecondDateChange,
  onSecondDateError,
  firstDateLabel,
  secondDateLabel,
  firstDateReadonly,
  secondDateReadonly,
  minFirstDate,
}: DateTimeRangePickerProps) {
  function handleFirstDateChange(date: Dayjs | null) {
    if (onFirstDateChange !== undefined) {
      consumeDateIfNonNull(date, onFirstDateChange)
    }
  }

  function handleSecondDateChange(date: Dayjs | null) {
    if (onSecondDateChange !== undefined) {
      consumeDateIfNonNull(date, onSecondDateChange)
    }
  }

  function consumeDateIfNonNull(date: Dayjs | null, consumer: (date: Dayjs) => void) {
    if (!date) {
      return
    }
    consumer(date)
  }

  const [minDate] = useState<Dayjs>(minFirstDate !== undefined ? minFirstDate : firstDate.clone())
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={firstDateLabel}
        value={firstDate}
        onChange={handleFirstDateChange}
        minDateTime={firstDateReadonly ? undefined : minDate}
        disablePast={!firstDateReadonly}
        ampm={false}
        readOnly={firstDateReadonly}
        slotProps={{ textField: { fullWidth: true } }}
      />
      <DateTimePicker
        label={secondDateLabel}
        value={secondDate}
        onChange={handleSecondDateChange}
        minDateTime={secondDateReadonly ? undefined : firstDate}
        disablePast={!secondDateReadonly}
        onError={(error) => {
          onSecondDateError?.(error !== null)
        }}
        ampm={false}
        readOnly={secondDateReadonly}
        slotProps={{ textField: { fullWidth: true } }}
      />
    </LocalizationProvider>
  )
}
