// import {SingleChoiceQuestion} from "../../../model/Question.ts";
// import {ChoiceList} from "./ChoiceList.tsx";

import { SingleChoiceQuestion } from '../../../../model/Question'
import { ChoiceList } from './ChoiceList'

type SingleChoiceQuestionCardProps = {
  question: SingleChoiceQuestion
}

export function SingleChoiceQuestionCard(props: SingleChoiceQuestionCardProps) {
  return <ChoiceList choiceSelection={props.question.choiceSelection} />
}
