import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, itIT } from '@mui/x-data-grid'
import { getAdminAbsences, approveRejectAbsence } from '../../services/absenceServices'
import { Info, ThumbDown, ThumbUp, Description } from '@mui/icons-material'
import {
  formatDate_dd_MM_yyyy,
  formatTableCreatedDate,
  formatTableEndDateOrDuration,
  formatTableStartDate,
} from '../../utils'
import { Grid, Modal } from '@mui/material'
import Typography from '@mui/material/Typography'
import '../../styles/Absence.css'
import { useMobileOrientation } from 'react-device-detect'
import { ScrollRestoration } from 'react-router-dom'
import useOrientationEffects from '../../hook/useOrentiationEffetc'

export default function AdminAbsence() {
  const { isLandscape } = useMobileOrientation()

  const [absenceListData, setAbsenceListData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [totalRows, setTotalRows] = useState(0)

  const handleRefreshRows = () => {
    setLoading(true)
    const queryParams = {
      page,
      size: rowsPerPage,
    }

    getAdminAbsences(queryParams)
      .then((data) => {
        setAbsenceListData(data.content)
        setTotalRows(data.totalElements)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Failed to fetch absences:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    handleRefreshRows()
  }, [page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [modalTypologyContent, setModalTypologyContent] = useState('')

  const [modalContent, setModalContent] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleModalOpen = () => setModalIsOpen(true)
  const handleModalClose = () => {
    setModalIsOpen(false)
    setModalContent([])
  }

  const [loading, setLoading] = useState(false)

  useOrientationEffects(isLandscape)

  //   useEffect(() => {
  //     setLoading(true);
  //     getAdminAbsences()
  //         .then((response) => {
  //           setAbsenceListData(response.content);
  //             setLoading(false);
  //         })
  //         .catch((error) => {
  //             console.error("Failed to fetch absences:", error);
  //             setLoading(false);
  //         });
  // }, []);

  // useEffect(() => {
  //   getAdminAbsences().then((response) => setAbsenceListData(response))
  // }, [])

  const acceptAbsence = (params) => {
    let data = params.row
    let confirmString = ''
    let absenceTitleString = 'Sei sicuro di voler approvare'
    if (params.row.type === 'ferie') {
      confirmString = 'Accetta Ferie'
      absenceTitleString +=
        ' le ferie di: <br> <br> ' + //a capo
        data.owner +
        ' <br><br>  per il periodo dal: <br> <br> ' + //a capo
        formatDate_dd_MM_yyyy(data.startDate) +
        ' al: ' +
        formatDate_dd_MM_yyyy(data.endDate)
    } else {
      confirmString = 'Accetta Permesso'
      absenceTitleString +=
        ' il permesso di: <br> <br> ' + //a capo
        data.owner +
        ' <br> <br>  per il giorno: <br> <br> ' + //a capo
        formatDate_dd_MM_yyyy(data.startDate)
    }

    absenceTitleString +=
      ' <br> <br>   con note: <br> <br> ' + //a capo
      data.note

    Swal.fire({
      title: 'Attenzione',
      html: absenceTitleString,
      showCancelButton: true,
      confirmButtonText: confirmString,
      cancelButtonText: 'Annulla',
    }).then((result) => {
      if (result.isConfirmed) {
        data.status = 'accepted'
        _editAbsence(data)
      }
    })
  }

  const declineAbsence = (params) => {
    let data = params.row
    let absenceTitleString = 'Sei sicuro di voler rifiutare'
    let confirmString = ''
    if (params.row.type === 'ferie') {
      confirmString = 'Rifiuta Ferie'
      absenceTitleString +=
        ' le ferie di: <br> <br>' +
        data.owner +
        ' <br> <br> per il periodo dal: <br> <br>' +
        formatDate_dd_MM_yyyy(data.startDate) +
        ' al: ' +
        formatDate_dd_MM_yyyy(data.endDate)
    } else {
      confirmString = 'Rifiuta Permesso'
      absenceTitleString +=
        ' il permesso di: <br> <br>' +
        data.owner +
        ' <br> <br> per il giorno: <br> <br>' +
        formatDate_dd_MM_yyyy(data.startDate)
    }

    Swal.fire({
      title: 'Attenzione',
      html: absenceTitleString,
      showCancelButton: true,
      confirmButtonText: confirmString,
      cancelButtonText: 'Annulla',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = params.row
        data.status = 'rejected'
        _editAbsence(data)
      }
    })
  }

  const showHistory = (params) => {
    //console.log("params: ", params)
    console.log(params.row.history)
    // Formatto il contenuto del modal
    setModalTypologyContent('history')
    setModalContent(params.row.history)
    console.log('History: ', params.row.history)
    setModalIsOpen(true)
  }

  const showNote = (params) => {
    //console.log("params: ", params)
    setModalTypologyContent('note')
    setModalContent(params.row.note)
    setModalIsOpen(true)
  }

  const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  }

  const _editAbsence = (updatedAbsence) => {
    approveRejectAbsence(updatedAbsence.id, updatedAbsence).then((editResult) => {
      console.log('Result Edit: ')
      console.log(editResult)
      if (editResult.res === 'success') {
        // Successo assoluto
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Operation done!',
          //footer: '<a href="">Why do I have this issue?</a>'
        })
      }
      if (editResult.res === 'partial_success') {
        // Successo parziale
        Swal.fire({
          icon: 'warning',
          title: 'Partial success...',
          text: editResult.errors.join(', '),
          //footer: '<a href="">Why do I have this issue?</a>'
        })
      }
      if (editResult.res === 'failure') {
        // Fallimento
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: editResult.errors.join(',\n '),
          //footer: '<a href="">Why do I have this issue?</a>'
        })
      }

      const updatedAbsences = absenceListData.map((absence) => {
        console.log('absence: ', absence)
        if (updatedAbsence.id === absence.id) {
          return updatedAbsence
        }
        return absence
      })
      setAbsenceListData(updatedAbsences)
    })
  }
  const columns = [
    {
      field: 'created',
      headerName: 'Data Creazione',
      type: 'string',
      valueGetter: formatTableCreatedDate,
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'owner',
      headerName: 'User',
      type: 'string',
      minWidth: 200,
      flex: 2.5,
    },
    {
      field: 'type',
      headerName: 'Tipo',
      type: 'string',
      minWidth: 100,
      flex: 1.2,
    },
    {
      field: 'startDate',
      headerName: 'Inizio',
      // type: 'date',
      valueGetter: formatTableStartDate,
      minWidth: 120,
      flex: 1.5,
    },
    {
      field: 'endDate',
      headerName: 'Fine/Durata',
      // type: 'date',
      valueGetter: formatTableEndDateOrDuration,
      minWidth: 120,
      flex: 1.5,
    },
    {
      field: 'status',
      headerName: 'Stato',
      type: 'string',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Azioni',
      minWidth: 150,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ color: 'green' }}
          icon={<ThumbUp />}
          onClick={() => acceptAbsence(params)}
          label="Accept"
          disabled={params.row.status !== 'pending'}
        />,
        <GridActionsCellItem
          sx={{ color: 'red' }}
          icon={<ThumbDown />}
          onClick={() => declineAbsence(params)}
          label="Reject"
          disabled={params.row.status !== 'pending'}
        />,
        <GridActionsCellItem icon={<Info />} onClick={() => showHistory(params)} label="Reject" />,
        <GridActionsCellItem
          icon={<Description />}
          sx={{ color: params.row.note !== '' ? 'black' : 'gray' }}
          disabled={params.row.note === ''}
          onClick={() => showNote(params)}
          label="Note"
        />,
      ],
    },
  ]

  useOrientationEffects(isLandscape)

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: '2.5em',
        ml: { xs: 1 },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        bgcolor: '',
      }}
    >
      <Grid xs={11.5}>
        <h2>Amministrazione Ferie - Permessi</h2>
        <h3>Richieste presenti</h3>
        <div style={{ height: '70vh', marginBottom: '15px' }}>
          <DataGrid
            rows={absenceListData || []}
            columns={columns}
            loading={loading}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) => `absence-row-${params.row.status}`}
            pagination
            pageSize={rowsPerPage}
            rowCount={totalRows}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
      <Modal
        open={modalIsOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalTypologyContent === 'note' ? 'Note' : modalTypologyContent === 'history' ? 'Hisotry' : ''}
          </Typography>
          {modalTypologyContent === 'note' && <Typography>{modalContent}</Typography>}
          {modalTypologyContent === 'history' && (
            <ul>
              {modalContent.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          )}
        </Box>
      </Modal>
      {/* </div> */}
      {/* </Grid>
    </div> */}
      <ScrollRestoration />
    </Grid>
  )
}
