import Button from '@mui/material/Button'
import SchoolIcon from '@mui/icons-material/School'
import { ScrollRestoration } from 'react-router-dom'
import { Grid } from '@mui/material'

export default function Education() {
  return (
    <Grid
      item
      sx={{
        paddingTop: '1em',
        paddingLeft: { xs: 2 },
      }}
    >
      <Grid>
        <h2> Portale e-learning di Telematica </h2>
        <br />
        <div> Un po' di descrizione riguardo il portale: </div>
        <br />
        <Button
          variant="contained"
          startIcon={<SchoolIcon />}
          color="success"
          onClick={() => {
            window.open('https://telematicainformatica.streamcloud.it/')
          }}
        >
          Vai al portale della formazione
        </Button>
      </Grid>
      <ScrollRestoration />
    </Grid>
  )
}
