import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../../authConfig'
import portale_ti from '../../assets/portale_ti.png'
import { isMobile } from 'react-device-detect'
import { Box } from '@mui/material'
import { styled, keyframes } from '@mui/system'
import { ScrollRestoration } from 'react-router-dom'

const showup = keyframes`
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const slidein = keyframes`
  0% { margin-left: -800px; }
  20% { margin-left: -800px; }
  35% { margin-left: 0; }
  100% { margin-left: 0; }
`

const reveal = keyframes`
  0% { opacity: 0; width: 0; }
  20% { opacity: 1; width: 0; }
  30% { width: 355px; }
  80% { opacity: 1; }
  100% { opacity: 0; width: 355px; }
`

const AnimatedContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  background: 'linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%)',
  color: '#555',
  fontFamily: 'Roboto',
  fontWeight: 300,
  fontSize: '32px',
  paddingTop: '3em',
  height: '100vh',
  flexGrow: 2,
  overflow: 'hidden',
  WebkitBackfaceVisibility: 'hidden',
  WebkitPerspective: 1000,
  WebkitTransform: 'translate3d(0,0,0)',
  marginLeft: `calc(${theme.spacing(-7)} - 1px)`,
}))

const FirstDiv = styled('div')(({ theme }) => ({
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  animation: `${showup} 7s infinite`,
}))

const LastDiv = styled('div')(({ theme }) => ({
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '0px',
  animation: `${reveal} 7s infinite`,
  marginLeft: '-18px',
}))

const AnimatedSpan = styled('span')(({ theme }) => ({
  marginLeft: '-355px',
  animation: `${slidein} 7s infinite`,
}))

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.5))',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '-100%',
    width: '200%',
    height: '100%',
    background:
      'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)',
    animation: 'shine 2s linear infinite',
    opacity: 0.5,
  },
}))

const shine = keyframes`
  to {
    left: 100%;
  }
`

const DashboardContent = () => {
  return (
    <>
      <AnimatedContainer>
        <Box
          sx={{
            height: 100,
            width: isMobile ? '80vw' : '90vw',
            margin: 'auto',
            marginBottom: isMobile ? 2 : 0,
          }}
        >
          <FirstDiv>Benvenuto</FirstDiv>
          <LastDiv>
            <AnimatedSpan>nel portale telematico!</AnimatedSpan>
          </LastDiv>
        </Box>

        <Box
          sx={{
            width: isMobile ? 300 : 550,
            overflow: 'hidden',
            margin: 'auto',
          }}
        >
          <StyledImage src={portale_ti} alt="Sample Image" />
        </Box>
      </AnimatedContainer>
      <ScrollRestoration />
    </>
  )
}

export const Dashboard = () => {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
      <DashboardContent />
    </MsalAuthenticationTemplate>
  )
}
