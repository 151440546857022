import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { ContentCopy, Delete, MoreVert } from '@mui/icons-material'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import { Template } from '../../../model/Template'

type TemplateCardProps = {
  template: Template
  onCardClick: (template: Template) => void
  onDelete: (template: Template) => void
  onCompilationContainerCreate: (template: Template) => void
  onDuplicate: (template: Template) => void
  isSelected: boolean
}

export default function TemplateCard(props: TemplateCardProps) {
  const template = props.template
  dayjs.extend(utc)
  dayjs.extend(timezone)

  // const theme = useTheme()
  // const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'))
  // const isExtraSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const [format, setFormat] = useState<string>('DD-MM-YYYY / HH:mm')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  // useEffect(() => {
  //     setFormat(isSmallDevice ? 'DD/MM/YYYY HH:mm' : 'DD,MM,YYYY HH:mm')
  // }, [isSmallDevice]);

  // useEffect(() => {
  //     setFormat(isExtraSmallDevice ? 'DD,MM,YYYY HH:mm' : 'DD,MM,YYYY HH:mm')
  // }, [isExtraSmallDevice]);

  function handleDelete() {
    props.onDelete(props.template)
  }

  function handleClick() {
    props.onCardClick(props.template)
  }

  function handleCompilationContainerCreate() {
    props.onCompilationContainerCreate(props.template)
  }

  function formatDateChip(date: Dayjs, label: string) {
    return <Chip label={label + dayjs(date).tz('Europe/Rome').format(format)} size={'small'} />
  }

  return (
    <Card
      key={template.id}
      raised
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        backgroundColor: props.isSelected ? '#e0e0e0' : '#f0f0f0',
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
            {template.title}
          </Typography>
          <Typography gutterBottom paragraph variant="body1" sx={{ wordBreak: 'break-word' }}>
            {template.description}
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {formatDateChip(template.creationDate, 'Created on ')}
            </Grid>
            <Grid item xs={12}>
              {formatDateChip(template.lastModificationDate, 'Last modified on ')}
            </Grid>
            <Grid item xs={12}>
              <Chip label={'Reference period: ' + template.referencePeriod} size={'small'} />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" onClick={handleCompilationContainerCreate}>
          Configura
        </Button>
        <IconButton size={'small'} onClick={handleClickMenu}>
          <MoreVert />
        </IconButton>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.onDuplicate(template)
                handleCloseMenu()
              }}
            >
              <ListItemIcon>
                <ContentCopy />
              </ListItemIcon>
              <ListItemText>Duplicate</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </CardActions>
    </Card>
  )
}
