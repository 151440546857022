import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'

import { appRoles } from './authConfig'

import Login from './pages/Login'
import ErrorPage from './pages/ErrorPage'

import { RouteGuard } from './components/RouteGuard'
import { BaseLayout } from './components/BaseLayout'
import { Dashboard } from './pages/dashboard/Dashboard'
import { Notification } from './pages/notification/Notification'
import Education from './pages/education/Education'
import './styles/App.css'

import Absence from './pages/absence/Absence'
import AdminAbsence from './pages/absence/AdminAbsence'
import CalendarComponent from './pages/absence/Calendar'

import Templates from './pages/survey/Template'
import { SubmittedTemplates } from './pages/survey/SubmittedTemplates'
import { AvailableSurveys } from './pages/survey/AvailableSurveys'
import { Compilation } from './pages/survey/Compilation'

import Map from './pages/reservation/Map'
import EditableMap from './pages/reservation/EditableMap'
import FormChiusureSettimanali from './pages/reservation/FormChiusureSettimanali'
import CollapsibleTable from './pages/reservation/FormAnnullaPrenotazione'
import RootAdmin from './pages/survey/RootAdmin'
import HREvents from './pages/hrGame/HREvents'
import HREventDetail from './pages/hrGame/HREventDetail'

const theme = createTheme({
  palette: {
    primary: {
      main: '#289b38',
    },
    background: {
      default: '#EAEAEA',
      paper: '#E1E1E1',
    },
  },
  components: {
    MuiDayCalendar: {
      styleOverrides: {
        weekNumberLabel: {
          color: '#289b38',
        },
        weekNumber: {
          color: '#289b38',
        },
      },
    },
  },
})

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export default function App({ instance }) {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <BaseLayout instance={instance} />,
      children: [
        { index: true, element: <RouteGuard roles={[]} Component={Dashboard} /> },
        { path: 'dashboard', element: <RouteGuard roles={[]} Component={Dashboard} /> },
        { path: 'notification', element: <RouteGuard roles={[]} Component={Notification} /> },
        { path: 'absence', element: <RouteGuard roles={[]} Component={Absence} /> },
        {
          path: 'adminAbsence',
          element: <RouteGuard roles={[appRoles.AdminAbsence]} Component={AdminAbsence} />,
        },
        { path: 'calendar', element: <RouteGuard roles={[]} Component={CalendarComponent} /> },
        { path: 'education', element: <RouteGuard roles={[]} Component={Education} /> },
        {
          path: 'survey/*',
          children: [
            { index: true, element: <RouteGuard roles={[]} Component={RootAdmin} /> },
            {
              path: 'templates',
              element: <RouteGuard roles={[appRoles.AdminSurvey]} Component={Templates} />,
            },
            {
              path: 'submitted-templates',
              element: <RouteGuard roles={[appRoles.AdminSurvey]} Component={SubmittedTemplates} />,
            },
            {
              path: 'available-surveys',
              element: <RouteGuard roles={[]} Component={AvailableSurveys} />,
            },
            {
              path: 'available-surveys/:id/compilation',
              element: <RouteGuard roles={[]} Component={Compilation} />,
            },
            { path: '*', element: <ErrorPage /> },
          ],
        },
        {
          path: 'reservation/*',
          children: [
            { path: 'map', element: <RouteGuard roles={[]} Component={Map} /> },
            {
              path: 'edit-map',
              element: <RouteGuard roles={[appRoles.AdminReservation]} Component={EditableMap} />,
            },
            {
              path: 'gestisci-prenotazioni',
              element: <RouteGuard roles={[]} Component={CollapsibleTable} />,
            },
            {
              path: 'chiusure-settimanali',
              element: <RouteGuard roles={[appRoles.AdminReservation]} Component={FormChiusureSettimanali} />,
            },
            { path: '*', element: <ErrorPage /> },
          ],
        },
        {
          path: 'hrgame/*',
          children: [
            { index: true, element: <RouteGuard roles={[]} Component={HREvents} /> },
            { path: 'eventDetails', element: <RouteGuard roles={[]} Component={HREventDetail} /> },
          ],
        },
        { path: '*', element: <ErrorPage /> },
      ],
    },
  ])

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}>
        <AuthenticatedTemplate>
          <Outlet />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </RouterProvider>
    </ThemeProvider>
  )
}

// export default App
