import { protectedResources } from '../authConfig'
import { msalInstance } from '../index'

const getToken = async () => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
  }
  try {
    const response = await msalInstance.acquireTokenSilent({
      account: account,
      scopes: protectedResources.apiPortal.scopes,
    })
    return response.accessToken
  } catch (error) {
    // Handle token acquisition errors
    console.error('Token acquisition error: ', error)
    msalInstance.loginRedirect()
  }
}
/* Endpoint COMPLETI da mappare
  == ROLE ===
  GET: /role
  POST: /role
  DELETE: /role/{roleId}

  === POSITION ===
  GET: /position
  POST: /position
  DELETE: /position/{positionId}

  === EVENT ===
  GET: /event
  GET: /event/{eventId}
  POST: /event
  GET: /event/active
  GET: /event/setActive/{eventId}
  DELETE: /event/{eventId}

  === CANDIDATE ===
  GET: /candidate
  GET: /candidateEvent/{eventId}
  GET: /candidate/{email}
  POST: /candidate
  GET: /candidate/toggleFavourite/{candidateId}
  PATCH: /candidate/comment
  DELETE: /candidate/{candidateId}
 */

// == ROLE ===
// GET: /role
export const hrGameGetRole = async () => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/role`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// POST: /role
export const hrGamePostRole = async (newRoleData) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(newRoleData),
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/role`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// DELETE: /role/{roleId}
export const hrGameDeleteRole = async (roleId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'DELETE',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/role/${roleId}`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}

// === POSITION ===
// GET: /position
export const hrGameGetPosition = async () => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/position`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// POST: /position
export const hrGamePostPosition = async (newPositionData) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(newPositionData),
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/position`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// DELETE: /position/{positionId}
export const hrGameDeletePosition = async (positionId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'DELETE',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/position/${positionId}`, options).then(
    async (response) => {
      if (!response.ok) {
        // Unauthorized, trigger login
        if (response.status === 401) {
          await msalInstance.loginRedirect()
          return
        }
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    }
  )
}

// === EVENT ===
// GET: /event
export const hrGameGetEvent = async () => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/event`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// GET: /event/{eventId}
export const hrGameGetEventId = async (eventId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/event/${eventId}`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// POST: /event
export const hrGamePostEvent = async (newEventData) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(newEventData),
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/event`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// GET: /event/active
export const hrGameGetEventActive = async () => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/event/active`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// GET: /event/setActive/{eventId}
export const hrGameSetEventActive = async (eventId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/event/setActive/${eventId}`, options).then(
    async (response) => {
      if (!response.ok) {
        // Unauthorized, trigger login
        if (response.status === 401) {
          await msalInstance.loginRedirect()
          return
        }
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    }
  )
}
// DELETE: /event/{eventId}
export const hrGameDeleteActive = async (eventId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'DELETE',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/event/${eventId}`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}

// === CANDIDATE ===
// GET: /candidate
export const hrGameGetCandidate = async () => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/candidate`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// GET: /candidateEvent/{eventId}
export const hrGameGetCandidateForEventId = async (eventId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/candidate/${eventId}`, options).then(
    async (response) => {
      if (!response.ok) {
        // Unauthorized, trigger login
        if (response.status === 401) {
          await msalInstance.loginRedirect()
          return
        }
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    }
  )
}
// GET: /candidate/{email}
export const hrGameGetCandidateFromEmail = async (email) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/candidate/${email}`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// POST: /candidate
export const hrGamePostCandidate = async (newCandidateData) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(newCandidateData),
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/candidate`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
// GET: /candidate/toggleFavourite/{candidateId}
export const hrGameToggleCandidateFavourite = async (candidateId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/candidate/toggleFavourite/${candidateId}`, options).then(
    async (response) => {
      if (!response.ok) {
        // Unauthorized, trigger login
        if (response.status === 401) {
          await msalInstance.loginRedirect()
          return
        }
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      // Questa API non risponde JSON
      // return response.json()
      return response.text()
    }
  )
}
// PATCH: /candidate/comment
export const hrGameCandidateComment = async (commentData) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(commentData),
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/candidate/comment`, options).then(async (response) => {
    if (!response.ok) {
      // Unauthorized, trigger login
      if (response.status === 401) {
        await msalInstance.loginRedirect()
        return
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    // Questa API non risponde JSON
    // return response.json()
    return response.text()
  })
}
// DELETE: /candidate/{candidateId}
export const hrGameDeleteCandidate = async (candidateId) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const options = {
    method: 'DELETE',
    headers: headers,
  }

  return fetch(`${protectedResources.apiPortal.hrGameEndpoint}/candidate/${candidateId}`, options).then(
    async (response) => {
      if (!response.ok) {
        // Unauthorized, trigger login
        if (response.status === 401) {
          await msalInstance.loginRedirect()
          return
        }
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      //return response.json()
      return response.data
    }
  )
}
