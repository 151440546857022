import * as ExcelJS from 'exceljs' // Importa ExcelJS
import { saveAs } from 'file-saver'
import { IconButton } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

interface User {
  email: string
  nome: string
  cognome: string
  posizione: string
  ruolo: string
  score: number
  stella: boolean
  commento: string
}

interface DownloadProps {
  utenti: User[]
  nomeEvento: string | null
}

function Download(props: DownloadProps) {
  const { utenti, nomeEvento } = props

  const buttonStyle = {
    padding: '5px',
    borderRadius: '6px',
    color: 'white',
    backgroundColor: '#B06161',
    margin: '10px',
    height: 'fit-content',
  }

  const downloadStyle = {
    display: 'flex',
  }

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook() // Crea un nuovo workbook con ExcelJS
    const worksheet = workbook.addWorksheet('Sheet1') // Aggiungi una nuova worksheet

    // Aggiungi intestazioni
    worksheet.addRow(['Email', 'Nome', 'Cognome', 'Posizione', 'Ruolo', 'Commento', 'Stella', 'Punteggio'])

    // Aggiungi dati degli utenti
    utenti.forEach((user) => {
      worksheet.addRow([
        user.email,
        user.nome,
        user.cognome,
        user.posizione,
        user.ruolo,
        user.commento,
        user.stella,
        user.score,
      ])
    })

    // Salva il workbook in un buffer
    const excelBuffer = await workbook.xlsx.writeBuffer()

    // Crea un blob dal buffer
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })

    // Scarica il file Excel
    saveAs(blob, nomeEvento + '.xlsx')
  }

  return (
    <div style={downloadStyle}>
      <p style={{ marginRight: '10px' }}>Download</p>
      <IconButton style={buttonStyle} onClick={exportToExcel}>
        <FileDownloadOutlinedIcon />
      </IconButton>
    </div>
  )
}

export default Download
