import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { TemplateDetails } from '../../model/Template'
import { getCompilationContainerTemplate } from '../../services/surveyCompilationServices'
import { TemplateCompilation } from '../../components/surveyComponents/compilations/TemplateCompilation'
import { ScrollRestoration } from 'react-router-dom'

export function Compilation() {
  const params = useParams<{ id: string }>()

  const [templateDetails, setTemplateDetails] = useState<TemplateDetails>({
    template: null,
    endingDate: null,
  })

  useEffect(() => {
    if (!params.id) return

    getCompilationContainerTemplate(params.id)
      .then((data) => {
        setTemplateDetails({
          template: data.template,
          endingDate: data.endingDate,
        })
      })
      .catch((error) => {
        console.error('Failed to fetch template:', error)
      })
  }, [params.id])

  return (
    <>
      {templateDetails.template && <TemplateCompilation templateDetails={templateDetails} />}
      <ScrollRestoration />
    </>
  )
}
