import { graphConfig, protectedResources } from '../authConfig'
import { msalInstance } from '../index'

const getToken = async () => {
  const account = msalInstance.getActiveAccount()

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: protectedResources.apiPortal.scopes,
  })

  return response.accessToken
}

const getTokenAdmin = async () => {
  const account = msalInstance.getActiveAccount()

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: graphConfig.scopes,
  })

  return response.accessToken
}

// Reservation

export const getData = async () => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }
  return fetch(protectedResources.apiPortal.reservationEndpoint, options)
    .then((response) => response.json())
    .catch((error) => {
      console.log(error)
      return []
    })
}

export const getDataPaginated = async (year, month, weekNumber) => {
  const accessToken = await getToken()
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`
  headers.append('Authorization', bearer)

  let url = protectedResources.apiPortal.reservationEndpoint + '/filtered'
  const params = new URLSearchParams()

  // aggiungere sempre year e month se definiti
  if (year !== undefined && month !== undefined) {
    params.append('year', year)
    params.append('month', month)
    // aggiungere weekNumber solo se definito
    if (weekNumber !== undefined) {
      params.append('weekNumber', weekNumber)
    }
  }

  if (params.toString()) {
    url += `?${params}`
  }

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch((error) => {
      console.error('Error fetching data:', error)
      return []
    })
}

export const getPostazioni = async () => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }
  return fetch(protectedResources.apiPortal.reservationEndpoint + '/postazioni', options)
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      console.log(error)
      return []
    })
}

export const postPostazioni = async (desks) => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(desks),
  }

  return fetch(protectedResources.apiPortal.reservationEndpoint + '/postazioni', options)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Errore durante il salvataggio delle postazioni')
      }
      return response.json()
    })
    .catch((error) => {
      alert(error.message)
      console.log(error)
    })
}

export const deletePostazione = async (id) => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`
  headers.append('Authorization', bearer)

  const options = {
    method: 'DELETE',
    headers: headers,
  }

  return fetch(protectedResources.apiPortal.reservationEndpoint + `/postazioni/${id}`, options)
    .then(() => {
      return []
    })
    .catch(() => {
      return []
    })
}

export const getChiusure = async () => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }
  return fetch(protectedResources.apiPortal.reservationEndpoint + '/chiusure', options)
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      console.log(error)
      return []
    })
}

export const postChiusure = async (chiusure) => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(chiusure),
  }

  console.log('postChiusure: ', chiusure)
  return fetch(protectedResources.apiPortal.reservationEndpoint + '/chiusure', options)
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

/// recupera prenotazioni per utente con paginazione
export const getPrenotazioniUtente = async (username) => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(protectedResources.apiPortal.reservationEndpoint + `/prenotazioni/${username}`, options)
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      console.log(error)
      return []
    })
}
// recupera tutte le prenotazioni con paginazione e filtri opzionali
export const getPrenotazioni = async ({ page = 0, rowsPerPage = 10, selectedUser, selectedPostazione }) => {
  const accessToken = await getToken()
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  let url = `${protectedResources.apiPortal.reservationEndpoint}/prenotazioni?page=${page}&size=${rowsPerPage}`

  if (selectedUser) url += `&username=${encodeURIComponent(selectedUser)}`
  if (selectedPostazione) url += `&postazioneId=${encodeURIComponent(selectedPostazione)}`

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => {
      console.log(error)
      return []
    })
}

// "post" prenotazione

export const postPrenotazione = async (prenotazioni, azioneConflitto) => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  const bodyObject = {
    prenotazioni: prenotazioni,
    azioneConflitto: azioneConflitto,
  }

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(bodyObject),
  }

  console.log('post.prenotazione: ', bodyObject)
  return fetch(protectedResources.apiPortal.reservationEndpoint + '/prenotazioni', options)
    .then((response) => response.json())
    .then((data) => {
      if (data.warnings && data.warnings.length > 0) {
        console.warn('Avvisi: ', data.warnings.join('\n'))
      }
      return data
    })
    .catch((error) => console.log(error))
}

export const deletePrenotazione = async (id) => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`
  headers.append('Authorization', bearer)

  const options = {
    method: 'DELETE',
    headers: headers,
  }

  return fetch(protectedResources.apiPortal.reservationEndpoint + `/prenotazioni/${id}`, options)
    .then(() => {
      return []
    })
    .catch(() => {
      return []
    })
}

export const deletePrenotazioni = async (ids) => {
  const accessToken = await getToken()

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`
  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  const options = {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(ids),
  }

  return fetch(protectedResources.apiPortal.reservationEndpoint + `/prenotazioni`, options)
    .then(() => {
      return []
    })
    .catch(() => {
      return []
    })
}
