import React, { useEffect, useState } from 'react'
import { Alert, Grid, TextField, Typography } from '@mui/material'
import { NumericQuestion } from '../../../../model/Question'
import { checkMaxValueError, checkMinValueError, checkTitleError } from './utils/FieldsInputCheck'
import { TitleField } from './fields/TitleField'
import MandatoryCheckBox from './fields/MandatoryCheckBox'
// import {NumericQuestion} from "../../../model/Question.ts";
// import {TitleField} from "./fields/TitleField.tsx";
// import MandatoryCheckBox from "./fields/MandatoryCheckBox.tsx";
// import {checkTitleError, checkMaxValueError, checkMinValueError} from "./utils/FieldsInputCheck.ts";

type NumericQuestionFormProps = {
  questionToUpdate?: NumericQuestion
  newQuestionCallBack: (question: NumericQuestion, errors: boolean) => void
  submit: boolean
  onSubmitErrors: boolean
  setSubmitErrors: (value: boolean) => void
}

export default function NumericQuestionForm({
  questionToUpdate,
  newQuestionCallBack,
  submit,
  setSubmitErrors,
  onSubmitErrors,
}: NumericQuestionFormProps) {
  const [title, setTitle] = useState<string>(questionToUpdate?.title ?? '')
  const [defaultInput, setDefaultInput] = useState<boolean>(true)
  const [isMandatory, setIsMandatory] = useState<boolean>(questionToUpdate?.isMandatory ?? false)
  const [titleError, setTitleError] = useState<boolean>(false)
  const [minValueError, setMinValueError] = useState<boolean>(false)
  const [maxValueError, setMaxValueError] = useState<boolean>(false)
  const [currentMinValue, setCurrentMinValue] = useState<number | undefined>(questionToUpdate?.minValue ?? undefined)
  const [currentMaxValue, setCurrentMaxValue] = useState<number | undefined>(questionToUpdate?.maxValue ?? undefined)
  const minValue = questionToUpdate?.minValue ?? Number.MIN_SAFE_INTEGER
  const maxValue = questionToUpdate?.maxValue ?? Number.MAX_SAFE_INTEGER
  let newQuestion: NumericQuestion

  useEffect(() => {
    newQuestion = {
      title: title,
      isMandatory: isMandatory,
      questionType: 'NUMERIC',
      minValue: currentMinValue,
      maxValue: currentMaxValue,
    } as NumericQuestion
    newQuestionCallBack(newQuestion, titleError || minValueError || maxValueError)
  }, [submit])

  useEffect(() => {
    setTitleError(checkTitleError(title, defaultInput))
    setDefaultInput(false)
  }, [defaultInput, title, titleError])

  useEffect(() => {
    setMinValueError(checkMinValueError(minValue, maxValue, currentMinValue, currentMaxValue))
    setMaxValueError(checkMaxValueError(maxValue, minValue, currentMaxValue, currentMinValue))
  }, [currentMinValue, currentMaxValue, minValue, maxValue])

  const handleTitleChange = (event: string) => {
    setTitle(event)
    setSubmitErrors(false)
  }

  const handleValuesFieldChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'maxValue') setCurrentMaxValue(parseInt(event.target.value))
    else setCurrentMinValue(parseInt(event.target.value))
    setSubmitErrors(false)
  }

  const handleErrorMessages = () => {
    if (onSubmitErrors) return 'Error during submitting'
    if (titleError) return 'The title must be provided and should not begin with a space.'
    return 'The minimum value should be smaller than the maximum value'
  }

  return (
    <Grid margin={'auto'} container rowSpacing={1} columns={15} width={'100%'}>
      <Grid item xs={15}>
        <TitleField title={title} setTitleCallBack={handleTitleChange} titleError={titleError} />
      </Grid>
      <Grid item xs={15} md={7} lg={7}>
        <TextField
          fullWidth
          onChange={handleValuesFieldChanges}
          id="minValue"
          error={minValueError}
          label="Min value"
          type="number"
        />
      </Grid>
      <Grid item xs={0} md={1} lg={1}></Grid>
      <Grid item xs={15} md={7} lg={7}>
        <TextField
          fullWidth
          onChange={handleValuesFieldChanges}
          id="maxValue"
          error={maxValueError}
          label="Max value"
          type="number"
        />
      </Grid>
      <Grid item lg={4} md={4} xs={15}>
        <MandatoryCheckBox isMandatory={isMandatory} handleOnClick={setIsMandatory}></MandatoryCheckBox>
      </Grid>
      {(titleError || minValueError || maxValueError || onSubmitErrors) && (
        <Grid item lg={11} md={11} xs={15}>
          <Alert variant="outlined" severity="info">
            <Typography>{handleErrorMessages()}</Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}
