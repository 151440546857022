import React from 'react'
import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { ApiError } from '../../../errors/ApiError'
import { Summary } from '../../../model/Summary'
import { publishApiError } from '../../../services/eventSercices'
import { getSummary } from '../../../services/surveyCompilationServices'
// import {getSummary} from "../../services/CompilationService.ts";
// import {Summary} from "../../model/Summary.ts";
// import {ApiError} from "../../errors/ApiError.ts";
// import {publishApiError} from "../../services/EventService.ts";

type OpenQuestionsViewProps = {
  submittedTemplateId: string
}

export function OpenQuestionsView({ submittedTemplateId }: OpenQuestionsViewProps) {
  const [summaries, setSummaries] = useState<Summary[]>([])

  useEffect(() => {
    getSummary(submittedTemplateId)
      .then((res) => {
        setSummaries(res)
      })
      .catch((error: ApiError) => {
        publishApiError(error.details)
      })
      .catch((error: Error) => {
        console.error(error)
      })
  }, [submittedTemplateId])

  return (
<Paper
    sx={{ overflowY: 'scroll', maxHeight: '70dvh', backgroundColor: 'inherit' }}
    elevation={0}
>
    <Container>
        <Stack spacing={1}>
            {summaries.length > 0 && summaries.map((summary, index) => {
                return (
                    <Accordion key={'accordion' + index} TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant={'h5'} sx={{ wordBreak: 'break-word' }}>
                                {summary.questionTitle}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ overflow: 'auto' }}>
                            <Container>
                                {summary.answers.map((answer, index) => {
                                    return (
                                        <Box sx={{ paddingBottom: 1 }} key={'answers' + index}>
                                            <Typography
                                                gutterBottom
                                                variant={'body1'}
                                                sx={{
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            >
                                                {answer}
                                            </Typography>
                                            {index !== summary.answers.length - 1 &&
                                                <Divider variant={'middle'} />
                                            }
                                        </Box>
                                    )
                                })}
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Stack>
    </Container>
</Paper>
  )
}
