import React, { useEffect, useMemo, useState } from 'react'
import planimetria from '../../assets/planimetria_ti.jpg'
import FormNuovaPrenotazione from '../../components/reservationComponents/FormNuovaPrenotazione'
import DeskIcon from '../../components/reservationComponents/DeskIcon'
import Tooltip from '@mui/material/Tooltip'
import {
  Alert,
  Box,
  Collapse,
  ListItem,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  Grid,
  Button,
} from '@mui/material'
import DatePickerComponent from '../../components/reservationComponents/DatePickerComponent'

import { Link, ScrollRestoration, useNavigate } from 'react-router-dom'
import { getDataPaginated } from '../../services/reservationServices'
import GiornateConPrenotazioni from '../../components/reservationComponents/GiornateConPrenotazioni'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isoWeek from 'dayjs/plugin/isoWeek'
import DataGridReservation from '../../components/reservationComponents/DataGridReservation'
import { useAuth } from '../../components/authentication/AuthProvider'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import useOrientationEffects from '../../hook/useOrentiationEffetc'
import { isMobileOnly, useMobileOrientation } from 'react-device-detect'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)

const nullData = {
  prenotazioni: [],
  postazioni: [],
  settings: {
    durataMinimaPrenotazione: 0,
    maxPrenotazioniSettimanali: 1000,
    timeZoneId: 'Europe/Rome',
    orarioApertura: '00:00:00',
    orarioChiusura: '00:00:00',
    chiusureSettimanali: [],
  },
  chiusureStraordinarie: [],
}

const Map = (props) => {
  const { username, isAdmin } = useAuth()
  const navigate = useNavigate()
  const { isLandscape } = useMobileOrientation()

  const [data, setData] = useState(nullData)
  const [loadingData, setLoadingData] = useState(false)
  const [loadingDataDataGrid, setLoadingDataDataGrid] = useState(false)
  const [error, setError] = useState(null)

  const { prenotazioni, postazioni, settings, chiusureStraordinarie } = data

  dayjs.tz.setDefault(settings.timeZoneId)
  //const localDate = (timestamp) => dayjs(timestamp).format('YYYY-MM-DD');
  const localTime = (timestamp) => dayjs(timestamp).format('HH:mm')
  const timestampFromLocalDateTime = (dayjsDate, localTime) => {
    if (!localTime) {
      return null
    }
    if (!dayjsDate || typeof dayjsDate.hour !== 'function') {
      return null
    }
    // estraggo ore e minuti dalla stringa di tempo
    const [hours, minutes] = localTime.split(':').map(Number)
    // imposto ora e minuto sull'oggetto dayjs
    return dayjsDate.hour(hours).minute(minutes).second(0).millisecond(0).valueOf()
  }

  const [desk, setDesk] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(false)
  const [openAnnullaPrenotazioni, setOpenAnnullaPrenotazioni] = useState(false)
  const [openNuovaPrenotazione, setOpenNuovaPrenotazione] = useState(false)
  const [allPrenotazioni, setAllPrenotazioni] = useState()
  const [allPrenotazioniUser, setAllPrenotazioniUser] = useState()

  // const currentTimestamp = useMemo(() => new Date().getTime(), [data]);
  const currentTimestamp = useMemo(() => dayjs().valueOf(), [data])

  // const [giorno, setGiorno] = useState(localDate(currentTimestamp));
  const [giorno, setGiorno] = useState(dayjs(currentTimestamp))

  const handleToday = () => {
    const today = dayjs()
    setGiorno(today)
    handleUpdateDatas(today.year(), today.month() + 1, today.isoWeek())
  }

  const isDateDisabled = (_localDate) => {
    const currentLocalDate = dayjs()
    if (chiusureStraordinarie.includes(_localDate)) {
      return true
    }
    if (dayjs(_localDate).isBefore(currentLocalDate, 'day')) {
      return true
    }
    return settings.chiusureSettimanali.includes(
      ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][dayjs(_localDate).day()]
    )
  }

  const prenotazioniByGiorno = (prenotazioni) => {
    const prenotazioniByGiorno = {}
    prenotazioni.forEach((prenotazione) => {
      const giorno = dayjs(prenotazione.inizio).format('YYYY-MM-DD')
      if (!(giorno in prenotazioniByGiorno)) {
        prenotazioniByGiorno[giorno] = []
      }
      prenotazioniByGiorno[giorno].push(prenotazione)
    })
    return prenotazioniByGiorno
  }

  const prenotazioniByPostazioneId = (prenotazioni) => {
    const prenotazioniByPostazioneId = {}
    prenotazioni.forEach((prenotazione) => {
      if (!(prenotazione.postazioneId in prenotazioniByPostazioneId)) {
        prenotazioniByPostazioneId[prenotazione.postazioneId] = []
      }
      prenotazioniByPostazioneId[prenotazione.postazioneId].push(prenotazione)
    })
    return prenotazioniByPostazioneId
  }

  const [prenotazioniPostazione, prenotazioniUser, giorniConPrenotazioniUser] = useMemo(() => {
    const byGiorno = prenotazioniByGiorno(prenotazioni)
    const byGiornoAndPostazioneId = {}
    const user = {}

    Object.keys(byGiorno).forEach((giornoKey) => {
      byGiornoAndPostazioneId[giornoKey] = prenotazioniByPostazioneId(
        byGiorno[giornoKey].sort((p, q) => p.inizio - q.inizio)
      )
      user[giornoKey] = byGiorno[giornoKey].filter((p) => p.username === username)
      if (user[giornoKey].length === 0) {
        delete user[giornoKey]
      }
    })

    return [
      (postazione, giorno) => {
        const giornoStr = dayjs(giorno).format('YYYY-MM-DD')
        return giornoStr in byGiornoAndPostazioneId && postazione.id in byGiornoAndPostazioneId[giornoStr]
          ? byGiornoAndPostazioneId[giornoStr][postazione.id]
          : []
      },
      (giorno) => {
        const giornoStr = dayjs(giorno).format('YYYY-MM-DD')
        return giornoStr in user ? user[giornoStr] : []
      },
      Object.keys(user).sort(),
    ]
  }, [data])

  const monday = dayjs(giorno).startOf('week').format('YYYY-MM-DD')
  const sunday = dayjs(giorno).endOf('week').format('YYYY-MM-DD')

  const giorniConPrenotazioniUserSettimana = giorniConPrenotazioniUser
    .filter((giorno) => dayjs(giorno).isSameOrAfter(monday, 'day'))
    .filter((giorno) => dayjs(giorno).isSameOrBefore(sunday, 'day'))

  const raggiuntoLimitePrenotazioni =
    giorniConPrenotazioniUserSettimana.length >= settings.maxPrenotazioniSettimanali &&
    prenotazioniUser(giorno).length === 0 &&
    !isDateDisabled(giorno)

  const [_freeSlots, _freeSlotsUser] = useMemo(() => {
    const [_freeSlots, _freeSlotsUser] = [{}, {}]
    postazioni.forEach((postazione) => {
      _freeSlots[postazione.id] = []
      _freeSlotsUser[postazione.id] = []
    })
    if (isDateDisabled(giorno)) {
      return [_freeSlots, _freeSlotsUser]
    }

    const openingTimestamp = timestampFromLocalDateTime(giorno, settings.orarioApertura)
    const closureTimestamp = timestampFromLocalDateTime(giorno, settings.orarioChiusura)

    postazioni.forEach((postazione) => {
      const occupied = [
        { fine: openingTimestamp },
        ...prenotazioniPostazione(postazione, giorno),
        { inizio: closureTimestamp },
      ]

      const occupiedUser = [{ fine: openingTimestamp }]
      let left = prenotazioniPostazione(postazione, giorno)
      let right = prenotazioniUser(giorno)

      let i = 0
      let j = 0

      while (i < left.length && j < right.length) {
        if (left[i].inizio > right[j].inizio) {
          ;[left, right] = [right, left]
          ;[i, j] = [j, i]
        }

        let { inizio, fine } = left[i++]

        while (j < right.length && right[j].inizio <= fine) {
          do {
            fine = Math.max(fine, right[j++].fine)
          } while (j < right.length && right[j].inizio <= fine)
          ;[left, right] = [right, left]
          ;[i, j] = [j, i]
        }
        occupiedUser.push({ inizio, fine })
      }
      occupiedUser.push(...left.slice(i), ...right.slice(j), {
        inizio: closureTimestamp,
      })

      for (let i = 1; i < occupied.length; i++) {
        const inizio = occupied[i - 1].fine
        const fine = occupied[i].inizio
        if (fine - inizio >= settings.durataMinimaPrenotazione && fine > currentTimestamp) {
          _freeSlots[postazione.id].push({ inizio, fine })
        }
      }

      for (let i = 1; i < occupiedUser.length; i++) {
        const inizio = occupiedUser[i - 1].fine
        const fine = occupiedUser[i].inizio
        if (fine - inizio >= settings.durataMinimaPrenotazione && fine > currentTimestamp) {
          _freeSlotsUser[postazione.id].push({ inizio, fine })
        }
      }
    })
    return [_freeSlots, _freeSlotsUser]
  }, [giorno, data])

  const freeSlots = (postazione) => _freeSlots[postazione.id]
  const freeSlotsUser = (postazione) => _freeSlotsUser[postazione.id]

  const _deskInfo = useMemo(
    () =>
      postazioni.reduce((infos, desk) => {
        const prenotazioniGiorno = prenotazioniPostazione(desk, giorno.format('YYYY-MM-DD'))
        return {
          ...infos,
          [desk.id]: {
            desk: desk,
            state: {
              availableForUser: isAdmin || freeSlotsUser(desk).length > 0,
              available: freeSlots(desk).length > 0,
              empty: prenotazioniGiorno.length === 0,
              disabled: isDateDisabled(giorno.format('YYYY-MM-DD')),
              reservedByUser: isAdmin || prenotazioniGiorno.some((p) => p.username === username),
            },
            prenotazioni: prenotazioniGiorno,
            freeSlots: freeSlotsUser(desk),
          },
        }
      }, {}),
    [giorno, data]
  )

  const deskInfo = (desk) => _deskInfo[desk.id]

  const handleOpenTooltip = (postazione) => {
    setDesk(postazione)
    setOpenTooltip(true)
  }

  const handleCloseTooltip = (postazione) => {
    setOpenTooltip(false)
  }

  function handleDeletePrenotazioneSuccess(idPrenotazione) {
    setData((prevData) => ({
      ...prevData,
      prenotazioni: prevData.prenotazioni.filter((p) => p.id !== idPrenotazione),
    }))
  }

  function handleUpdateDatas(year = giorno.year(), month = giorno.month() + 1, weekNumber = giorno.isoWeek()) {
    if (!loadingData) {
      setLoadingData(true)
      getDataPaginated(year, month, weekNumber)
        .then((res) => {
          setData(res)
          setAllPrenotazioni(res.prenotazioni)
          setAllPrenotazioniUser(res.prenotazioni.filter((p) => p.username === username))
          setLoadingData(false)
          setError(null)
        })
        .catch((err) => {
          setError('Non è stato possibile caricare i dati aggiornati.')
          setLoadingData(false)
          navigate('/')
          console.log(err)
        })
    }
  }

  useOrientationEffects(isLandscape)

  useEffect(() => handleUpdateDatas(), [openNuovaPrenotazione, giorno])

  return (
    <Grid
      item
      display={'flex'}
      flexDirection={'column'}
      xs={12}
      sx={{
        paddingTop: '1em',
        paddingLeft: { xs: 2 },
        ml: '-57px',
        mt: '2.5em',
      }}
    >
      <Button sx={{ width: '35%', m: '0 auto' }} onClick={handleToday}>
        Torna ad oggi
      </Button>
      <DatePickerComponent
        date={giorno}
        timezone={settings.timeZoneId}
        onDateChange={(newDate) => {
          const newDay = dayjs(newDate)
          setGiorno(newDay)
          handleUpdateDatas(newDay.year(), newDay.month() + 1, newDay.isoWeek())
        }}
        onMonthChange={(newMonth) => {
          // console.log("Mese cambiato:", newMonth.format('YYYY-MM'));
          const year = newMonth.year()
          const month = newMonth.month() + 1
          handleUpdateDatas(year, month)
        }}
        isDateDisabled={isDateDisabled}
        giorniConPrenotazioni={giorniConPrenotazioniUser}
      />

      <Grid
        item
        display={'flex'}
        flexWrap={'wrap'}
        justifyContent={isMobileOnly && isLandscape ? 'center' : 'flex-start'}
        mt={'2em'}
        sx={{ marginX: 'auto', width: '100%' }}
        gap={2}
        flexDirection={{
          xs: 'row',
          sm: 'row',
          md: 'row',
          lg: isMobileOnly && isLandscape ? 'row' : 'column',
          xl: 'column',
        }}
      >
        <Grid item display={'flex'} justifyContent={'center'} xs={12} sm={12} md={11} lg={8} xl={8}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              // maxWidth: "800px",
              overflow: 'auto',
            }}
          >
            <img src={planimetria} width="800px" alt="" style={{ mixBlendMode: 'darken' }} />

            {postazioni.map((postazione, i) => (
              <Tooltip
                key={postazione.id}
                open={desk !== null && desk.id === postazione.id && openTooltip}
                onOpen={() => handleOpenTooltip(postazione)}
                onClose={() => handleCloseTooltip(postazione)}
                disableTouchListener
                arrow
                title={
                  <>
                    <Typography sx={{ textAlign: 'center' }}>Postazione {postazione.id}</Typography>
                    {prenotazioniPostazione(postazione, giorno).map((prenotazione) => (
                      <ListItem sx={{ padding: '0px', alignContent: 'center' }} key={prenotazione.id}>
                        <b>{`${localTime(prenotazione.inizio, settings.timeZoneId)} - ${localTime(
                          prenotazione.fine,
                          settings.timeZoneId
                        )} ${prenotazione.name.toUpperCase()}`}</b>
                      </ListItem>
                    ))}
                  </>
                }
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: `${postazione.y}px`,
                    left: `${postazione.x}px`,
                    transform: `rotate(${-postazione.theta}deg)`,
                    width: '29px',
                    height: '20px',
                    cursor: deskInfo(postazione).state.availableForUser ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => {
                    if (openTooltip && desk !== null && desk.id === postazione.id) {
                      handleCloseTooltip(postazione)
                      setOpenNuovaPrenotazione(deskInfo(postazione).state.availableForUser)
                    } else {
                      handleOpenTooltip(postazione)
                    }
                  }}
                >
                  <DeskIcon state={deskInfo(postazione).state} />
                </Box>
              </Tooltip>
            ))}
          </Box>
        </Grid>

        <Grid item display={'flex'} xs={12} sm={12} md={11} lg={isMobileOnly && isLandscape ? 8 : 5} xl={5}>
          <DataGridReservation
            data={data}
            giorno={giorno}
            onDeleteSuccess={handleDeletePrenotazioneSuccess}
            setLoadingDataDataGrid={setLoadingDataDataGrid}
          />
        </Grid>
      </Grid>

      {(loadingDataDataGrid || !prenotazioni) && (
        <div style={{ height: '100vh' }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingData || loadingDataDataGrid}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      {desk !== null && isAdmin !== null && (
        <FormNuovaPrenotazione
          isAdmin={isAdmin}
          open={openNuovaPrenotazione}
          deskInfo={deskInfo(desk)}
          everybodyPrenotazioni={allPrenotazioni}
          allPrenotazioniUser={allPrenotazioniUser}
          prenotazioniUser={prenotazioniUser(giorno)}
          chiusureStraordinarie={chiusureStraordinarie}
          date={giorno}
          settings={settings}
          onClose={() => {
            setOpenNuovaPrenotazione(false)
          }}
          onSubmit={() => handleUpdateDatas()}
          raggiuntoLimitePrenotazioni={raggiuntoLimitePrenotazioni}
        />
      )}

      <Stack sx={{ position: 'fixed', bottom: '0.5em', left: '0.5em', right: '0.5em' }}>
        <Collapse in={error !== null && !loadingData} mountOnEnter unmountOnExit>
          <Alert
            sx={{ marginTop: '0.5em' }}
            severity={'error'}
            action={
              <Link sx={{ cursor: 'pointer' }} onClick={handleUpdateDatas}>
                Riprova
              </Link>
            }
          >
            {error}
          </Alert>
        </Collapse>

        <Collapse in={raggiuntoLimitePrenotazioni} mountOnEnter unmountOnExit>
          <Alert
            sx={{ marginTop: '0.5em' }}
            severity="warning"
            action={
              <Link sx={{ cursor: 'pointer' }} onClick={() => setOpenAnnullaPrenotazioni(true)}>
                Annulla una prenotazione
              </Link>
            }
          >
            Non puoi prenotare in questo giorno perché hai raggiunto il limite settimanale di{' '}
            {settings.maxPrenotazioniSettimanali} prenotazioni!
          </Alert>
          <GiornateConPrenotazioni
            isOpen={openAnnullaPrenotazioni}
            onClose={() => {
              setOpenAnnullaPrenotazioni(false)
              handleUpdateDatas()
            }}
            prenotazioni={prenotazioniUser}
            giorni={giorniConPrenotazioniUserSettimana}
          />
        </Collapse>
      </Stack>
      <ScrollRestoration />
    </Grid>
  )
}

export default Map
