import { format, parseISO } from 'date-fns'

export const formatDate_dd_MM_yyyy = (stringDate) => {
  return `${format(parseISO(stringDate), 'dd-MM-yyyy')}`
}

export const formatDate_dd_MM_yyyy_hh_mm_ss = (stringDate) => {
  return `${format(parseISO(stringDate), 'dd-MM-yyyy HH:MM:SS')}`
}

export const formatTableCreatedDate = (params) => {
  return `${formatDate_dd_MM_yyyy(params.row.created)}`
}

export const formatTableStartDate = (params) => {
  return `${formatDate_dd_MM_yyyy(params.row.startDate)}`
}

export const formatTableEndDateOrDuration = (params) => {
  if (params.row.type === 'ferie') {
    return `${formatDate_dd_MM_yyyy(params.row.endDate)}`
  }
  if (params.row.type === 'permesso') {
    return `${params.row.permissionDurationHours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}
      :
      ${params.row.permissionDurationMinutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} 
      h`
  }
  return ''
}

export const formatTableEndDateOrDurationLong = (params) => {
  if (params.row.type === 'ferie') {
    return `${formatDate_dd_MM_yyyy(params.row.endDate)}`
  }
  if (params.row.type === 'permesso') {
    const calculateTimeRange = (startDate, durationPermissionHours, durationPermissionMinutes) => {
      const date = new Date(startDate)
      let endHours = date.getHours() + durationPermissionHours
      let endMinutes = date.getMinutes() + durationPermissionMinutes

      // Gestire il sovrappasso dei minuti e delle ore
      if (endMinutes >= 60) {
        endHours += Math.floor(endMinutes / 60)
        endMinutes %= 60
      }
      if (endHours >= 24) {
        endHours %= 24
      }

      // Formatto di nuovo l'orario di inizio e di fine
      // Prendo ora, la converto in stringa, col metodo padStart tengo solo le prime due cifre per sicurezza
      const startTime = `
        ${date.getHours().toString().padStart(2, '0')}
        :
        ${date.getMinutes().toString().padStart(2, '0')}
        `
      // console.log('log Ora',date.getHours().toString().padStart(2,'0'));
      // console.log('log Minuti',date.getMinutes().toString().padStart(2,'0'));
      // Prendo minuti, li converto in stringa, col metodo padStart tengo solo le prime due cifre per sicurezza
      const endTime = `
        ${endHours.toString().padStart(2, '0')}
        :
        ${endMinutes.toString().padStart(2, '0')}
        `

      return `${params.row.permissionDurationHours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}
        :
        ${params.row.permissionDurationMinutes.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })} 
        h 
        (da ${startTime} a ${endTime})`
    }

    return calculateTimeRange(
      params.row.startDate,
      params.row.permissionDurationHours,
      params.row.permissionDurationMinutes
    )
  }
  return ''
}

export const formatTableStatus = (params) => {
  if (params.row.status === 'accepted') {
    return 'Accettato'
  }
  if (params.row.status === 'rejected') {
    return 'Rifiutato'
  }

  return 'In attesa di approvazione'
}
