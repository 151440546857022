// import {List, ListItem} from "@mui/material";
// import { ChoiceSelection, WeightedChoice } from "../../../../model/Question";

// type ChoiceListProps = {
//     choiceSelection: ChoiceSelection;
// }

// export function ChoiceList(props: ChoiceListProps) {
//     const choices = props.choiceSelection.choices

//     function mapChoices() {
//         return choices.map((choice, index) => {
//                 if (typeof choice === 'string') {
//                     return <ListItem key={index} sx={{display: 'list-item'}}>{choice}</ListItem>
//                 }
//                 choice = choice as WeightedChoice
//                 return <ListItem key={index} sx={{display: 'list-item'}}>{choice.choice + ' (' + choice.weight+')'}</ListItem>
//             }
//         )
//     }

//     return (
//         <List sx={{listStyleType: 'disc', paddingLeft: 4}}>
//             {mapChoices()}
//         </List>
//     )
// }

import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import QuizIcon from '@mui/icons-material/Quiz'
import Typography from '@mui/material/Typography'

type Choice = string | WeightedChoice

interface WeightedChoice {
  choice: string
  weight: number
}

interface ChoiceSelection {
  choices: Choice[]
}

interface ChoiceListProps {
  choiceSelection: ChoiceSelection
}

export function ChoiceList(props: ChoiceListProps) {
  const { choiceSelection } = props

  function mapChoices() {
    return choiceSelection.choices.map((choice, index) => {
      const isWeightedChoice = (item: Choice): item is WeightedChoice => {
        return (item as WeightedChoice).choice !== undefined
      }

      const displayChoice = isWeightedChoice(choice) ? `${choice.choice} (weight ${choice.weight})` : choice

      return (
        <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', paddingY: 1 }}>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <QuizIcon sx={{ color: 'primary.main' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body1"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'wrap',
                  overflowWrap: 'break-word',
                }}
              >
                {displayChoice}
              </Typography>
            }
            sx={{ margin: 0 }}
          />
        </ListItem>
      )
    })
  }

  return <List sx={{ width: '90%' }}>{mapChoices()}</List>
}
