import React from 'react'

function DeskIcon(props) {
  const { state } = props
  const fill =
    state.availableForUser && state.empty
      ? 'green'
      : state.availableForUser
        ? 'orange'
        : state.available && state.empty
          ? 'rgba(94, 139, 88, 0.8)'
          : state.available
            ? 'rgba(189, 169, 99, 0.8)'
            : state.disabled
              ? 'grey'
              : 'red'

  const dot = !state.empty && !state.disabled
  const dotFill = state.reservedByUser ? 'rgb(102, 140, 255)' : fill

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="24px" viewBox="0 0 27 25">
      <rect x="1" y="1" width="25" height="13" fill={fill} stroke="#000" strokeWidth="1" rx="2" ry="2" />

      {dot && <circle cx="13.5" cy="18" r="5" fill={dotFill} stroke="#000" strokeWidth="1" />}

      <rect x="8" y="8" width="11" height="6" fill={fill} stroke="#000" strokeWidth="1" />
    </svg>
  )
}

export default DeskIcon
