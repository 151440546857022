import { FormControlLabel, FormGroup, Grid, Paper, Switch, TextField, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
// import {ChoiceSelection, WeightedChoice} from "../../../../model/Question.ts";
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import IconButton from '@mui/material/IconButton'
import { Add } from '@mui/icons-material'
import FormControl from '@mui/material/FormControl'
import { ChoiceSelection, WeightedChoice } from '../../../../../model/Question'

type ChoicesFieldsProps = {
  questionChoicesSelection: ChoiceSelection
  setQuestionChoicesSelection: (questionChoices: ChoiceSelection) => void
  setChoicesError: (value: boolean) => void
}

export default function ChoiceFields({
  questionChoicesSelection,
  setQuestionChoicesSelection,
  setChoicesError,
}: ChoicesFieldsProps) {
  const localChoices = { ...questionChoicesSelection }
  const [choiceFieldUserInput] = useState<string[]>(
    localChoices.choices.map((choice) => {
      if (typeof choice === 'string') {
        return choice
      }
      return choice.choice
    })
  )

  const [weightFieldUserInput] = useState<number[]>(
    localChoices.choices.map((choice) => {
      if (typeof choice !== 'string') {
        return choice.weight
      }
      return 1
    })
  )

  const [showWeight, setShowWeight] = React.useState<boolean>(localChoices.type === 'WEIGHTED')
  const [addButtonStatus, setAddButtonStatus] = useState<boolean>(true)
  const [removeButtonStatus, setRemoveButtonStatus] = useState<boolean>(false)
  const [textFieldsError] = useState<boolean[]>(Array.from(Array(localChoices.choices.length), () => false))
  const [weightFieldsError] = useState<boolean[]>(Array.from(Array(localChoices.choices.length), () => false))

  const changeButtonStatus = () => {
    setAddButtonStatus(true)
    setRemoveButtonStatus(true)
    if (localChoices.choices.length <= 2) {
      setRemoveButtonStatus(false)
    }
    if (localChoices.choices.length >= 5) {
      setAddButtonStatus(false)
    }
  }
  const handleAddChoice = () => {
    if (questionChoicesSelection.type === 'SIMPLE') {
      ;(localChoices.choices as string[]).push('')
    } else {
      ;(localChoices.choices as WeightedChoice[]).push({ choice: '', weight: 1 })
    }
    choiceFieldUserInput.push('')
    weightFieldUserInput.push(1)
    setQuestionChoicesSelection(localChoices)
    changeButtonStatus()
  }

  const handleRemoveChoice = (event: React.MouseEvent<HTMLButtonElement>) => {
    const index = Number(event.currentTarget.id)
    localChoices.choices.splice(index, 1)
    weightFieldUserInput.splice(index, 1)
    choiceFieldUserInput.splice(index, 1)
    textFieldsError.splice(index, 1)
    weightFieldsError.splice(index, 1)
    setQuestionChoicesSelection(localChoices)
    changeButtonStatus()
  }

  const handleFieldsChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number(event.target.id.substring(event.target.id.length - 1))
    if (event.target.id.includes('weight')) {
      ;(localChoices.choices[index] as WeightedChoice).weight = Number(event.target.value)
      weightFieldUserInput[index] = Number(event.target.value)
      checkWeightInputErrors(index, Number(event.target.value))
      setQuestionChoicesSelection(localChoices)
      return
    }
    if (localChoices.type !== 'WEIGHTED') {
      localChoices.choices[index] = event.target.value
    } else {
      ;(localChoices.choices[index] as WeightedChoice).choice = event.target.value
    }
    choiceFieldUserInput[index] = event.target.value
    checkTitleError(event.target.value, index)
    setQuestionChoicesSelection(localChoices)
  }

  const checkTitleError = (title: string, index: number) => {
    textFieldsError[index] = title === '' || title.startsWith(' ')
    setParentChoicesError()
  }

  const checkWeightInputErrors = (index: number, value: number) => {
    weightFieldsError[index] = value < 1 || value > 2147483647
    setParentChoicesError()
  }

  const setParentChoicesError = () => {
    let error = false
    textFieldsError.forEach((value, index) => {
      if (value || weightFieldsError[index]) {
        error = true
      }
    })
    setChoicesError(error)
  }

  const convertToWeighted = () => {
    const tempChoiceSelection = {
      type: 'WEIGHTED',
      choices: (localChoices.choices as string[]).map((choice, index) => {
        return { choice: choice, weight: weightFieldUserInput[index] } as WeightedChoice
      }),
    } as ChoiceSelection
    setQuestionChoicesSelection(tempChoiceSelection)
  }

  const convertToSimple = () => {
    const tempChoiceSelection = {
      type: 'SIMPLE',
      choices: (localChoices.choices as WeightedChoice[]).map((choice) => {
        return choice.choice
      }),
    } as ChoiceSelection
    setQuestionChoicesSelection(tempChoiceSelection)
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowWeight(event.target.checked)
    if (localChoices.type !== 'WEIGHTED') {
      convertToWeighted()
    } else {
      convertToSimple()
    }
  }

  return (
    <>
      <Grid container padding={0.5}>
        <Grid item md={8} xs={6}>
          <Typography variant={'subtitle1'}>Choices</Typography>
        </Grid>
        <Grid item md={2} xs={3}>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={<Switch id={'weight switch'} checked={showWeight} onChange={handleSwitchChange} />}
                label={'Weight:'}
                labelPlacement={'start'}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={3}>
          <Tooltip title={localChoices.choices.length === 5 ? 'Maximum 5 choices' : 'Add choice'} placement="right-end">
            <span>
              <IconButton
                size={'small'}
                onClick={addButtonStatus ? handleAddChoice : undefined}
                disabled={localChoices.choices.length === 5}
                sx={{
                  marginLeft: 5.2,
                }}
              >
                <Add />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <Paper
        style={{
          maxHeight: '22dvh',
          overflowX: 'hidden',
          boxShadow: 'none',
          scrollbarGutter: 'stable',
          display: 'flex',
          justifyItems: 'center',
          paddingTop: 8,
          paddingLeft: 6,
        }}
      >
        <Grid container spacing={1}>
          {questionChoicesSelection.choices.map((_choice, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Grid container spacing={1} marginBottom={'10px'}>
                  <Grid item md={showWeight ? 9 : 11} xs={showWeight ? 12 : 10} key={'choice' + index}>
                    <TextField
                      id={'choice' + index}
                      multiline
                      label={'Choice: ' + (index + 1)}
                      placeholder={'Insert choice'}
                      onChange={handleFieldsChanges}
                      error={textFieldsError[index]}
                      value={choiceFieldUserInput[index]}
                      fullWidth
                    ></TextField>
                  </Grid>
                  {showWeight && (
                    <Grid item md={2} xs={10} key={'weight' + index}>
                      <TextField
                        fullWidth
                        id={'weight' + index}
                        label="Weight"
                        disabled={!showWeight}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={showWeight && weightFieldsError[index]}
                        value={weightFieldUserInput[index].toString()}
                        onChange={handleFieldsChanges}
                      />
                    </Grid>
                  )}
                  <Grid item md={1} xs={2} key={'remove button' + index} padding={1} marginTop={1.5}>
                    <Tooltip
                      title={localChoices.choices.length <= 2 ? 'Minimum 2 choices' : 'Remove choice'}
                      placement="right-end"
                    >
                      <span>
                        <IconButton
                          size={'small'}
                          onClick={removeButtonStatus ? handleRemoveChoice : undefined}
                          id={index.toString()}
                          disabled={localChoices.choices.length <= 2}
                        >
                          <RemoveRoundedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
    </>
  )
}
