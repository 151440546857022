// import {getRolesFromJwt} from "../services/AuthService.ts";
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../components/authentication/AuthProvider'
import { appRoles } from '../../authConfig'

export default function RootAdmin() {
  const { roles } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (roles.includes(appRoles.AdminSurvey)) {
      navigate('/survey/templates')
    } else {
      navigate('/survey/available-surveys')
    }
  })

  // useEffect(() => {
  //         navigate('/survey/available-surveys')
  // });

  return null
}
