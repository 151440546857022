import { Typography } from '@mui/material'
import { MultipleChoiceQuestion } from '../../../../model/Question'
import { ChoiceList } from './ChoiceList'
// import { MultipleChoiceQuestion } from "../../../model/Question.ts";
// import {ChoiceList} from "./ChoiceList.tsx";

type MultipleChoiceQuestionCardProps = {
  question: MultipleChoiceQuestion
}

export function MultipleChoiceQuestionCard(props: MultipleChoiceQuestionCardProps) {
  return (
    <>
      {props.question.minChoices !== 0 && (
        <Typography variant={'body2'}>Min choices: {props.question.minChoices}</Typography>
      )}
      {props.question.maxChoices !== props.question.choiceSelection.choices.length && (
        <Typography variant={'body2'}>Max choices: {props.question.maxChoices}</Typography>
      )}
      <ChoiceList choiceSelection={props.question.choiceSelection} />
    </>
  )
}
