import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon'

interface Option {
  label: string
  value: string
}

interface SelectBarProps {
  options: Option[]
  handleSearchChange: (value: string) => void
  label: string
  width: string | number
  size?: 'small' | 'medium'
  icon?: React.ReactElement<SvgIconProps>
}

export default function SelectBar({
  options,
  handleSearchChange,
  label,
  width,
  icon,
  size = 'medium',
}: SelectBarProps) {
  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      onChange={(event, value) => {
        handleSearchChange(typeof value === 'string' ? value : value?.value ?? '')
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size={size}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {icon}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      sx={{ width: width }}
    />
  )
}
