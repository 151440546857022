import React, { Fragment, useEffect, useState } from 'react'
import planimetria from '../../assets/planimetria_ti.jpg'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import OpenWithIcon from '@mui/icons-material/OpenWith'

import AddIcon from '@mui/icons-material/Add'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import UndoIcon from '@mui/icons-material/UndoRounded'
import RedoIcon from '@mui/icons-material/RedoRounded'
import RestorePageIcon from '@mui/icons-material/RestorePageRounded'

import {
  Button,
  ButtonGroup,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  DialogContentText,
  Backdrop,
  CircularProgress,
  Collapse,
  Alert,
  Link,
  Stack,
  Grid,
} from '@mui/material'

import { getPostazioni, postPostazioni } from '../../services/reservationServices'
import { useRef } from 'react'
import { DeleteForever } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import DeskIcon from '../../components/reservationComponents/DeskIcon'
import { useBlocker, useNavigate } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import Swal from 'sweetalert2'

const nullError = {
  message: "This error message shouldn't be visible",
  action: () => {},
  actionMessage: 'this should do nothing',
}

const EditableMap = () => {
  const navigate = useNavigate()

  const [desks, setDesks] = useState({})
  const [deskHistory, setDeskHistory] = useState([])
  const [historyIndex, setHistoryIndex] = useState(0)
  const [needFetch, setNeedFetch] = useState(true)
  const [needSave, setNeedSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(nullError)
  const [wasFetching, setWasFetching] = useState(true)
  const [tool, setTool] = useState(null)
  const [unsavedEdits, setUnsavedEdits] = useState(false)
  const [stickyRange, setStickyRange] = useState(0)
  const [selectedDeskIndex, setSelectedDeskIndex] = useState(-1)
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const [openDialogNewDesk, setOpenDialogNewDesk] = useState(false)
  const [openDialogSave, setOpenDialogSave] = useState(false)
  const [helperText, setHelperText] = useState(false)
  const newDeskIdInputRef = useRef(null)

  useEffect(() => {
    if (isMobileOnly) {
      Swal.fire({
        html: 'Sorry... questa sezione al momento può essere utilizzata solamente in modalità desktop.',
        confirmButtonText: 'Ok!',
        confirmButtonColor: '#289b38',
        toast: true,
        customClass: {
          container: 'my-sweet-alert-absence',
          confirmButton: 'swal-button',
        },
      }).then(() => {
        navigate('/')
      })
    }
  }, [navigate])

  useEffect(() => {
    if (!needFetch || loading) {
      return () => {}
    }
    setNeedFetch(false)
    setLoading(true)
    getPostazioni()
      .then((res) => {
        setLoading(false)
        handleFreshData(res)
        setError(nullError)
      })
      .catch((err) => {
        setLoading(false)
        setError({
          message: 'Non è stato possibile scaricare i dati aggiornati.',
          action: () => setNeedFetch(true),
          actionMessage: 'Riprova',
        })
        navigate('/')
      })
  }, [needFetch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!unsavedEdits || !needSave || loading) {
      return () => {}
    }
    setNeedSave(false)
    setLoading(true)
    postPostazioni(Object.values(desks))
      .then((res) => {
        setLoading(false)
        handleFreshData(res)
        setError(nullError)
      })
      .catch((err) => {
        let errorMessage = 'Non è stato possibile salvare le modifiche.'
        if (err.response && err.response.data) {
          errorMessage = err.response.data.message || errorMessage
        }
        setError({
          message: errorMessage,
          action: () => setNeedSave(true),
          actionMessage: 'Riprova',
        })
        setLoading(false)
        console.error(err)
      })
  }, [needSave]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!wasFetching) {
      setUnsavedEdits(true)
    }
    setWasFetching(false)
  }, [desks]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedDeskIndex(-1)
    setDesks((desks) => (historyIndex > 0 ? deskHistory[historyIndex - 1] : desks))
  }, [historyIndex]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedEdits) {
        const message = 'Hai delle modifiche non salvate. Sei sicuro di voler lasciare la pagina?'
        event.preventDefault()
        event.returnValue = message
        return message
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [unsavedEdits])

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      unsavedEdits === true && historyIndex > 1 && currentLocation.pathname !== nextLocation.pathname
  )

  const handleFreshData = (data) => {
    const newDesks = data.reduce((desks, desk) => Object.assign(desks, { [desk.id]: desk }), {})
    setDeskHistory([newDesks])
    setHistoryIndex(1)
    setWasFetching(true)
    setNeedSave(false)
    setUnsavedEdits(false)
    handleToolChange(null, null)
  }

  const handleNewDeskVersion = (newDesks) => {
    const newHistory = [...deskHistory.slice(0, historyIndex), newDesks]
    setDeskHistory(newHistory)
    setHistoryIndex((i) => i + 1)
    setUnsavedEdits(true)
  }

  const handleUndo = () => {
    if (historyIndex > 1) {
      setHistoryIndex((historyIndex) => Math.max(historyIndex - 1, 0))
    }
  }

  const handleRedo = () => {
    setHistoryIndex((historyIndex) => Math.min(historyIndex + 1, deskHistory.length))
    setUnsavedEdits(false)
  }

  const handleToolChange = (event, tool) => {
    setTool(tool)
    setSelectedDeskIndex(-1)
  }

  const handleSaveClick = () => {
    setNeedSave(unsavedEdits && historyIndex !== 1)
  }

  const handleMouseDown =
    tool === 'translate' || tool === 'rotate'
      ? (deskIndex, event) => {
          const thetaRad = (desks[deskIndex].theta * Math.PI) / 180
          const cosTheta = Math.cos(thetaRad)
          const sinTheta = Math.sin(thetaRad)

          const { offsetX, offsetY } = event.nativeEvent
          const [oX, oY] = [offsetX - 14.5, offsetY - 10]

          setOffset({
            x: cosTheta * oX + sinTheta * oY + 14.5,
            y: -sinTheta * oX + cosTheta * oY + 10,
          })
          setSelectedDeskIndex(deskIndex)
        }
      : tool === 'delete'
        ? (deskIndex, event) => {
            // qui passo l'id della postazione
            setSelectedDeskIndex(deskIndex)
          }
        : (deskIndex, event) => {}

  const handleMouseUp =
    tool === 'translate' || tool === 'rotate'
      ? () => {
          if (
            selectedDeskIndex in desks &&
            (deskHistory.length === 0 ||
              desks[selectedDeskIndex].x !== deskHistory.slice(-1)[0][selectedDeskIndex].x ||
              desks[selectedDeskIndex].y !== deskHistory.slice(-1)[0][selectedDeskIndex].y ||
              desks[selectedDeskIndex].theta !== deskHistory.slice(-1)[0][selectedDeskIndex].theta)
          ) {
            handleNewDeskVersion(desks)
          }
          setSelectedDeskIndex(-1)
        }
      : tool === 'delete'
        ? () => {
            if (selectedDeskIndex in desks) {
              const newDesks = { ...desks }
              delete newDesks[selectedDeskIndex]
              handleNewDeskVersion(newDesks)
            }
            setSelectedDeskIndex(-1)
          }
        : () => {
            setSelectedDeskIndex(-1)
          }

  const handleMouseMove =
    tool === 'translate'
      ? (event) => {
          if (!(selectedDeskIndex in desks)) {
            return
          }
          const rect = event.currentTarget.getBoundingClientRect()
          const mapX = event.clientX - rect.left - offset.x
          const mapY = event.clientY - rect.top - offset.y

          const newPos = {
            ...desks[selectedDeskIndex],
            x: stickTranslate(mapX, stickyX(selectedDeskIndex, desks, stickyRange)),
            y: stickTranslate(mapY, stickyY(selectedDeskIndex, desks, stickyRange)),
          }
          setDesks({ ...desks, [selectedDeskIndex]: newPos })
        }
      : tool === 'rotate'
        ? (event) => {
            if (!(selectedDeskIndex in desks)) {
              return
            }
            const { x, y } = desks[selectedDeskIndex]

            const rect = event.currentTarget.getBoundingClientRect()
            const cursorX = event.clientX - rect.left - offset.x
            const cursorY = event.clientY - rect.top - offset.y

            const newTheta =
              (((x !== cursorX ? -Math.atan2(cursorY - y, cursorX - x) : (Math.sign(y - cursorY) * Math.PI) / 2) *
                180) /
                Math.PI +
                270) %
              360
            const newPos = {
              ...desks[selectedDeskIndex],
              theta: stickRotate(newTheta, stickyTheta(selectedDeskIndex, desks, stickyRange)),
            }
            setDesks((desks) => ({ ...desks, [selectedDeskIndex]: newPos }))
          }
        : () => {}

  const handleCreateDesk = () => {
    const nuovoId = newDeskIdInputRef.current.querySelector('input').value

    if (desks[nuovoId]) {
      // alert("scrivania con stesso ID esiste già");
      setHelperText(true)
      return
    }

    const nuovaPostazione = {
      id: nuovoId,
      x: 0,
      y: 0,
      theta: 0,
    }
    handleNewDeskVersion({ ...desks, [nuovoId]: nuovaPostazione })
    setTool('translate')
    handleCloseDialog()
  }

  const handleCloseDialog = () => {
    setOpenDialogNewDesk(false)
    newDeskIdInputRef.current.querySelector('input').value = ''
  }

  return (
    <Grid
      item
      sx={{
        mt: '2.5em',
      }}
    >
      <Dialog open={openDialogNewDesk} onClose={handleCloseDialog}>
        <DialogTitle>Inserisci ID nuova postazione</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            ref={newDeskIdInputRef}
            autoFocus
            required={true}
            placeholder="Es. A00"
            onChange={() => {
              setHelperText(false)
            }}
            helperText={helperText ? 'Scrivania con stesso nome esiste già' : ''}
            sx={{
              '& .MuiFormHelperText-root': {
                color: 'red',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={handleCreateDesk}>Crea</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogSave} onClose={() => setOpenDialogSave(false)}>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler salvare? <br /> Se stai eliminando una postazione, tutte le prenotazioni associate
            saranno cancellate.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleUndo()
              setOpenDialogSave(false)
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={() => {
              handleSaveClick()
              setOpenDialogSave(false)
            }}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>

      {blocker.state === 'blocked' ? (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogTitle sx={{ textAlign: 'center', color: 'info.main' }}>Modifiche non salvate</DialogTitle>
            <DialogContentText sx={{ textAlign: 'center' }}>
              Sei sicuro di voler lasciare la pagina?
              <br /> Le modifiche non salvate andranno perse.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={blocker.reset} sx={{ textTransform: 'none' }}>
              Annulla
            </Button>
            <Button onClick={blocker.proceed} color="primary" autoFocus sx={{ textTransform: 'none' }}>
              Procedi
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <ButtonGroup color="primary" variant="outlined">
          <ButtonGroup>
            <Button onClick={() => setOpenDialogNewDesk(true)}>
              <Tooltip arrow title="Aggiungi">
                <AddIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>

          <ButtonGroup sx={{ marginLeft: '0.5em' }}>
            <Button disableRipple>
              <Tooltip arrow title="Allineamento automatico">
                <Slider
                  defaultValue={0}
                  onChange={(e) => setStickyRange(e.target.value / 100)}
                  color="primary"
                  sx={{ width: 150 }}
                />
              </Tooltip>
            </Button>
          </ButtonGroup>

          <ToggleButtonGroup
            sx={{ marginLeft: '0.5em' }}
            value={tool}
            exclusive
            onChange={handleToolChange}
            color={'primary'}
          >
            <ToggleButton value={'translate'} disableTouchRipple sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <Tooltip arrow title="Muovi">
                <OpenWithIcon />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value={'rotate'} disableTouchRipple sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <Tooltip arrow title="Ruota">
                <RotateLeftIcon />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value={'delete'} disableTouchRipple sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <Tooltip arrow title="Elimina">
                <DeleteForever />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          <ButtonGroup sx={{ marginLeft: '0.5em' }}>
            <Button disabled={historyIndex <= 1} onClick={() => handleUndo()}>
              <Tooltip arrow title="Annulla">
                <UndoIcon />
              </Tooltip>
            </Button>

            <Button disabled={historyIndex === deskHistory.length} onClick={() => handleRedo()}>
              <Tooltip arrow title="Ripristina modifica">
                <RedoIcon />
              </Tooltip>
            </Button>

            <Button
              disabled={!(unsavedEdits && historyIndex !== 1)}
              onClick={() => {
                setHistoryIndex(1)
                setUnsavedEdits(false)
              }}
            >
              <Tooltip arrow title="Annulla tutte le modifiche">
                <RestorePageIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>

          <ButtonGroup sx={{ marginLeft: '0.5em' }}>
            <Button
              disabled={!unsavedEdits || historyIndex <= 1}
              onClick={() => {
                setOpenDialogSave(true)
              }}
            >
              Salva
            </Button>
          </ButtonGroup>
        </ButtonGroup>
      </Grid>

      {/* AREA POSTAZIONI */}
      <Box
        sx={{
          position: 'relative',
          margin: '1em auto',
          width: 'fit-content',
          height: 'fit-content',
          userSelect: 'none',
        }}
      >
        <img src={planimetria} width="800px" alt="" style={{ userSelect: 'none', mixBlendMode: 'darken' }} />

        {Object.values(desks).map((desk) => (
          <Fragment key={desk.id}>
            <Tooltip arrow title={'Postazione ' + desk.id}>
              <Box
                {...((tool === 'translate' || tool === 'delete') && {
                  onMouseDown: (event) => handleMouseDown(desk.id, event),
                })}
                sx={{
                  position: 'absolute',
                  top: `${desks[desk.id].y}px`,
                  left: `${desks[desk.id].x}px`,
                  transform: `rotate(${-desks[desk.id].theta}deg)`,
                  width: '29px',
                  height: '20px',
                  zIndex: selectedDeskIndex === desk.id ? 2 : 1,
                  ...(tool === 'translate' && { cursor: 'grab' }),
                  ...(tool === 'delete' && { cursor: 'pointer' }),
                }}
              >
                <DeskIcon state={{ empty: false, disabled: true }} />
              </Box>
            </Tooltip>

            {tool === 'rotate' && (selectedDeskIndex === desk.id || !(selectedDeskIndex in desks)) && (
              <Box
                onMouseDown={(event) => handleMouseDown(desk.id, event)}
                sx={{
                  position: 'absolute',
                  top: `${desks[desk.id].y}px`,
                  left: `${desks[desk.id].x}px`,
                  transform: `rotate(${-desks[desk.id].theta}deg) translate(0, -30px)`,
                  width: '29px',
                  height: '20px',
                  boxSizing: 'border-box',
                  zIndex: selectedDeskIndex === desk.id ? 3 : 2,
                  cursor: 'grab',
                }}
              >
                <RotateLeftIcon sx={{ border: '0.5px solid black', borderRadius: '100%' }} />
              </Box>
            )}
          </Fragment>
        ))}
        {selectedDeskIndex in desks && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 5,
              userSelect: 'none',
              ...(tool === 'translate' && { cursor: 'grabbing' }),
            }}
            onMouseMove={(event) => handleMouseMove(event)}
            onMouseUp={() => handleMouseUp()}
            onMouseLeave={() => handleMouseUp()}
          ></Box>
        )}
      </Box>
      <Stack sx={{ position: 'fixed', bottom: '0.5em', left: '4em', right: '1em' }}>
        <Collapse in={error !== nullError && !loading}>
          <Alert
            severity={'error'}
            action={
              <Link onClick={error.action} sx={{ cursor: 'pointer' }}>
                {error.actionMessage}
              </Link>
            }
            sx={{ marginTop: '0.5em' }}
          >
            {error.message}
          </Alert>
        </Collapse>
      </Stack>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  )
}

export default EditableMap

function stickTranslate(value, stickyValues) {
  const candidates = stickyValues.filter((sticky) => Math.abs(value - sticky) <= 5)
  if (candidates.length === 0) {
    return value
  }
  return candidates.reduce((bestSoFar, current) =>
    Math.abs(current - value) < Math.abs(bestSoFar - value) ? current : bestSoFar
  )
}

function stickRotate(value, stickyValues) {
  value = (value + 360) % 360
  const candidates = stickyValues.filter(
    (sticky) => Math.abs((value - sticky) % 360) <= 15 || Math.abs((value - sticky) % 360) >= 345
  )
  if (candidates.length === 0) {
    return value
  }
  return candidates.reduce((bestSoFar, current) =>
    Math.abs((current - value) % 360) < Math.abs((bestSoFar - value) % 360) ? current : bestSoFar
  )
}

function stickyX(index, desks, stickyRange) {
  return Object.values(desks)
    .filter(
      (desk) =>
        desk.id !== index &&
        (desk.x - desks[index].x) * (desk.x - desks[index].x) + (desk.y - desks[index].y) * (desk.y - desks[index].y) <
          stickyRange * 10000
    )
    .map((pos) => pos.x)
}

function stickyY(index, desks, stickyRange) {
  return Object.values(desks)
    .filter(
      (desk) =>
        desk.id !== index &&
        (desk.x - desks[index].x) * (desk.x - desks[index].x) + (desk.y - desks[index].y) * (desk.y - desks[index].y) <
          stickyRange * 10000
    )
    .map((pos) => pos.y)
}

function stickyTheta(index, desks, stickyRange) {
  return Object.values(desks)
    .filter(
      (desk) =>
        desk.id !== index &&
        (desk.x - desks[index].x) * (desk.x - desks[index].x) + (desk.y - desks[index].y) * (desk.y - desks[index].y) <
          stickyRange * 10000
    )
    .map((pos) => pos.theta)
    .flatMap((theta) => [theta, (theta + 90) % 360, (theta + 180) % 360, (theta + 270) % 360])
}
