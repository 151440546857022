import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import {
  Alert,
  Collapse,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  Stack,
  Switch,
  TextField,
} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import DeleteIcon from '@mui/icons-material/Delete'

import ButtonSubmit from './ButtonSubmit'
import { deletePrenotazione, postPrenotazione } from '../../services/reservationServices'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useAuth } from '../authentication/AuthProvider'
import Swal from 'sweetalert2'
import '../../styles/Absence.css'

dayjs.extend(utc)
dayjs.extend(timezone)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FormDialog(props) {
  const {
    open,
    date,
    onClose,
    onSubmit,
    settings,
    // prenotazioniUser,
    raggiuntoLimitePrenotazioni,
    chiusureStraordinarie,
    everybodyPrenotazioni,
    allPrenotazioniUser,
  } = props

  dayjs.tz.setDefault(settings.timeZoneId)

  const { username, isAdmin } = useAuth()

  const localTime = (dayjsObj) => dayjs(dayjsObj).format('HH:mm')
  const localDay = (dayjsObj) => dayjs(dayjsObj).format('DD/MM/YYYY')
  // const localDayMatch = (dayjsObj) => dayjs(dayjsObj).format('YYYY-MM-DD');
  const timestampFromLocalDateTime = (dayjsDate, localTime) => {
    if (!localTime) {
      return null
    }
    if (!dayjsDate || typeof dayjsDate.hour !== 'function') {
      return null
    }
    // estraggo ore e minuti dalla stringa di tempo
    const [hours, minutes] = localTime.split(':').map(Number)
    // imposto ora e minuto sull'oggetto dayjs
    return dayjsDate.hour(hours).minute(minutes).second(0).millisecond(0).valueOf()
  }

  const { desk, freeSlots, state } = props.deskInfo
  // const switchGiornataInteraRef = useRef(null);
  const switchSettimanaInteraRef = useRef(null)
  const switchMeseInteroRef = useRef(null)
  const [switchGiornataIntera, setSwitchGiornataIntera] = useState(false)
  const [switchSettimanaIntera, setSwitchSettimanaIntera] = useState(false)
  const [switchMeseIntero, setSwitchMeseIntero] = useState(false)

  const [requestStatus, setRequestStatus] = useState(null)

  const [oraInizio, setOraInizio] = useState(freeSlots.length > 0 ? localTime(freeSlots[0].inizio) : null)
  const [oraFine, setOraFine] = useState(freeSlots.length > 0 ? localTime(freeSlots[0].fine) : null)

  const [collapseIn, setCollapseIn] = useState(false)
  // const [mostraDettaglioErrore, setMostraDettaglioErrore] = useState({});

  const [allPrenotazioni, setAllPrenotazioni] = useState([])

  useEffect(() => {
    const prenotazioniDivise = isAdmin ? everybodyPrenotazioni : allPrenotazioniUser
    setAllPrenotazioni(prenotazioniDivise)
  }, [isAdmin, everybodyPrenotazioni, allPrenotazioniUser])

  const getOraInizio = () => (switchGiornataIntera ? settings.orarioApertura : oraInizio)
  const getOraFine = () => (switchGiornataIntera ? settings.orarioChiusura : oraFine)

  const timestampInizio = timestampFromLocalDateTime(date, getOraInizio())
  const timestampFine = timestampFromLocalDateTime(date, getOraFine())

  const [errori, setErrori] = useState([])

  function handleDeletePrenotazione(prenotazioneId) {
    deletePrenotazione(prenotazioneId)
    setAllPrenotazioni((prenotazioniAttuali) => prenotazioniAttuali.filter((p) => p.id !== prenotazioneId))
  }

  function calcolaErrori({
    isAdmin,
    switchSettimanaIntera,
    switchMeseIntero,
    date,
    chiusureStraordinarie,
    settings,
    desk,
    allPrenotazioni,
    username,
  }) {
    let erroreConflittoPrenotazioni = {
      messaggio: 'Esistono delle prenotazioni per questa fascia oraria',
      presente: false,
      dettagli: null,
      codiceErrore: 5,
    }

    if (
      (isAdmin && (switchSettimanaIntera || switchMeseIntero)) ||
      (!isAdmin && state.availableForUser && (switchSettimanaIntera || switchMeseIntero))
    ) {
      let dateArray = switchSettimanaIntera
        ? getWeekDates(date, chiusureStraordinarie)
        : getMonthDates(date, chiusureStraordinarie)

      const presentaConflitti = dateArray.some((dayTimestamp) => {
        const timestampInizioGiorno = dayTimestamp
        const timestampFineGiorno = dayjs(dayTimestamp).endOf('day').valueOf()

        return allPrenotazioni.some(
          (prenotazione) => prenotazione.inizio < timestampFineGiorno && prenotazione.fine > timestampInizioGiorno
        )
      })

      if (presentaConflitti) {
        erroreConflittoPrenotazioni.presente = true
        erroreConflittoPrenotazioni.dettagli = allPrenotazioni
          .filter((prenotazione) => {
            return dateArray.some((dayTimestamp) => {
              const timestampInizioGiorno = dayTimestamp
              const timestampFineGiorno = dayjs(dayTimestamp).endOf('day').valueOf()
              return prenotazione.inizio < timestampFineGiorno && prenotazione.fine > timestampInizioGiorno
            })
          })
          .map((prenotazione) => (
            <li key={prenotazione.id}>
              <strong>{localDay(prenotazione.inizio)}</strong> -<strong>{localTime(prenotazione.inizio)}</strong> -
              <strong>{localTime(prenotazione.fine)}</strong> :
              <br /> {prenotazione.name}
              <br />
              Postazione: {prenotazione.postazioneId}
              <br />
              {(prenotazione.username === username || isAdmin) && (
                <>
                  Vuoi eliminare?
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    size="small"
                    onClick={() => handleDeletePrenotazione(prenotazione.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </li>
          ))
      }
    }

    const durataPrenotazioneInOre = (timestampFine - timestampInizio) / (1000 * 60 * 60)
    //console.log('Durata nuova prenotazione in ore:', durataPrenotazioneInOre);

    const durataTotalePrenotazioniUtente = allPrenotazioni
      .filter((prenotazione) => {
        const stessaData = dayjs(prenotazione.inizio).isSame(dayjs(timestampInizio), 'day')
        const stessoUtente = prenotazione.username === username
        return stessoUtente && stessaData
      })
      .reduce((totale, prenotazione) => {
        const durataPrenotazione = (prenotazione.fine - prenotazione.inizio) / (1000 * 60 * 60)
        return totale + durataPrenotazione
      }, 0)

    // console.log('Durata totale prenotazioni esistenti in ore:', durataTotalePrenotazioniUtente);

    const durataTotaleConNuovaPrenotazione = durataTotalePrenotazioniUtente + durataPrenotazioneInOre

    // console.log('Durata totale con nuova prenotazione in ore:', durataTotaleConNuovaPrenotazione);

    const erroriAggiornati = [
      {
        messaggio: 'La durata della prenotazione è negativa.',
        presente: timestampFine - timestampInizio < 0,
        dettagli: null,
        codiceErrore: 0,
      },
      {
        messaggio: 'La durata della prenotazione è troppo breve.',
        presente:
          0 <= timestampFine - timestampInizio && timestampFine - timestampInizio < settings.durataMinimaPrenotazione, //1 ora no possibile
        dettagli: null,
        codiceErrore: 1,
      },
      {
        messaggio: `Hai inserito un orario antecedente all'apertura ${settings.orarioApertura}.`,
        presente: getOraInizio() + ':00' < settings.orarioApertura,
        dettagli: null,
        codiceErrore: 2,
      },
      {
        messaggio: `Hai inserito un orario successivo alla chiusura ${settings.orarioChiusura}.`,
        presente: getOraFine() > settings.orarioChiusura,
        dettagli: null,
        codiceErrore: 3,
      },
      {
        messaggio: `Esistono già delle prenotazioni per questa postazione nella fascia oraria che hai scelto.`,
        presente: allPrenotazioni.some(
          (prenotazione) =>
            prenotazione.inizio < timestampFine &&
            prenotazione.fine > timestampInizio &&
            prenotazione.postazioneId === desk.id &&
            dayjs(prenotazione.inizio).isSame(dayjs(timestampInizio), 'day')
        ),
        dettagli: (
          <ul>
            {allPrenotazioni
              .filter(
                (prenotazione) =>
                  prenotazione.postazioneId === desk.id &&
                  dayjs(prenotazione.inizio).isSame(dayjs(timestampInizio), 'day')
              )
              .map((prenotazione) => (
                <li key={prenotazione.id}>
                  <strong>{dayjs(prenotazione.inizio).format('HH:mm')}</strong> -
                  <strong>{dayjs(prenotazione.fine).format('HH:mm')}</strong>:{prenotazione.username}
                  <br />
                  {prenotazione.postazioneId}
                </li>
              ))}
          </ul>
        ),
        codiceErrore: 4,
      },
      {
        messaggio: 'Non puoi effettuare più di ' + settings.maxPrenotazioniSettimanali + ' prenotazioni a settimana',
        presente: raggiuntoLimitePrenotazioni,
        dettagli: null,
        codiceErrore: 6,
      },
      {
        messaggio: 'Non puoi prenotare nel passato',
        presente: timestampFine <= new Date().getTime(),
        dettagli: null,
        codiceErrore: 7,
      },
      {
        messaggio: 'Hai superato le 9 ore giornaliere.',
        presente: durataTotaleConNuovaPrenotazione > 9,
        dettagli: null,
        codiceErrore: 8,
      },
      erroreConflittoPrenotazioni,
    ]

    return erroriAggiornati
  }

  useEffect(() => {
    const erroriAggiornati = calcolaErrori({
      isAdmin,
      everybodyPrenotazioni,
      allPrenotazioniUser,
      switchSettimanaIntera,
      switchMeseIntero,
      date,
      chiusureStraordinarie,
      timestampInizio,
      timestampFine,
      settings,
      desk,
      allPrenotazioni,
      username,
    })
    setErrori(erroriAggiornati)
  }, [
    timestampInizio,
    timestampFine,
    state.availableForUser,
    switchSettimanaIntera,
    switchMeseIntero,
    date,
    chiusureStraordinarie,
    allPrenotazioni,
  ])

  useEffect(() => {
    if (!open && freeSlots.length) {
      setOraInizio(localTime(freeSlots[0].inizio))
      setOraFine(localTime(freeSlots[0].fine))
      setSwitchGiornataIntera(false)
    }
  }, [desk])

  //giusto ora
  useEffect(() => {
    setSwitchGiornataIntera(false)
    setRequestStatus(null)
    if (state.availableForUser && freeSlots.length && !isAdmin) {
      setOraInizio(localTime(freeSlots[0].inizio))
      setOraFine(localTime(freeSlots[0].fine))
    } else {
      setOraInizio(settings.orarioApertura)
      setOraFine(settings.orarioChiusura)
    }
  }, [open])

  useEffect(() => {
    setRequestStatus(null)
  }, [oraInizio, oraFine])

  function getWeekDates(startDate, chiusureStraordinarie) {
    let dates = []
    let currentDate = dayjs(startDate)

    while (dates.length < 5 && currentDate.day() !== 6) {
      if (currentDate.day() !== 0 && !chiusureStraordinarie.includes(currentDate.format('YYYY-MM-DD'))) {
        dates.push(currentDate.startOf('day').valueOf())
      }
      currentDate = currentDate.add(1, 'day')
    }
    return dates
  }

  function getMonthDates(startDate, chiusureStraordinarie) {
    let dates = []
    let currentDate = dayjs(startDate)
    const startMonth = currentDate.month()

    while (currentDate.month() === startMonth) {
      if (
        currentDate.day() !== 0 &&
        currentDate.day() !== 6 &&
        !chiusureStraordinarie.includes(currentDate.format('YYYY-MM-DD'))
      ) {
        dates.push(currentDate.startOf('day').valueOf())
      }
      currentDate = currentDate.add(1, 'day')
    }
    return dates
  }

  const prenotazione = [
    {
      username: username,
      postazioneId: desk.id,
      inizio: timestampInizio,
      fine: timestampFine,
    },
  ]

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} transitionDuration={400}>
      <DialogTitle>Nuova prenotazione</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              label="Utente"
              sx={{ pointerEvents: 'none' }}
              readOnly={true}
              margin="dense"
              type="text"
              fullWidth
              variant="standard"
              value={username}
              disabled={!!username}
              focused={false}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Postazione"
              sx={{ pointerEvents: 'none' }}
              margin="dense"
              type="text"
              fullWidth
              variant="standard"
              value={desk ? desk.id : undefined}
              disabled={desk ? !!desk.id : undefined}
              focused={false}
              InputProps={{
                readOnly: true,
              }}
              error={errori.some((errore) => errore.codiceErrore === 4 && errore.presente)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Giorno"
              margin="dense"
              type="date"
              fullWidth
              variant="standard"
              value={date.format('YYYY-MM-DD')}
              focused={false}
              disabled={!!date || switchGiornataIntera || switchSettimanaIntera || switchMeseIntero}
              sx={{ pointerEvents: 'none' }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Inizio"
              value={getOraInizio()}
              onChange={(e) => setOraInizio(e.target.value)}
              margin="dense"
              type="time"
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              sx={requestStatus === 'success' || requestStatus === 'pending' ? { pointerEvents: 'none' } : {}}
              InputProps={{
                readOnly: requestStatus === 'success' || requestStatus === 'pending',
              }}
              // disabled={(switchGiornataIntera || switchSettimanaIntera || switchMeseIntero) && !isAdmin}

              error={errori.some(
                (errore) =>
                  (errore.codiceErrore === 1 && errore.presente) ||
                  (errore.codiceErrore === 2 && errore.presente) ||
                  (errore.codiceErrore === 4 && errore.presente) ||
                  (errore.codiceErrore === 5 && errore.presente)
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Fine"
              value={getOraFine()}
              onChange={(e) => setOraFine(e.target.value)}
              margin="dense"
              type="time"
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              sx={requestStatus === 'success' || requestStatus === 'pending' ? { pointerEvents: 'none' } : {}}
              InputProps={{
                readOnly: requestStatus === 'success' || requestStatus === 'pending',
              }}
              // disabled={(switchGiornataIntera || switchSettimanaIntera || switchMeseIntero) && !isAdmin}
              error={errori.some(
                (errore) =>
                  (errore.codiceErrore === 0 && errore.presente) ||
                  (errore.codiceErrore === 1 && errore.presente) ||
                  (errore.codiceErrore === 3 && errore.presente) ||
                  (errore.codiceErrore === 4 && errore.presente) ||
                  (errore.codiceErrore === 5 && errore.presente) ||
                  (errore.codiceErrore === 8 && errore.presente)
              )}
            />
          </Grid>
        </Grid>

        <Grid item display={'flex'} mt={6} flexDirection={'column'} flexWrap={'wrap'}>
          <Grid item display={'flex'} xs={12} gap={2}>
            <>
              {/* <Grid item >
              <FormControlLabel
                label="Giornata intera"
                control={
                  <Switch
                    ref={switchGiornataInteraRef}
                    onChange={() =>
                      setSwitchGiornataIntera(
                        switchGiornataInteraRef.current.querySelector("input").checked
                      )
                    }
                    disabled={
                      prenotazioni.length !== 0 ||
                        state.availableForUser ? false : prenotazioniUser.length !== 0 ||
                        prenotazioniUser.length !== 0 ||
                        requestStatus === "success" ||
                        requestStatus === "pending" ||
                        switchSettimanaIntera ||
                      switchMeseIntero
                    }
                  />
                }
              />
            </Grid> */}
            </>

            <Grid item>
              <FormControlLabel
                label="Settimana intera"
                control={
                  <Switch
                    ref={switchSettimanaInteraRef}
                    onChange={() =>
                      setSwitchSettimanaIntera(switchSettimanaInteraRef.current.querySelector('input').checked)
                    }
                    disabled={
                      requestStatus === 'success' ||
                      requestStatus === 'pending' ||
                      switchGiornataIntera ||
                      switchMeseIntero
                      // (!isAdmin && prenotazioniUser.length !== 0)
                    }
                  />
                }
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                label="Mese intero"
                control={
                  <Switch
                    ref={switchMeseInteroRef}
                    onChange={() => setSwitchMeseIntero(switchMeseInteroRef.current.querySelector('input').checked)}
                    disabled={
                      requestStatus === 'success' ||
                      requestStatus === 'pending' ||
                      switchGiornataIntera ||
                      switchSettimanaIntera
                      // (!isAdmin && prenotazioniUser.length !== 0)
                    }
                  />
                }
              />
            </Grid>
          </Grid>

          <Grid item display={'flex'} xs={12} gap={2} mt={3} justifyContent={'flex-end'}>
            {requestStatus !== 'success' && (
              <Button
                onClick={() => {
                  onClose()
                  setSwitchSettimanaIntera(false)
                  setSwitchMeseIntero(false)
                  setCollapseIn(false)
                }}
                variant="outlined"
                size="small"
              >
                Chiudi
              </Button>
            )}

            <ButtonSubmit
              variant="button"
              key={getOraInizio() + getOraFine()}
              fetch={async () => {
                try {
                  setRequestStatus('pending')
                  let prenotazioni = []

                  if (switchSettimanaIntera) {
                    const dateArray = getWeekDates(date, chiusureStraordinarie)
                    prenotazioni = dateArray.map((data) => {
                      const timestampInizio = timestampFromLocalDateTime(dayjs(data), getOraInizio())
                      const timestampFine = timestampFromLocalDateTime(dayjs(data), getOraFine())

                      return {
                        username: username,
                        postazioneId: desk.id,
                        inizio: timestampInizio,
                        fine: timestampFine,
                      }
                    })
                  } else if (switchMeseIntero) {
                    const dateArray = getMonthDates(date, chiusureStraordinarie)
                    prenotazioni = dateArray.map((data) => {
                      const timestampInizio = timestampFromLocalDateTime(dayjs(data), getOraInizio())
                      const timestampFine = timestampFromLocalDateTime(dayjs(data), getOraFine())

                      return {
                        username: username,
                        postazioneId: desk.id,
                        inizio: timestampInizio,
                        fine: timestampFine,
                      }
                    })
                  } else {
                    // come prima
                    prenotazioni.push(...prenotazione)
                  }

                  let azioneConflitto

                  if (errori.some((errore) => errore.codiceErrore === 5 && errore.presente) && isAdmin) {
                    // in caso di conflitti,gestisco
                    const { value: azioneUtente } = await Swal.fire({
                      toast: true,
                      title: "Seleziona un'azione per i conflitti",
                      input: 'select',
                      customClass: {
                        container: 'sweet-alert-container',
                      },
                      inputOptions: {
                        days_without_conflict: 'Prenota solo i giorni non in conflitto',
                        overwrite: 'Sovrascrivi le prenotazioni esistenti',
                        interrupted: 'Annulla tutto',
                      },
                      inputPlaceholder: "Seleziona un'azione",
                      showCancelButton: true,
                      inputValidator: (value) => {
                        return new Promise((resolve) => {
                          if (value) {
                            resolve()
                          } else {
                            resolve("Devi selezionare un'azione")
                          }
                        })
                      },
                    })
                    // azione predefinita
                    azioneConflitto = azioneUtente ? azioneUtente : 'interrupted'
                  } else {
                    // senza errore 5 azione default be
                    azioneConflitto = 'days_without_conflict'
                  }

                  if (azioneConflitto !== 'interrupted') {
                    // se lo user non ha definito annulla vado avanti
                    const result = await postPrenotazione(prenotazioni, azioneConflitto)

                    // gestisco il risultato come prima se ci sono prenot.
                    if (result.prenotazioni && result.prenotazioni.length > 0) {
                      setRequestStatus('success')
                      onClose()
                      onSubmit()
                    }

                    if (result.warnings && result.warnings.length > 0) {
                      console.warn('Avvisi: ', result.warnings.join('\n'))
                      const warningsHtml = `<ul>${result.warnings.map((warning) => `<li>${warning}</li>`).join('')}</ul>`
                      Swal.fire({
                        toast: false,
                        icon: 'warning',
                        title: 'Avvisi',
                        html: warningsHtml,
                        // html: result.warnings.join("\n"),
                        position: 'center',
                        showConfirmButton: false,
                        showCloseButton: true,
                        // timer: 6000,
                        customClass: {
                          container: 'sweet-alert-container',
                        },
                      })
                    }
                  } else {
                    // annullamento azione qui
                    setRequestStatus('error')
                    Swal.fire({
                      toast: true,
                      icon: 'error',
                      title: 'Annullato',
                      text: 'Nessuna prenotazione è stata inviata',
                      position: 'center',
                      showConfirmButton: false,
                      showCloseButton: true,
                      // timer: 4000,
                      customClass: {
                        container: 'sweet-alert-container',
                      },
                    })
                  }
                } catch (error) {
                  setRequestStatus('error')
                }
              }}
              icon={<EventAvailableOutlinedIcon />}
              iconSuccess={<CheckCircleOutlineRoundedIcon />}
              iconError={<ErrorOutlineRoundedIcon />}
              text={'Prenota'}
              textSuccess={'Chiudi'}
              textError={'Riprova'}
              onClickAfterSuccess={() => {
                onSubmit()
                setSwitchSettimanaIntera(false)
                setSwitchMeseIntero(false)
                onClose()
              }}
              retryAfterError={false}
              onSuccess={() => {
                setRequestStatus('success')
                setSwitchSettimanaIntera(false)
                setSwitchMeseIntero(false)
              }}
              onError={() => {
                setRequestStatus('error')
                setSwitchSettimanaIntera(false)
                setSwitchMeseIntero(false)
                onSubmit()
              }}
              disabled={
                errori.filter((err) => err.presente).length !== 0 &&
                !isAdmin &&
                (errori.some((errore) => errore.codiceErrore === 4 && errore.presente) ||
                  errori.some((errore) => errore.codiceErrore === 5 && errore.presente) ||
                  errori.some((errore) => errore.codiceErrore === 8 && errore.presente))
              }
            />
          </Grid>
        </Grid>

        <Stack sx={{ width: '100%', marginTop: '1em' }}>
          {errori
            .filter((errore) => errore.presente)
            .map((errore, i) => (
              <Collapse key={i} in={true}>
                <Alert
                  // severity="error"
                  severity={errore.codiceErrore === 5 ? 'info' : 'error'}
                  sx={{ paddingTop: 0, paddingBottom: 0, marginTop: '5px' }}
                  action={
                    errore.dettagli ? (
                      <Button
                        // color="error"
                        color={errore.codiceErrore === 5 ? 'info' : 'error'}
                        onClick={() => {
                          setCollapseIn(!collapseIn)
                          // setMostraDettaglioErrore(prevState => ({
                          //   ...prevState,
                          //   [errore.codiceErrore]: !prevState[errore.codiceErrore]
                          // }));
                        }}
                      >
                        Dettagli
                      </Button>
                    ) : null
                  }
                >
                  {errore.messaggio}
                  {errore.dettagli && <Collapse in={collapseIn}>{errore.dettagli}</Collapse>}
                </Alert>
              </Collapse>
            ))}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
