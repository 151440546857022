import React from 'react'
import { Typography, Box } from '@mui/material'

const CustomLabel = ({ text, children, variant = 'subtitle1', component = 'legend' }) => {
  return (
    <Box
      sx={{
        border: '1px solid lightgray',
        borderRadius: '4px',
        padding: '8px 16px',
        marginBottom: '16px',
      }}
    >
      <Typography
        variant={variant}
        component={component}
        sx={{
          mb: 1,
          color: 'gray',
          '&.Mui-focused': { color: 'gray' },
        }}
      >
        {text}
      </Typography>
      {children}
    </Box>
  )
}

export default CustomLabel
