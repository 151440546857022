import * as React from 'react'
import SignInButton from '../components/authentication/SignInButton'
import '../styles/login.css'

export default function Login() {
  return (
    <div className="login">
      <h2>Accedi per utilizzare servizi di telematica</h2>
      <div>
        <SignInButton />
      </div>
    </div>
  )
}
