import React from 'react'
import { useEffect, useState } from 'react'
import { Container, Divider, Paper, Stack, Typography } from '@mui/material'
import { ApiError } from '../../../errors/ApiError'
import { PieGraph } from '../../../model/Graph'
import { publishApiError } from '../../../services/eventSercices'
import { getGraphs } from '../../../services/surveyCompilationServices'
import { Pie } from '../compilations/graphs/Pie'
// import {getGraphs} from "../../services/CompilationService.ts";
// import {PieGraph} from "../../model/Graph.ts";
// import {Pie} from "../compilations/graphs/Pie.tsx";
// import {ApiError} from "../../errors/ApiError.ts";
// import {publishApiError} from "../../services/EventService.ts";

type PieViewProps = {
  submittedTemplateId: string
}

export function PieView(props: PieViewProps) {
  const [graphs, setGraphs] = useState<PieGraph[] | undefined>(undefined)

  useEffect(() => {
    getGraphs(props.submittedTemplateId, 'PIE')
      .then((graphs) => {
        setGraphs(graphs as PieGraph[])
      })
      .catch((error: ApiError) => {
        publishApiError(error.details)
      })
      .catch((error: Error) => {
        console.error(error)
      })
  }, [props.submittedTemplateId, setGraphs])

  return (
    <Paper
      elevation={0}
      sx={{
        // overflowY: 'scroll',
        maxHeight: '70dvh',
        backgroundColor: 'inherit',
      }}
    >
      <Stack spacing={2} divider={<Divider orientation="horizontal" variant={'middle'} />}>
        {graphs
          ? graphs.map((graph: PieGraph, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Container
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography gutterBottom>{graph.questionTitle}</Typography>
                  </Container>
                  <Pie values={graph.values} questionTitle={graph.questionTitle} type={'PIE'} />
                </React.Fragment>
              )
            })
          : null}
      </Stack>
    </Paper>
  )
}
