import React, { createContext, useContext, useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { appRoles } from '../../authConfig'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const { accounts } = useMsal()
  const [isAdmin, setAdmin] = useState(false)
  const [username, setUsername] = useState(null)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const account = accounts[0] || null
    if (account) {
      const userRoles = account.idTokenClaims?.roles || []
      setRoles(userRoles)
      setUsername(account.idTokenClaims?.['preferred_username'])
      const adminStatus =
        userRoles.includes(appRoles.AdminAbsence) ||
        userRoles.includes(appRoles.AdminReservation) ||
        userRoles.includes(appRoles.AdminSurvey)
      setAdmin(adminStatus)
    }
  }, [accounts]) // eslint-disable-line react-hooks/exhaustive-deps

  return <AuthContext.Provider value={{ username, isAdmin, roles }}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
