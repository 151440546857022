import React, { useState, useEffect } from 'react'
// import { useMsal } from '@azure/msal-react'
import { Outlet } from 'react-router-dom'
import { useAuth } from './authentication/AuthProvider'

export const RouteGuard = ({ Component, ...props }) => {
  const { roles } = useAuth()
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    userCanAccessToPage()
  }, [roles, props.roles]) // eslint-disable-line react-hooks/exhaustive-deps

  const userCanAccessToPage = () => {
    if (props.roles.length > 0) {
      let intersection = props.roles.filter((role) => roles.includes(role))
      setIsAuthorized(intersection.length > 0)
    } else {
      setIsAuthorized(true)
    }
    if (props.roles.length === 0) {
      setIsAuthorized(true)
    }
  }

  return (
    <>
      {isAuthorized ? (
        <>
          <Component {...props} />
          <Outlet />
        </>
      ) : (
        <div className="data-area-div" style={{ height: '100vh' }}>
          <h3>Non disponi delle permessi necessari per visualizzare questa pagina.</h3>
        </div>
      )}
    </>
  )
}
