// import {NumericQuestion} from "../../../model/Question.ts";
import { Typography } from '@mui/material'
import { NumericQuestion } from '../../../../model/Question'
import { Box } from '@mui/system'
import HdrStrongIcon from '@mui/icons-material/HdrStrong'
import HdrWeakIcon from '@mui/icons-material/HdrWeak'

type NumericQuestionCardProps = {
  question: NumericQuestion
}

export function NumericQuestionCard(props: NumericQuestionCardProps) {
  const minAndMaxDefault = props.question.minValue === -2147483648 && props.question.maxValue === 2147483647
  return (
    <>
      {minAndMaxDefault ? null : (
        <>
          {/* <Typography variant={'body2'}>Min: {props.question.minValue}</Typography>
                    <Typography variant={'body2'}>Max: {props.question.maxValue}</Typography> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              paddingY: 1,
              pl: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <HdrWeakIcon sx={{ marginRight: 1, color: 'primary.main' }} />
              <Typography variant="body2">Min: {props.question.minValue}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <HdrStrongIcon sx={{ marginRight: 1, color: 'primary.main' }} />
              <Typography variant="body2">Max: {props.question.maxValue}</Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
