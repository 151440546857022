import React from 'react'
import { Box, Typography, Container } from '@mui/material'
import errorImage from '../assets/404Image.webp'

function ErrorPage() {
  return (
    <Container maxWidth="sm">
      <Box textAlign="center" p={5}>
        <img src={errorImage} alt="Error" style={{ width: '100%', height: 'auto', marginBottom: '20px' }} />

        <Typography variant="h4" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="subtitle1">We can't seem to find the page you're looking for.</Typography>
        <Typography variant="body1" color="textSecondary">
          Error code: 404
        </Typography>
      </Box>
    </Container>
  )
}

export default ErrorPage
