import { Alert, Box, Collapse, Container, Divider, Paper, Snackbar, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { LoadingButton } from '@mui/lab'
import SendIcon from '@mui/icons-material/Send'
import { useNavigate } from 'react-router-dom'
import { TemplateDetails } from '../../../model/Template'
import { addCompilation } from '../../../services/surveyCompilationServices'
import { ApiError } from '../../../errors/ApiError'
import { publishApiError } from '../../../services/eventSercices'
import { Answer } from './answers/Answer'
import dayjs from 'dayjs'
import '../../../styles/Survey.css'

// type TemplateCompilationProps = {
//     template: Template;
// }

interface TemplateCompilationProps {
  templateDetails: TemplateDetails
}

export type AnswerType = string | string[]

export function TemplateCompilation({ templateDetails }: TemplateCompilationProps) {
  const { template, endingDate } = templateDetails
  const [answers, setAnswers] = useState<AnswerType[]>(initValues())
  const [errors, setErrors] = useState<boolean[]>(initErrors())
  const [loading, setLoading] = useState(false)
  const [snackbarOpen, setBlur] = useState<boolean>(false)
  const [blur, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')

  const params = useParams<{ id: string }>()

  const navigate = useNavigate()

  const handleCloseSnackbar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  function handleAnswerChange(value: AnswerType, index: number) {
    const newAnswers = [...answers]
    newAnswers[index] = value
    setAnswers(newAnswers)
    setCannotSubmit(false)
  }

  function handleErrorChange(error: boolean, index: number) {
    const newErrors = [...errors]
    newErrors[index] = error
    setErrors(newErrors)
  }

  function initValues() {
    const questions = template.questions
    const values = [] as AnswerType[]
    questions.forEach((question: { questionType: any }, index: number) => {
      switch (question.questionType) {
        case 'MULTIPLE_CHOICE': {
          values[index] = [] as string[]
          break
        }
        default:
          values[index] = ''
      }
    })
    return values
  }

  function initErrors() {
    const errors = [] as boolean[]
    template.questions.forEach(() => {
      errors.push(false)
    })
    return errors
  }

  const [cannotSubmit, setCannotSubmit] = useState(false)

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault()
    setLoading(true)

    if (hasMissingRequiredAnswers()) {
      setLoading(false)
      setCannotSubmit(true)
      return
    }
    const compilationAnswers = answers.map((answer, index) => {
      return {
        value: replaceEmptyStringsWithNull(answer),
        questionType: template.questions[index].questionType,
      }
    })
    try {
      if (dayjs().isAfter(dayjs(endingDate))) {
        setSnackbarMessage('Ops... il sondaggio è scaduto, verifica se sono presenti altri sondaggi per te')
        setSnackbarOpen(true)
        setBlur(true)
        setTimeout(() => navigate('/survey/available-surveys'), 3000) //4000
        return
      }
      const res = await addCompilation(params.id!, compilationAnswers)
      setSnackbarMessage(
        'Le risposte al tuo sondaggio son state caricate correttamente, verifica se sono presenti altri sondaggi per te.'
      )
      setSnackbarOpen(true)
      setBlur(true)
      setTimeout(() => navigate('/survey/available-surveys'), 3500) //5000
    } catch (e) {
      if (e instanceof ApiError) {
        publishApiError(e.details)
      } else {
        console.error(e)
      }
    }
    // finally {
    //     setLoading(false)
    // }
  }

  function replaceEmptyStringsWithNull(element: AnswerType) {
    if (typeof element === 'string') {
      return element === '' ? null : element
    }
    return element.length === 0 ? null : element
  }

  function hasMissingRequiredAnswers(): boolean {
    const newErrors = [...errors]
    template.questions.forEach((question: { isMandatory: any }, index: number) => {
      if (question.isMandatory) {
        if (answers[index].length === 0) {
          newErrors[index] = true
        }
      }
    })
    setErrors(newErrors)
    return newErrors.some((error) => error)
  }

  const handleErrorMessage = (questionType: string) => {
    switch (questionType) {
      case 'NUMERIC':
        return 'Required numeric response. Please enter a valid numerical value.'
      case 'TEXTUAL':
        return 'Empty required field: You must provide a response'
      case 'SINGLE_CHOICE':
        return 'Please choose one option before submitting.'
      case 'MULTIPLE_CHOICE':
        return 'The number of choices provided does not match the required count.'
    }
  }

  return (
    <>
      <Container maxWidth={'md'}>
        {blur && <div className="blur"></div>}
        <Stack direction={'column'} padding={1} alignItems={'center'}>
          <Container>
            <Paper elevation={3} sx={{ paddingTop: 1, marginTop: '2.5em' }}>
              <Container fixed sx={{ paddingBottom: 2 }}>
                <Typography sx={{ wordBreak: 'break-word' }} variant={'h4'} align={'center'}>
                  {template.title}
                </Typography>
                {template.questions.map(
                  (question: { questionType: any; title: string; isMandatory: boolean }, index: number) => {
                    return (
                      <Box key={index}>
                        <Answer
                          question={question}
                          value={answers[index]}
                          onChange={handleAnswerChange}
                          index={index}
                          error={errors[index]}
                          onError={handleErrorChange}
                        />
                        {errors[index] && (
                          <Alert variant={'outlined'} severity={'error'} sx={{ marginTop: 1 }}>
                            {handleErrorMessage(question.questionType)}
                          </Alert>
                        )}
                        {index !== template.questions.length - 1 && (
                          <Divider sx={{ p: 1 }} variant={'middle'} key={'divider ' + index} />
                        )}
                      </Box>
                    )
                  }
                )}
              </Container>
              <Collapse in={cannotSubmit}>
                <Alert severity={'info'} sx={{ margin: 1 }} variant={'outlined'}>
                  Please resolve all errors before submitting
                </Alert>
              </Collapse>
            </Paper>
            <LoadingButton
              variant="contained"
              size={'large'}
              onClick={handleSubmit}
              loading={loading}
              loadingPosition={'end'}
              endIcon={<SendIcon />}
              sx={{ marginTop: 1 }}
              disabled={cannotSubmit}
            >
              Submit
            </LoadingButton>
          </Container>
        </Stack>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          sx={{ zIndex: 3000 }}
        />
      </Container>
    </>
  )
}
