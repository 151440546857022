import React from 'react'
import { styled } from '@mui/material/styles'
import { Button, ButtonGroup, Grid } from '@mui/material'
import { DatePicker, PickersDay, itIT } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

dayjs.extend(utc)
dayjs.extend(timezone)

const DatePickerComponent = (props) => {
  const { date, onDateChange, onMonthChange, isDateDisabled, timezone, giorniConPrenotazioni } = props
  dayjs.tz.setDefault(timezone)

  const nextDate = (date) => dayjs(date).add(1, 'day').format('YYYY-MM-DD')
  const prevDate = (date) => dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={'it'}
      localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <Grid
        item
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        margin={{ xs: '0 auto', sm: '0 auto', md: '0 auto' }}
        maxHeight={'100px'}
        lg={6}
      >
        <ButtonGroup>
          <Button onClick={() => onDateChange(prevDate(date))}>
            <KeyboardArrowLeftIcon />
          </Button>

          <DatePicker
            value={dayjs(date)}
            editable={false}
            minDate={dayjs('2020-01-01')}
            onChange={(day) => onDateChange(day.format('YYYY-MM-DD'))}
            onMonthChange={onMonthChange}
            shouldDisableDate={(day) => isDateDisabled(day.format('YYYY-MM-DD'))}
            disableHighlightToday
            displayWeekNumber
            slots={{
              day: Day,
            }}
            slotProps={{
              actionBar: {
                actions: ['today', 'accept'],
              },
              day: {
                selectedDays: giorniConPrenotazioni,
              },
            }}
          />

          <Button onClick={() => onDateChange(nextDate(date))}>
            {' '}
            <KeyboardArrowRightIcon />{' '}
          </Button>
        </ButtonGroup>
      </Grid>
    </LocalizationProvider>
  )
}

export default DatePickerComponent

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.light}`,
  }),
  ...(isFirstDay && {}),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
  ...(!dayIsBetween && {}),
}))

function Day(props) {
  const { day, selectedDays, ...other } = props

  const dayIsBetween = selectedDays.includes(day.format('YYYY-MM-DD'))

  return <CustomPickersDay {...other} day={day} dayIsBetween={dayIsBetween} disableRipple focusRipple={false} />
}
