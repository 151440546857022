// import {HistogramGraph} from "../../../model/Graph.ts";
// import {BarChart} from "@mui/x-charts/BarChart";
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { List, ListItem, Typography, useMediaQuery } from '@mui/material'
import { HistogramGraph } from '../../../../model/Graph'
import { BarChart } from '@mui/x-charts'

type GraphData = {
  x: string[]
  y: number[]
}

export function Histogram(graph: HistogramGraph) {
  const [data, setData] = useState<GraphData | undefined>(undefined)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const newData: GraphData = {
      x: [],
      y: [],
    }
    Object.keys(graph.values).forEach((key) => {
      newData.x.push(key)
      newData.y.push(graph.values[key])
    })
    setData(newData)
  }, [graph.values, setData])

  function elaborateData(data: string[]): string[] | number[] {
    if (isMobile) {
      return data.map((_value: string, index) => {
        return index + 1
      })
    }
    return data
  }

  return (
    <>
      {data && (
        <>
          <BarChart
            xAxis={[
              {
                data: elaborateData(data.x),
                scaleType: 'band',
              },
            ]}
            series={[
              {
                data: data.y,
              },
            ]}
            height={300}
            layout={'vertical'}
          />
          {isMobile && (
            <List>
              {data.x.map((value: string, index: number) => {
                return (
                  <ListItem key={index} sx={{ padding: 0 }}>
                    <Typography gutterBottom={index === data.x.length - 1}>
                      {index + 1} {value}
                    </Typography>
                  </ListItem>
                )
              })}
            </List>
          )}
        </>
      )}
    </>
  )
}
