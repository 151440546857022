import React from 'react'
import cover from '../../assets/noDataFound1.png'
import { Box } from '@mui/system'

const NoRowsOverlay = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={{
          height: '50%',
          width: '50%',
          backgroundImage: `url(${cover})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      ></Box>
    </div>
  )
}

export default NoRowsOverlay
