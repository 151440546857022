export function checkTitleError(title: string, defaultInput: boolean) {
  return (title.length === 0 || title.startsWith(' ')) && !defaultInput
}

export function checkMinValueError(
  minValue: number,
  maxValue: number,
  currentMinValue: number | undefined,
  currentMaxValue: number | undefined
) {
  if (currentMinValue === undefined) return false
  if (currentMaxValue !== undefined && currentMinValue > currentMaxValue) return true
  return currentMinValue < minValue || currentMinValue > maxValue
}

export function checkMaxValueError(
  maxValue: number,
  minValue: number,
  currentMaxValue: number | undefined,
  currentMinValue: number | undefined
) {
  if (currentMaxValue === undefined) return false
  if (currentMinValue !== undefined && currentMinValue > currentMaxValue) return true
  return currentMaxValue > maxValue || currentMaxValue < minValue
}
