// import {ApiErrorDetails} from "../services/EventService.ts";

import { ApiErrorDetails } from '../services/eventSercices'

export class ApiError extends Error {
  readonly details: ApiErrorDetails

  constructor(details: ApiErrorDetails) {
    super()
    this.details = details
  }
}
