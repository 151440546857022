import { useEffect, useState } from 'react'
import '../../styles/calendar.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/it'
import { Box, ButtonGroup, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { getWindowAbsences } from '../../services/absenceServices'
import { DataGrid, GridToolbar, itIT } from '@mui/x-data-grid'
import { formatTableEndDateOrDurationLong, formatTableStartDate } from '../../utils'
import WeekPicker from '../../components/WeekPicker'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { ScrollRestoration, useNavigate } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import Swal from 'sweetalert2'

dayjs.extend(isBetween)

export default function CalendarComponent() {
  const navigate = useNavigate()

  const [absenceListData, setAbsenceListData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterType, setFilterType] = useState('tutti')
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [totalRows, setTotalRows] = useState(0)

  const [dateRange, setDateRange] = useState({ startOfWeek: null, endOfWeek: null })

  useEffect(() => {
    if (isMobileOnly) {
      Swal.fire({
        html: 'Sorry... questa sezione al momento può essere utilizzata solamente in modalità desktop.',
        confirmButtonText: 'Ok!',
        confirmButtonColor: '#289b38',
        toast: true,
        customClass: {
          container: 'my-sweet-alert-absence',
          confirmButton: 'swal-button',
        },
      }).then(() => {
        navigate('/')
      })
    }
  }, [navigate])

  const handleRefreshRows = (date, startDate, endDate) => {
    setLoading(true)
    const queryParams = {
      date: date ? date.toISOString().split('T')[0] : null,
      startDate: startDate ? startDate.toISOString().split('T')[0] : null,
      endDate: endDate ? endDate.toISOString().split('T')[0] : null,
      page,
      size: rowsPerPage,
    }

    getWindowAbsences(queryParams)
      .then((data) => {
        setAbsenceListData(data.content)
        setTotalRows(data.totalElements)
        setFilteredData(data.content)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Failed to fetch absences:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    handleRefreshRows(new Date())
  }, [page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleWeekChange = (weekRange) => {
    const [startOfWeek, endOfWeek] = weekRange
    setDateRange({ startOfWeek, endOfWeek })
    handleRefreshRows(null, startOfWeek, endOfWeek)
  }

  useEffect(() => {
    const filteredData = absenceListData.filter((row) => {
      if (row.status === 'rejected') return false
      if (filterType !== 'tutti' && row.type !== filterType) return false
      if (dateRange.startOfWeek && dateRange.endOfWeek) {
        const rowDate = dayjs(row.startDate)
        return (
          rowDate.isAfter(dateRange.startOfWeek.startOf('day')) && rowDate.isBefore(dateRange.endOfWeek.endOf('day'))
        )
      }
      return true
    })

    setFilteredData(filteredData)
  }, [filterType, dateRange, absenceListData])

  const allColumns = [
    // const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'owner',
      headerName: 'Dipendente',
      flex: 1,
      maxWidth: 230,
    },
    {
      field: 'type',
      headerName: 'Tipologia',
      flex: 1,
      maxWidth: 100,
    },
    {
      field: 'startDate',
      headerName: 'Inizio',
      flex: 1,
      maxWidth: 150,
      valueGetter: formatTableStartDate,
    },
    {
      field: 'endDate',
      headerName: 'Fine o Fascia oraria',
      flex: 1,
      maxWidth: 250,
      valueGetter: formatTableEndDateOrDurationLong,
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 1,
    },
  ]

  const columns = allColumns.filter((column) => column.field !== 'id')

  const getButtonStyle = (buttonType) => {
    const baseStyles = {
      tutti: { backgroundColor: 'white', color: '#4CAF50' },
      ferie: { backgroundColor: '#4CAF50', color: 'white' },
      permesso: { backgroundColor: '#2196F3', color: 'white' },
    }

    const selectedStyles = {
      tutti: { backgroundColor: '#eeeeee' },
      ferie: { backgroundColor: '#388E3C' },
      permesso: { backgroundColor: '#1976D2' },
    }

    return filterType === buttonType
      ? {
          ...baseStyles[buttonType],
          ...selectedStyles[buttonType],
          fontSize: '15px',
          fontStyle: 'italic',
        }
      : baseStyles[buttonType]
  }

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: '2.5em',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '',
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <WeekPicker onChange={handleWeekChange} sx={{ mr: 4 }} />

        <div style={{ minHeight: '100%', width: isMobileOnly ? '80%' : '70%' }}>
          <Typography component="div" sx={{ mb: 2 }}>
            Legenda :
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                width: 20,
                height: 15,
                bgcolor: '#289b38',
                ml: 1,
                mr: 0.5,
              }}
            />
            Ferie
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                width: 20,
                height: 15,
                bgcolor: '#2c77bd',
                ml: 1,
                mr: 0.5,
              }}
            />
            Permessi
          </Typography>
          {/* <DataRangeFilter onFilterChange={handleFilterChange} /> */}
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button style={getButtonStyle('tutti')} onClick={() => setFilterType('tutti')}>
              Tutti
            </Button>
            <Button style={getButtonStyle('ferie')} onClick={() => setFilterType('ferie')}>
              Ferie
            </Button>
            <Button style={getButtonStyle('permesso')} onClick={() => setFilterType('permesso')}>
              Permessi
            </Button>
          </ButtonGroup>
          <div style={{ height: '70vh' }}>
            <DataGrid
              columns={columns}
              loading={loading}
              rows={filteredData}
              disableSelectionOnClick
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params) => `absence-row-${params.row.type}`}
              pagination
              pageSize={rowsPerPage}
              rowCount={totalRows}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              paginationMode="server"
              // onRowClick={(params) => {
              //   console.log("id riga:", params.row.id);
              // }}
            />
          </div>
        </div>
      </Grid>
      <ScrollRestoration />
    </Grid>
  )
}
