import React from 'react'
import { Alert, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ChoiceSelection, SingleChoiceQuestion } from '../../../../model/Question'
import { checkTitleError } from './utils/FieldsInputCheck'
import { TitleField } from './fields/TitleField'
import ChoiceFields from './fields/ChoiceFields'
import MandatoryCheckBox from './fields/MandatoryCheckBox'
// import {TitleField} from "./fields/TitleField.tsx";
// import MandatoryCheckBox from "./fields/MandatoryCheckBox.tsx";
// import {
//     ChoiceSelection,
//     SingleChoiceQuestion,
// } from "../../../model/Question.ts";
// import {checkTitleError} from "./utils/FieldsInputCheck.ts";
// import ChoiceFields from "./fields/ChoiceFields.tsx";

type SingleChoiceQuestionFormProps = {
  questionToUpdate?: SingleChoiceQuestion
  newQuestionCallBack: (question: SingleChoiceQuestion, errors: boolean) => void
  submit: boolean
  onSubmitErrors: boolean
  setSubmitErrors: (value: boolean) => void
}

export function SingleChoiceQuestionForm({
  questionToUpdate,
  newQuestionCallBack,
  submit,
  setSubmitErrors,
  onSubmitErrors,
}: SingleChoiceQuestionFormProps) {
  const [title, setTitle] = useState<string>(questionToUpdate?.title ?? '')
  const [isMandatory, setIsMandatory] = useState<boolean>(questionToUpdate?.isMandatory ?? false)
  const [questionChoicesSelection, setQuestionChoicesSelection] = useState<ChoiceSelection>(
    questionToUpdate?.choiceSelection ?? {
      type: 'SIMPLE',
      choices: ['', ''],
    }
  )
  const [defaultInput, setDefaultInput] = useState<boolean>(true)
  const [titleError, setTitleError] = useState<boolean>(false)
  const [choicesError, setChoicesError] = useState<boolean>(false)

  useEffect(() => {
    setTitleError(checkTitleError(title, defaultInput))
    setDefaultInput(false)
  }, [defaultInput, title])

  useEffect(() => {
    const newQuestion: SingleChoiceQuestion = {
      title: title,
      isMandatory: isMandatory,
      questionType: 'SINGLE_CHOICE',
      choiceSelection: questionChoicesSelection,
    }
    newQuestionCallBack(newQuestion, titleError || choicesError)
  }, [choicesError, isMandatory, newQuestionCallBack, questionChoicesSelection, submit, title, titleError])

  const handleErrorMessages = () => {
    if (onSubmitErrors) return 'Error during submitting'
    return 'The title must be provided and should not begin with a space.'
  }

  const handleTitleChange = (event: string) => {
    setTitle(event)
    setSubmitErrors(false)
  }

  return (
    <Grid margin={'auto'} container rowSpacing={1} width={'100%'}>
      <Grid item xs={15}>
        <TitleField title={title} setTitleCallBack={handleTitleChange} titleError={titleError} />
      </Grid>

      <Grid item xs={12}>
        <ChoiceFields
          questionChoicesSelection={questionChoicesSelection}
          setChoicesError={setChoicesError}
          setQuestionChoicesSelection={setQuestionChoicesSelection}
        />
      </Grid>

      <Grid item lg={3} md={3} xs={12}>
        <MandatoryCheckBox isMandatory={isMandatory} handleOnClick={setIsMandatory}></MandatoryCheckBox>
      </Grid>
      {(titleError || onSubmitErrors) && (
        <Grid item lg={9} md={9} xs={12}>
          <Alert variant="outlined" severity="info">
            <Typography>{handleErrorMessages()}</Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}
