import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { deletePrenotazione } from '../../services/reservationServices'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Box } from '@mui/system'
import NoRowsOverlay from './NoRowOverlay'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAuth } from '../authentication/AuthProvider'

dayjs.extend(utc)
dayjs.extend(timezone)

const formatTime = (value) => {
  return dayjs(value).format('HH:mm')
}

const DataGridReservation = (props) => {
  const { username, isAdmin } = useAuth()
  const [rows, setRows] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    const giornoSelezionato = dayjs(props.giorno)
    const filteredRows = props.data.prenotazioni
      .filter((prenotazione) => {
        const inizioPrenotazione = dayjs(prenotazione.inizio)
        return giornoSelezionato.isSame(inizioPrenotazione, 'day')
      })
      .map((prenotazione, index) => ({
        id: prenotazione.id || index,
        ...prenotazione,
        orarioInizio: dayjs(prenotazione.inizio).format('HH:mm'),
        orarioFine: dayjs(prenotazione.fine).format('HH:mm'),
      }))

    setRows(filteredRows)
  }, [props.data.prenotazioni, props.giorno])

  const handleDeletePrenotazione = (prenotazioneId) => {
    setIsDeleting(true)
    deletePrenotazione(prenotazioneId)
      .then(() => {
        const updatedRows = rows.filter((row) => row.id !== prenotazioneId)
        setRows(updatedRows)
        props.onDeleteSuccess(prenotazioneId)
      })
      .catch((error) => {
        console.error('Errore cancellazione?', error)
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }

  useEffect(() => {
    props.setLoadingDataDataGrid(isDeleting)
  }, [isDeleting, props])

  const columns = [
    {
      field: 'postazioneId',
      flex: 0.5,
      headerName: 'Post.',
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Nome',
    },
    {
      field: 'inizio',
      flex: 0.5,
      headerName: 'Inizio',
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      field: 'fine',
      flex: 0.5,
      headerName: 'Fine',
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      field: 'actions',
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      headerName: '',
      renderCell: (params) => {
        if (params.row.username !== username && !isAdmin) {
          return null
        } else if (isAdmin || params.row.username === username) {
          return (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => handleDeletePrenotazione(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      },
    },
  ]

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '500px',
      }}
    >
      <DataGrid rows={rows} columns={columns} slots={{ noRowsOverlay: NoRowsOverlay }} />
    </Box>
  )
}

export default DataGridReservation
