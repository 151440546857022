import React, { useCallback } from 'react'
import { SyntheticEvent, useEffect, useState } from 'react'
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import dayjs, { Dayjs } from 'dayjs'
import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { DefaultizedPieValueType } from '@mui/x-charts'
import { useTheme } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import { Save } from '@mui/icons-material'
import { ApiError } from '../../../errors/ApiError'
import { CompilationContainer } from '../../../model/CompilationContainer'
import { publishApiError } from '../../../services/eventSercices'
import { setAllowedUsers, updateCompilation } from '../../../services/surveyCompilationServices'
import { DateTimeRangePicker } from '../DateTimeRangePicker'
import { PieData } from '../compilations/graphs/Pie'
import { getUsers } from '../../../services/absenceServices'
import { Box } from '@mui/system'
import cover from '../../../assets/allowed1.png'

interface User {
  displayName: string
  mail: string
}

type SubmittedTemplateOverviewProps = {
  submittedTemplate: CompilationContainer
}

export function SubmittedTemplateOverview({ submittedTemplate }: SubmittedTemplateOverviewProps) {
  const [openAllowedUsers, setOpenAllowedUsers] = useState<boolean>(false)

  const [userOptions, setUserOptions] = useState<string[]>([])
  const [usersLoaded, setUsersLoaded] = useState<boolean>(false)
  const loading = openAllowedUsers && userOptions.length === 0
  const [selectedUsers, setSelectedUsers] = useState<string[]>(submittedTemplate.allowedUsers)
  const [userEmailMap, setUserEmailMap] = useState<{ [displayName: string]: string }>({})
  const [emailUserMap, setEmailUserMap] = useState<{ [email: string]: string }>({})
  const [selectedDisplayNames, setSelectedDisplayNames] = useState<string[]>([])
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [graphData, setGraphData] = useState<PieData[]>([])

  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const getPieData: () => PieData[] = useCallback(() => {
    const allowedUsersData: PieData = {
      id: 0,
      label: 'Allowed users',
      value: selectedUsers.length,
    }
    const usersWhoCompiledData: PieData = {
      id: 1,
      label: 'Users who compiled',
      value: submittedTemplate.numberOfCompilations,
    }
    if (selectedUsers.length === 0) {
      return [usersWhoCompiledData]
    }
    return [usersWhoCompiledData, allowedUsersData]
  }, [selectedUsers, submittedTemplate.numberOfCompilations])

  useEffect(() => {
    if (selectedUsers.length === 0) {
      return
    }
    setGraphData(getPieData())
  }, [getPieData])

  useEffect(() => {
    if (!usersLoaded) {
      getUsers()
        .then((users: User[]) => {
          const displayNameCounts = users.reduce<{ [key: string]: number }>((acc, user) => {
            acc[user.displayName] = (acc[user.displayName] || 0) + 1
            return acc
          }, {})

          const localUserEmailMap: { [displayName: string]: string } = {}
          const localEmailUserMap: { [email: string]: string } = {}

          users.forEach((user) => {
            let displayNameKey = user.displayName
            if (displayNameCounts[user.displayName] > 1) {
              displayNameKey += ` (${user.mail})`
            }
            localUserEmailMap[displayNameKey] = user.mail
            localEmailUserMap[user.mail] = displayNameKey
          })

          setUserEmailMap(localUserEmailMap)
          setEmailUserMap(localEmailUserMap)

          const displayNames = Object.keys(localUserEmailMap)
          setUserOptions(displayNames)
          setUsersLoaded(true)
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
  }, []) //solo al montaggio

  useEffect(() => {
    if (usersLoaded) {
      const displayNames = submittedTemplate.allowedUsers.map((user) => {
        if (emailUserMap[user]) {
          return emailUserMap[user]
        }
        const emailKey = Object.keys(emailUserMap).find((email) => email.startsWith(`${user}@`))
        if (emailKey) {
          return emailUserMap[emailKey]
        }
        return user
      })

      setSelectedDisplayNames(displayNames)
    }
  }, [usersLoaded, emailUserMap, submittedTemplate.allowedUsers])

  async function removeOption(displayNameToRemove: string) {
    // console.log("Inizio rimozione:", displayNameToRemove);

    // trovo l'email corrispondente al nome visualizzato da rimuovere
    let emailToRemove: string | undefined = undefined
    for (const [email, displayName] of Object.entries(emailUserMap)) {
      if (displayName === displayNameToRemove) {
        emailToRemove = email
        break // stop ciclo una volta trovata l'email corrispondente
      }
    }

    // verifico se l'email è stata trovata
    if (!emailToRemove) {
      console.error('Email non trovata per:', displayNameToRemove)
      return
    }
    try {
      // aggiorno lista degli utenti selezionati rimuovendo l'email
      const newSelectedUsers = selectedUsers.filter((email) => email !== emailToRemove)
      await setAllowedUsers(submittedTemplate.id, newSelectedUsers)

      // nuova lista di utenti
      setSelectedUsers(newSelectedUsers)

      // aggiorno lista dei nomi visualizzati rimuovendo il nome corrispondente
      const newSelectedDisplayNames = selectedDisplayNames.filter((displayName) => displayName !== displayNameToRemove)
      setSelectedDisplayNames(newSelectedDisplayNames)

      // aggiorno oggetto template con i nuovi utenti permessi
      submittedTemplate.allowedUsers = newSelectedUsers

      // console.log("Rimozione completata con successo per:", displayNameToRemove);
    } catch (error) {
      if (error instanceof ApiError) {
        console.error('Errore API:', error.details)
        publishApiError(error.details)
      } else if (error instanceof Error) {
        console.error('Errore:', error.message)
      } else {
        console.error('Si è verificato un errore sconosciuto durante la rimozione di', displayNameToRemove)
      }
    }
  }

  async function handleCheckedChange(label: string, value: boolean) {
    const email = userEmailMap[label]
    const updatedSelectedUsersEmails = value
      ? [...selectedUsers, email] // selectedUsers deve contenere email invece di displayName
      : selectedUsers.filter((userEmail) => userEmail !== email)

    try {
      await setAllowedUsers(submittedTemplate.id, updatedSelectedUsersEmails)
      setSelectedUsers(updatedSelectedUsersEmails)
      submittedTemplate.allowedUsers = updatedSelectedUsersEmails
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        publishApiError(error.details)
      } else {
        console.error(error)
      }
    }
  }

  async function handleChange(event: SyntheticEvent, newValue: string[]) {
    // mappo i nuovi valori (display names) ai corrispondenti indirizzi email
    const newValuesEmails = newValue.map((label) => userEmailMap[label])

    try {
      await setAllowedUsers(submittedTemplate.id, newValuesEmails)

      setSelectedUsers(newValuesEmails)
      submittedTemplate.allowedUsers = newValuesEmails
    } catch (error: any) {
      if (error instanceof ApiError) {
      } else {
        setSnackbarMessage(error.message)
        setSnackbarOpen(true)
        console.error('sono io handlechange', error.message)
      }
    }
  }

  function getDifferences(shorterArray: string[], longerArray: string[]): string[] {
    return longerArray.filter((element) => !shorterArray.includes(element))
  }

  function arcLabel(item: DefaultizedPieValueType): string {
    let value = item.value.toString()
    if (item.value.toString().length > 5) {
      value = value.substring(0, 5)
    }
    return `${value}`
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getDateFormat(): string {
    if (isExtraSmall) {
      return 'D/M/YY HH:mm'
    }
    return 'D MMMM YYYY HH:mm'
  }

  function isEnded(): boolean {
    return dayjs().isAfter(submittedTemplate.endingDate)
  }

  function isStarted(): boolean {
    return dayjs().isAfter(submittedTemplate.beginningDate)
  }

  const [beginDate, setBeginDate] = useState<Dayjs>(dayjs(submittedTemplate.beginningDate))
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(submittedTemplate.endingDate))

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true)

  useEffect(() => {
    setIsSaveButtonDisabled(
      beginDate.isSame(submittedTemplate.beginningDate) && endDate.isSame(submittedTemplate.endingDate)
    )
  }, [beginDate, endDate, submittedTemplate])

  const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)

  async function handleSave() {
    setLoadingSaveButton(true)
    const containerModification = {
      beginningDate: beginDate.toISOString(),
      endingDate: endDate.toISOString(),
    }

    try {
      await updateCompilation(submittedTemplate.id, containerModification)
      // Gestisci la risposta di successo qui, ad esempio mostrando un messaggio di successo
    } catch (error) {
      // Gestisci l'errore qui, ad esempio mostrando un messaggio di errore
      console.error(error)
    } finally {
      setLoadingSaveButton(false)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <Grid container rowSpacing={2} sx={{ px: { xs: 0, sm: 6, md: 10, lg: 10 } }}>
      <Grid item xs={12}>
        <Box
          sx={{
            height: '35vh',
            // width: '100%',
            display: 'flex',
            // flexDirection: 'column',
            backgroundImage: `url(${cover})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            mixBlendMode: 'darken',
          }}
        ></Box>
      </Grid>

      <Grid item display={'flex'} flexDirection={'column'} gap={3} sx={{ width: '80%', m: ' 0 auto' }}>
        <Grid item xs={12}>
          <Autocomplete
            open={openAllowedUsers}
            onOpen={() => setOpenAllowedUsers(true)}
            onClose={() => setOpenAllowedUsers(false)}
            options={userOptions || 'Nessun utente selezionabile'}
            getOptionLabel={(option) => option}
            value={selectedDisplayNames}
            isOptionEqualToValue={(option, value) => {
              // converto il valore (email) nel corrispondente nome visualizzato
              const optionEmail = userEmailMap[option]
              const valueEmail = userEmailMap[value] || value // caso in cui value è un nome visualizzato
              return optionEmail === valueEmail || option === value
            }}
            multiple
            onChange={handleChange}
            renderTags={(values) =>
              values.map((value, index) => <Chip key={index} label={value} onDelete={() => removeOption(value)} />)
            }
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    onChange={async (event) => {
                      await handleCheckedChange(option, event.target.checked)
                    }}
                    title={option}
                  />
                  {option}
                </li>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Allowed users"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            disableCloseOnSelect
            disabled={dayjs().isAfter(submittedTemplate.endingDate)}
            limitTags={5}
            autoHighlight
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction={'row'} spacing={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DateTimeRangePicker
              firstDate={beginDate}
              firstDateReadonly={isStarted()}
              firstDateLabel={'Begin date'}
              secondDate={endDate}
              secondDateReadonly={isEnded()}
              secondDateLabel={'End date'}
              minFirstDate={dayjs().add(1, 'millisecond')}
              onFirstDateChange={setBeginDate}
              onSecondDateChange={setEndDate}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            variant={'contained'}
            fullWidth
            endIcon={<Save style={{ fill: '#fafafa' }} />}
            loadingPosition={'end'}
            disabled={isSaveButtonDisabled || isEnded()}
            onClick={handleSave}
            loading={loadingSaveButton}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {submittedTemplate.numberOfCompilations === 0 && (
          <Container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ marginRight: 1 }}>
              {!isStarted() ? 'Not yet started' : `No compilations ${isEnded() ? '' : 'yet'}`}
            </Typography>
            <SentimentVeryDissatisfiedIcon />
          </Container>
        )}
        {submittedTemplate.numberOfCompilations !== 0 && (
          <PieChart
            series={[
              {
                data: graphData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                arcLabel: arcLabel,
                arcLabelMinAngle: 20,
              },
            ]}
            height={250}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontWeight: 'bold',
              },
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}
