import React, { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box } from '@mui/material'

function ButtonSubmit(props) {
  const {
    variant,
    fetch,
    icon,
    iconSuccess,
    iconError,
    text,
    textSuccess,
    textError,
    onSuccess,
    onError,
    onClickAfterSuccess,
    onClickAfterError,
    disabled = false,
    retryAfterError = false,
  } = props

  const [status, setStatus] = useState('')

  const buttonText = {
    success: textSuccess === undefined ? text : textSuccess,
    error: textError === undefined ? text : textError,
    pending: text,
    '': text,
  }

  const handleSubmit = () => {
    setStatus('pending')
    setTimeout(() => {
      const result = fetch()
      result
        .then((resp) => {
          setStatus('success')
          onSuccess(resp.data)
        })
        .catch((err) => {
          setStatus('error')
          onError(err)
        })
    }, 250)
  }

  if (variant === 'button') {
    const buttonProps = {
      success: {
        onClick: onClickAfterSuccess,
        loading: false,
        startIcon: iconSuccess,
        loadingPosition: 'start',
        color: 'success',
      },
      error: {
        onClick: retryAfterError ? handleSubmit : onClickAfterError,
        loading: false,
        startIcon: iconError,
        loadingPosition: 'start',
        color: 'error',
      },
      pending: {
        loading: true,
        startIcon: icon,
        loadingPosition: 'start',
      },
      '': {
        onClick: handleSubmit,
        loading: false,
        startIcon: icon,
        loadingPosition: 'start',
        color: 'primary',
      },
    }

    return (
      <LoadingButton size="small" disabled={disabled} variant="contained" {...buttonProps[status]}>
        <span>{buttonText[status]}</span>
      </LoadingButton>
    )
  } else {
    const buttonProps = {
      success: {
        onClick: onClickAfterSuccess,
        color: 'success',
      },
      error: {
        onClick: retryAfterError ? handleSubmit : onClickAfterError,
        color: 'error',
      },
      pending: {
        disabled: true,
      },
      '': {
        onClick: handleSubmit,
        color: 'primary',
      },
    }

    const iconPending = <></>

    const buttonContent = {
      error: iconError,
      success: iconSuccess,
      loading: iconPending,
      '': icon,
    }

    return (
      // <IconButton disabled={disabled} {...buttonProps[status]} color={buttonProps[status].color}>
      <Box>
        <LoadingButton
          loading={status === 'pending'}
          variant="outlined"
          disabled={disabled}
          {...buttonProps[status]}
          sx={{ width: '25px', height: '30px', borderColor: 'transparent' }}
          color={buttonProps[status].color}
        >
          {buttonContent[status]}
        </LoadingButton>
      </Box>
      // </IconButton>
    )
  }
}

export default ButtonSubmit
