import React from 'react'
import { useMsal } from '@azure/msal-react'
import Button from '@mui/material/Button'
import LoginIcon from '@mui/icons-material/Login'
import { loginRequest } from '../../authConfig'

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export default function SignInButton() {
  const { instance } = useMsal()

  const handleLogin = () => {
    //instance.loginPopup(loginRequest).catch((error) => console.log(error))
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }
  return (
    <div>
      <Button variant="contained" endIcon={<LoginIcon />} onClick={() => handleLogin()} size="large">
        Login
      </Button>
    </div>
  )
}
