import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Avatar, Box, Container, Divider, Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material'
import { getCompilationHistory } from '../../../services/surveyCompilationServices'
import { getUsers } from '../../../services/absenceServices'
import { useTheme } from '@mui/material/styles'

type HistoryViewProps = {
  submittedTemplateId: string
}

type CompilationHistory = {
  userId: string
  date: Dayjs
}

interface User {
  displayName: string
  mail: string
}

// array dove ogni elemento ha una chiave = userId e un valore che è la data
type CompilationHistoryResponse = Record<string, string>

export function HistoryView(props: HistoryViewProps) {
  const theme = useTheme()
  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('sm'))

  const [histories, setHistories] = useState<CompilationHistory[] | undefined>(undefined)

  useEffect(() => {
    const fetchUsersAndHistory = async () => {
      try {
        const users: User[] = await getUsers()

        // costruisco la mappa userId > displayName
        let userIdToDisplayNameMap: { [userId: string]: string } = {}
        users.forEach((user) => {
          const userId: string = user.mail.split('@')[0]
          userIdToDisplayNameMap[userId] = user.displayName
        })

        if (!props.submittedTemplateId) {
          return
        }

        const mapRes: Map<string, Dayjs> = await getCompilationHistory(props.submittedTemplateId)
        const res: CompilationHistoryResponse = {} // tipo atteso
        mapRes.forEach((value, key) => {
          res[key] = value.format() // converto Dayjs in stringa
        })

        const unorderedHistories: CompilationHistory[] = Object.entries(res).map(([userId, dateString]) => {
          const displayName: string = userIdToDisplayNameMap[userId] || userId
          return {
            userId: displayName,
            date: dayjs(dateString),
          }
        })

        const orderedHistories: CompilationHistory[] = unorderedHistories.sort((a, b) =>
          a.date.isBefore(b.date) ? -1 : 1
        )
        setHistories(orderedHistories)
      } catch (error) {
        console.error('Errore:', error)
      }
    }

    fetchUsersAndHistory()
  }, [props.submittedTemplateId])

  function getColor(index: number): string {
    switch (index) {
      case 0:
        return 'gold'
      case 1:
        return '#646464'
      case 2:
        return '#cd7f32'
      default:
        return 'inherit'
    }
  }

  return (
    <Paper
      sx={{
        maxHeight: '70dvh',
        backgroundColor: 'inherit',
        px: { xs: 0, sm: 6, md: 20, lg: 30 },
      }}
      elevation={0}
    >
      <Stack divider={<Divider orientation="horizontal" variant="middle" />} spacing={2}>
        {histories &&
          histories.map((history, index) => (
            <Container key={index}>
              <Grid container sx={{ display: 'flex', alignItems: 'center' }} spacing={1}>
                <Grid item xs={2} sx={{ padding: 1 }}>
                  <Avatar
                    sx={{
                      boxShadow: 3,
                      width: 35,
                      height: 35,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: getColor(index) }}>
                      {index + 1}
                    </Typography>
                  </Avatar>
                </Grid>
                <Grid item xs={10} sm={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: isTabletOrLarger ? 'row' : 'column',
                      alignItems: isTabletOrLarger ? 'center' : 'flex-start',
                      gap: 1,
                    }}
                  >
                    <Typography variant={isTabletOrLarger ? 'h6' : 'body1'} sx={{ flex: 1 }}>
                      {history.userId}
                    </Typography>
                    <Typography
                      variant={isTabletOrLarger ? 'h6' : 'body2'}
                      sx={{
                        flex: 1,
                        textAlign: isTabletOrLarger ? 'left' : 'center',
                        marginTop: isTabletOrLarger ? 0 : 1,
                      }}
                    >
                      {history.date.format('DD/MM/YY HH:mm:ss')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          ))}
      </Stack>
    </Paper>
  )
}
