import React from 'react'
import { FormControlLabel, Switch } from '@mui/material'

type MandatoryCheckBoxProps = {
  isMandatory: boolean
  handleOnClick: (value: boolean) => void
}

export default function MandatoryCheckBox({ isMandatory, handleOnClick }: MandatoryCheckBoxProps) {
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleOnClick(event.target.checked)
  }

  return <FormControlLabel control={<Switch onChange={handleCheckBox} checked={isMandatory} />} label="Mandatory" />
}
