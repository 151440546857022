import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { loginRequest } from './authConfig'
import { styled, Box } from '@mui/system'
import portale_ti from './assets/portale_ti.png'
import { isMobile } from 'react-device-detect'
import { Typography } from '@mui/material'

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.5))',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
}))

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  margin: '0 auto',
  marginTop: '10vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

const MsalAuthLoader = ({ children }) => {
  const { instance, accounts } = useMsal()
  const [isMsalReady, setIsMsalReady] = useState(false)
  const [redirectPath, setRedirectPath] = useState(null)

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        const redirectResponse = await instance.handleRedirectPromise()
        if (redirectResponse) {
          const path = sessionStorage.getItem('redirectPath')
          if (path) {
            setRedirectPath(path)
            sessionStorage.removeItem('redirectPath')
          }
        }

        const activeAccount = instance.getActiveAccount()
        if (!activeAccount && accounts.length > 0) {
          instance.setActiveAccount(accounts[0])
        } else if (!activeAccount) {
          const currentPath = window.location.pathname + window.location.search
          sessionStorage.setItem('redirectPath', currentPath)
          await instance.loginRedirect(loginRequest)
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          const currentPath = window.location.pathname + window.location.search
          sessionStorage.setItem('redirectPath', currentPath)
          await instance.loginRedirect(loginRequest)
        } else {
          console.error('MSAL initialization error:', error)
        }
      }
      setIsMsalReady(true)
    }

    initializeMsal()
  }, [instance, accounts])

  useEffect(() => {
    if (isMsalReady && redirectPath) {
      window.location.href = redirectPath
    }
  }, [isMsalReady, redirectPath])

  if (!isMsalReady) {
    return (
      <Container sx={{ width: isMobile ? 300 : 550 }}>
        <StyledImage src={portale_ti} alt="Sample Image" />
        <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '2vh' }}>
          Waiting please . . .
        </Typography>
      </Container>
    )
  }

  return children
}

export default MsalAuthLoader
