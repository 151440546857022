import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded'
import CheckIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded'

import Button from '@mui/material/Button'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useAuth } from '../../components/authentication/AuthProvider'
import {
  deletePrenotazione,
  deletePrenotazioni,
  getPostazioni,
  getPrenotazioni,
  getPrenotazioniUtente,
} from '../../services/reservationServices'
import { ButtonGroup, Checkbox, Divider, Grid, Slide, TablePagination } from '@mui/material'
import { styled } from '@mui/material/styles'
import ButtonSubmit from '../../components/reservationComponents/ButtonSubmit'
import Swal from 'sweetalert2'
import { getUsers } from '../../services/absenceServices'
import SelectBar from '../../utilsComponents/SelectComponent.tsx'
import cover from '../../assets/noDataFound.png'
import { ScrollRestoration } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { isMobileOnly, useMobileOrientation } from 'react-device-detect'
import useOrientationEffects from '../../hook/useOrentiationEffetc'

dayjs.locale('it')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Rome')

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    strokeWidth: '0.1px',
  },
}))

const transformData = (prenotazioni) => {
  const groupedByDate = {}
  prenotazioni.forEach((pren) => {
    const dateString = dayjs(pren.inizio).format('DD/MM/YYYY')
    if (!groupedByDate[dateString]) {
      groupedByDate[dateString] = []
    }
    groupedByDate[dateString].push({
      id: pren.id,
      name: pren.name,
      postazioneId: pren.postazioneId,
      inizio: pren.inizio,
      fine: pren.fine,
    })
  })
  return Object.entries(groupedByDate).map(([date, entries]) => ({
    date,
    prenotazioni: entries,
  }))
}

function DayRow({ dayData, onRefresh, toggleDay, togglePrenotazione, selectedPrenotazioni, isExpanded, isLast }) {
  const [open, setOpen] = React.useState(isExpanded)
  const [removedRows, setRemovedRows] = React.useState(new Set())

  React.useEffect(() => {
    setOpen(isExpanded)
  }, [isExpanded])

  const handleDelete = async (prenotazioneId) => {
    setRemovedRows(new Set([...removedRows, prenotazioneId]))
    await deletePrenotazione(prenotazioneId)
    onRefresh()
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell padding="checkbox">
          <CustomCheckbox
            checked={dayData.prenotazioni.every((pren) => selectedPrenotazioni.has(pren.id))}
            onChange={() => toggleDay(dayData.prenotazioni)}
          />
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {dayData.date}
        </TableCell>
        <TableCell align="center">{dayData.prenotazioni.length}</TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: 'background.default' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {/* <Divider sx={{ margin: '0 auto' }} /> */}
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Nome</TableCell>
                    <TableCell>Postazione ID</TableCell>
                    <TableCell>Inizio</TableCell>
                    <TableCell>Fine</TableCell>
                    <TableCell>Azioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dayData.prenotazioni.map((pren) => (
                    <Slide key={pren.id} direction="left" in={!removedRows.has(pren.id)} mountOnEnter unmountOnExit>
                      <TableRow key={pren.id}>
                        <TableCell padding="checkbox">
                          <CustomCheckbox
                            checked={selectedPrenotazioni.has(pren.id)}
                            onChange={() => togglePrenotazione(pren.id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {pren.name}
                        </TableCell>
                        <TableCell>{pren.postazioneId}</TableCell>
                        <TableCell>{dayjs(pren.inizio).format('HH:mm')}</TableCell>
                        <TableCell>{dayjs(pren.fine).format('HH:mm')}</TableCell>
                        <TableCell>
                          <ButtonSubmit
                            variant="icon-button"
                            retryAfterError={true}
                            fetch={() => handleDelete(pren.id)}
                            icon={<DeleteIcon sx={{ color: 'red' }} />}
                            iconSuccess={<CheckIcon />}
                            iconError={<ErrorIcon />}
                            text={'Elimina'}
                            textSuccess={'Eliminata'}
                            textError={'Errore'}
                            onClickAfterSuccess={() => null}
                            onClickAfterError={() => {}}
                            onSuccess={() => {}}
                            onError={() => {}}
                            disabled={false}
                          />
                        </TableCell>
                      </TableRow>
                    </Slide>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
          {!isLast && dayData.prenotazioni.some((pren) => !removedRows.has(pren.id)) && (
            <Divider sx={{ margin: '0 auto' }} />
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
DayRow.propTypes = {
  dayData: PropTypes.shape({
    date: PropTypes.string.isRequired,
    prenotazioni: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        postazioneId: PropTypes.string.isRequired,
        inizio: PropTypes.number.isRequired,
        fine: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
}

export default function CollapsibleTable() {
  const { username, isAdmin } = useAuth()
  const theme = useTheme()
  const { isLandscape } = useMobileOrientation()

  const [expandedAll, setExpandedAll] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [totalRows, setTotalRows] = React.useState(0)

  const [selectedPrenotazioni, setSelectedPrenotazioni] = React.useState(new Set())

  const [selectedPostazione, setSelectedPostazione] = React.useState('')
  const [postazioneIdOption, setPostazioneIdOption] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState('')
  const [userOptions, setUserOptions] = React.useState([])

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await getUsers()
        const options = fetchedUsers.map((user) => ({
          value: user.mail,
          label: `${user.displayName} (${user.mail})`,
        }))
        setUserOptions(options)
      } catch (error) {
        console.error('Failed to fetch users:', error)
      }
    }

    fetchUsers()
  }, [])

  React.useEffect(() => {
    const fetchPostazioni = async () => {
      try {
        const postazioniList = await getPostazioni()
        const option = postazioniList.map((postazioneId) => postazioneId.id)
        setPostazioneIdOption(option)
      } catch (error) {
        console.error('Failed to fetch postazioni:', error)
      }
    }

    fetchPostazioni()
  }, [])

  const togglePrenotazione = (prenotazioneId) => {
    const newSelection = new Set(selectedPrenotazioni)
    if (selectedPrenotazioni.has(prenotazioneId)) {
      newSelection.delete(prenotazioneId)
    } else {
      newSelection.add(prenotazioneId)
    }
    setSelectedPrenotazioni(newSelection)
  }

  const toggleDay = (prenotazioni) => {
    const newSelection = new Set(selectedPrenotazioni)
    const allSelected = prenotazioni.every((pren) => selectedPrenotazioni.has(pren.id))
    prenotazioni.forEach((pren) => {
      if (allSelected) {
        newSelection.delete(pren.id)
      } else {
        newSelection.add(pren.id)
      }
    })
    setSelectedPrenotazioni(newSelection)
  }

  const toggleExpandCollapse = () => {
    setExpandedAll(!expandedAll)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const deleteSelectedPrenotazioni = () => {
    if (selectedPrenotazioni.size > 0) {
      // devo convertire il set in array
      const idsToDelete = Array.from(selectedPrenotazioni)
      const message = isAdmin
        ? `Stai eliminando tutte le prenotazioni. <br/> Ricordati di avvisare gli utenti. Confermi la cancellazione?`
        : `Stai eliminando tutte le prenotazioni. <br/> Confermi?`

      Swal.fire({
        title: 'Sei sicuro?',
        html: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sì, elimina!',
        cancelButtonText: 'Annulla',
      }).then((result) => {
        if (result.isConfirmed) {
          deletePrenotazioni(idsToDelete)
            .then(() => {
              Swal.fire('Cancellate!', 'Le prenotazioni sono state cancellate.', 'success')
              setSelectedPrenotazioni(new Set())
              handleRefreshRows()
            })
            .catch((err) => {
              console.error("Errore durante l'eliminazione delle prenotazioni:", err)
              Swal.fire('Errore!', 'Le prenotazioni non sono state cancellate.', 'error')
            })
        }
      })
    } else {
      Swal.fire('Attenzione!', 'Nessuna prenotazione selezionata.', 'info')
    }
  }

  const handleUserChange = (selectedValue) => {
    setSelectedUser(selectedValue)
  }
  const handlePostazioneChange = (selectedValue) => {
    setSelectedPostazione(selectedValue)
  }

  const handleRefreshRows = () => {
    const queryParams = {
      page,
      rowsPerPage,
      selectedUser,
      selectedPostazione,
    }

    const promise = isAdmin ? getPrenotazioni(queryParams) : getPrenotazioniUtente(username)
    promise
      .then((data) => {
        if (isAdmin) {
          // se isAdmin è true, data.content è un array
          const transformedPrenotazioni = transformData(data.content)
          setRows(transformedPrenotazioni)
          setTotalRows(data.totalElements)
        } else {
          // se isAdmin è false, data è direttamente un array
          if (Array.isArray(data)) {
            const transformedPrenotazioni = transformData(data)
            setRows(transformedPrenotazioni)
            setTotalRows(data.length)
          } else {
            console.error('Data format is incorrect:', data)
          }
        }
      })
      .catch((err) => {
        console.error('Errore durante il caricamento delle prenotazioni:', err)
      })
  }

  useOrientationEffects(isLandscape)

  React.useEffect(() => {
    handleRefreshRows()
  }, [page, rowsPerPage, selectedUser, selectedPostazione])

  return (
    <>
      <Grid
        item
        sx={{
          paddingTop: '1em',
          mt: '2.5em',
          mr: `calc(${theme.spacing(7)} + 1px)`,
          ml: { xs: isMobileOnly && isLandscape ? 0 : `calc(${theme.spacing(1)} + 1px)` },
        }}
      >
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          {isAdmin && (
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexDirection: 'row',
                flexWrap: 'wrap',
                padding: 2,
              }}
            >
              <SelectBar
                options={userOptions}
                handleSearchChange={handleUserChange}
                label="Search for Users"
                width="40%"
                size="small"
              />
              <SelectBar
                options={postazioneIdOption}
                handleSearchChange={handlePostazioneChange}
                label="Search for Postazione ID"
                width="40%"
                size="small"
              />
            </Grid>
          )}

          {rows.length > 0 ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 2 }}>
              <ButtonGroup size="small" aria-label="outlined primary button group">
                <Button variant={expandedAll ? 'contained' : 'outlined'} onClick={toggleExpandCollapse}>
                  {expandedAll ? 'Riduci tutto' : 'Espandi tutto'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={deleteSelectedPrenotazioni}
                  color="error"
                  disabled={selectedPrenotazioni.size < 1}
                >
                  Elimina selezionate
                </Button>
              </ButtonGroup>
            </Box>
          ) : null}

          <Divider variant="middle" sx={{ width: '40%', m: '0 auto' }} />

          {rows.length > 0 ? (
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell>Data</TableCell>
                  <TableCell width={'20%'} align="center">
                    Numero di prenotazioni
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((dayData, index) => (
                  <DayRow
                    key={dayData.date}
                    dayData={dayData}
                    onRefresh={handleRefreshRows}
                    toggleDay={() => toggleDay(dayData.prenotazioni)}
                    togglePrenotazione={togglePrenotazione}
                    selectedPrenotazioni={selectedPrenotazioni}
                    isExpanded={expandedAll}
                    isLast={index === rows.length - 1}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Grid display={'flex'} justifyContent={'center'}>
              <Box
                sx={{
                  height: '400px',
                  width: '400px',
                  display: 'flex',
                  backgroundImage: `url(${cover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  mixBlendMode: 'darken',
                }}
              ></Box>
            </Grid>
          )}
        </TableContainer>

        {rows.length > 0 ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 15, 25, 100]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={isMobileOnly ? 'Pren. per pag.' : 'Pren. per pagina'}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Grid>
      <ScrollRestoration />
    </>
  )
}
