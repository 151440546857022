import { FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
// import {SingleChoiceQuestion, WeightedChoice} from "../../../model/Question.ts";
import FormControl from '@mui/material/FormControl'
import { SingleChoiceQuestion, WeightedChoice } from '../../../../model/Question'

type SingleChoiceAnswerProps = {
  question: SingleChoiceQuestion
  value: string
  onChange: (value: string, index: number) => void
  index: number
  error: boolean
  onError: (error: boolean, index: number) => void
}

export function SingleChoiceAnswer(props: SingleChoiceAnswerProps) {
  function getControlLabelFromChoice(choice: string | WeightedChoice) {
    return typeof choice === 'string' ? choice : (choice as WeightedChoice).choice
  }

  function getValueFromChoice(choice: string | WeightedChoice) {
    return typeof choice === 'string' ? choice : (choice as WeightedChoice).choice
  }

  function getControlNodes() {
    return props.question.choiceSelection.choices.map((choice, index) => {
      return (
        <FormControlLabel
          key={index}
          control={<Radio />}
          label={getControlLabelFromChoice(choice)}
          value={getValueFromChoice(choice)}
        />
      )
    })
  }

  return (
    <FormControl error={props.error}>
      {props.question.isMandatory && <FormHelperText>Required *</FormHelperText>}
      <RadioGroup
        onChange={(event) => {
          props.onChange((event.target as HTMLInputElement).value, props.index)
          props.onError(false, props.index)
        }}
      >
        {getControlNodes()}
      </RadioGroup>
    </FormControl>
  )
}
