import * as React from 'react'
import dayjs from 'dayjs'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import { styled } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { Box, Button } from '@mui/material'
import 'dayjs/locale/it'

dayjs.extend(isBetweenPlugin)

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' &&
    prop !== 'isHovered' &&
    prop !== 'selectionType' &&
    prop !== 'isFirstDay' &&
    prop !== 'isLastDay',
})(({ theme, isSelected, isHovered, day, selectionType, isFirstDay, isLastDay }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}))

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false
  }

  return dayA.isSame(dayB, 'week')
}

const isInSameMonth = (day, selectedDate) => {
  return selectedDate && day.month() === selectedDate.month() && day.year() === selectedDate.year()
}

function Day(props) {
  const { day, selectedDay, hoveredDay, selectionType, ...other } = props

  const isSelected = selectionType === 'week' ? isInSameWeek(day, selectedDay) : isInSameMonth(day, selectedDay)

  const isFirstDay =
    selectionType === 'week'
      ? day.day() === 1 // Lunedì
      : day.date() === 1

  const isLastDay =
    selectionType === 'week'
      ? day.day() === 0 // Domenica
      : day.date() === day.daysInMonth()

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isSelected}
      isHovered={isInSameWeek(day, hoveredDay)}
      selectionType={selectionType}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  )
}

export default function WeekPicker({ onChange }) {
  const [currentMonth, setCurrentMonth] = React.useState(dayjs())
  const [selectedWeek, setSelectedWeek] = React.useState([null, null])
  const [hoveredDay, setHoveredDay] = React.useState(null)
  const [selectionType, setSelectionType] = React.useState('week') // 'week' o 'month'

  const handleWeekSelection = (day) => {
    const startOfWeek = day.startOf('week').add(1, 'day') // Inizia da Lunedì
    const endOfWeek = day.endOf('week').add(1, 'day') // Termina a Domenica
    setSelectedWeek([startOfWeek, endOfWeek])
    onChange([startOfWeek, endOfWeek])
    setSelectionType('week')
  }

  const handleCurrentMonthSelect = () => {
    const now = dayjs()
    setCurrentMonth(now)
    handleSelectMonth(now)
    setSelectionType('month')
  }

  const handleSelectMonth = (month) => {
    const targetMonth = month || currentMonth
    const startOfMonth = targetMonth.startOf('month')
    const endOfMonth = targetMonth.endOf('month')
    setSelectedWeek([startOfMonth, endOfMonth])
    onChange([startOfMonth, endOfMonth])
    setSelectionType('month')
  }

  const changeMonth = (direction) => {
    const updatedMonth = currentMonth.add(direction, 'month')
    setCurrentMonth(updatedMonth)
    handleSelectMonth(updatedMonth)
  }

  const handleReset = () => {
    setSelectedWeek([null, null])
    onChange([null, null])
    setSelectionType('week')
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          sx={{
            marginTop: '10px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
            <Button variant="outlined" sx={{ width: '170px', height: '40px' }} onClick={handleCurrentMonthSelect}>
              Mese Corrente
            </Button>
            <Button variant="outlined" sx={{ width: '170px', height: '40px' }} onClick={() => changeMonth(1)}>
              Mese Successivo
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
            <Button variant="outlined" sx={{ width: '170px', height: '40px' }} onClick={() => changeMonth(-1)}>
              Mese Precedente
            </Button>
            <Button variant="outlined" sx={{ width: '170px', height: '40px' }} onClick={handleReset} color="error">
              Reset Selezione
            </Button>
          </Box>
        </Box>
        <DateCalendar
          value={selectedWeek[0]}
          onChange={handleWeekSelection}
          currentmonth={currentMonth}
          showDaysOutsideCurrentMonth
          displayWeekNumber
          slots={{ day: Day }}
          slotProps={{
            day: (ownerState) => ({
              selectedDay: selectedWeek[0],
              hoveredDay,
              selectionType,
              onPointerEnter: () => setHoveredDay(ownerState.day),
              onPointerLeave: () => setHoveredDay(null),
            }),
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}
