import { Alert, Collapse, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ApiError } from '../../../errors/ApiError'
import { Question } from '../../../model/Question'
import { Template } from '../../../model/Template'
import { publishApiError } from '../../../services/eventSercices'
import {
  updateTemplate,
  deleteQuestionAtIndex,
  addQuestionToTemplate,
  updateQuestionAtIndex,
} from '../../../services/surveyCompilationServices'
import { QuestionList } from '../questions/QuestionList'
// import {
//     updateTemplate,
//     deleteQuestionAtIndex,
//     addQuestionToTemplate,
//     updateQuestionAtIndex
// } from "../../services/TemplateService.ts";
// import {Template} from "../../model/Template.ts";
// import {Question} from "../../model/Question.ts";
// import {QuestionList} from "../questions/QuestionList.tsx";
// import {theme} from "../../assets/theme.ts";
// import {ApiError} from "../../errors/ApiError.ts";
// import {publishApiError} from "../../services/EventService.ts";

type FullTemplateProps = {
  template: Template
  onTChange?: (templateId: string, title: string, description: string, reference: string) => void
  disableButtons: boolean
  onTemplateChange: (template: Template) => void
}

export default function FullTemplate(props: FullTemplateProps) {
  const [disableButtons] = useState(props.disableButtons ?? false)

  const [template, setTemplate] = useState<Template>(props.template)

  const [modifications, setModifications] = useState({
    title: false,
    description: false,
    referencePeriod: false,
  })

  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    referencePeriod: false,
  })
  const [erroreMessages, setErroreMessages] = useState<string>('')
  const [errorState, setErrorState] = useState<boolean>(false)

  useEffect(() => {
    setTemplate(props.template)
    setModifications({
      title: false,
      description: false,
      referencePeriod: false,
    })
    setLoading(false)
    setErrors({
      title: false,
      description: false,
      referencePeriod: false,
    })
  }, [props.template, props.template.questions])

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newErrors = { ...errors }
    newErrors.title = event.target.value.length <= 0
    setErrors(newErrors)
    const newTemplate = { ...template }
    newTemplate.title = event.target.value
    const newModifications = { ...modifications }
    newModifications.title = true
    setModifications(newModifications)
    setTemplate(newTemplate)
    setErrorState(false)
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newErrors = { ...errors }
    newErrors.description = event.target.value.length <= 0
    setErrors(newErrors)
    const newTemplate = { ...template }
    newTemplate.description = event.target.value
    modifications.description = true
    const newModifications = { ...modifications }
    newModifications.description = true
    setModifications(newModifications)
    setTemplate(newTemplate)
  }

  function handleReferencePeriodChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newErrors = { ...errors }
    newErrors.referencePeriod = event.target.value.length !== 4 || isNaN(Number(event.target.value))
    setErrors(newErrors)
    const newTemplate = { ...template }
    newTemplate.referencePeriod = event.target.value
    modifications.referencePeriod = true
    const newModifications = { ...modifications }
    newModifications.referencePeriod = true
    setModifications(newModifications)
    setTemplate(newTemplate)
  }

  function handleSave() {
    setLoading(true)
    updateTemplate(props.template.id, template.title, template.description, template.referencePeriod)
      .then((response) => {
        props.onTemplateChange?.(response)
        setModifications({
          title: false,
          description: false,
          referencePeriod: false,
        })
      })
      .catch((error) => {
        let errorMessage = 'Errore sconosciuto.'
        if (error instanceof Error) {
          errorMessage = error.message
        }
        if (error.response && error.response.data) {
          errorMessage = error.response.data
        }
        setErrorState(true)
        setErroreMessages(errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  async function deleteQuestion(questionIndex: number) {
    const response = await deleteQuestionAtIndex(props.template.id, questionIndex)
    if (response) {
      const newTemplate = { ...template }
      newTemplate.questions.splice(questionIndex, 1)
      setTemplate(newTemplate)
      props.onTemplateChange(newTemplate)
    }
  }

  async function addQuestion(questionToAdd: Question) {
    addQuestionToTemplate(props.template.id, questionToAdd)
      .then(() => {
        const newTemplate = { ...template }
        newTemplate.questions.push(questionToAdd)
        setTemplate(newTemplate)
        props.onTemplateChange(newTemplate)
      })
      .catch((error: ApiError) => {
        publishApiError(error.details)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  async function updateQuestion(modifiedQuestion: Question, questionIndex: number) {
    try {
      await updateQuestionAtIndex(props.template.id, questionIndex, modifiedQuestion)
      const newTemplate = { ...template }
      newTemplate.questions[questionIndex] = modifiedQuestion
      setTemplate(newTemplate)
    } catch (error) {
      if (error instanceof ApiError) {
        publishApiError(error.details)
      } else {
        console.error('Update question error:', error)
      }
    }
  }

  const SaveButton = () => {
    return (
      <LoadingButton
        sx={{ width: '30em' }}
        variant={'contained'}
        endIcon={<Save style={{ fill: '#fafafa' }} />}
        loadingPosition={'end'}
        loading={loading}
        onClick={handleSave}
        disabled={!modifications.title && !modifications.description && !modifications.referencePeriod}
      >
        Save
      </LoadingButton>
    )
  }

  return (
    //// va bene questo
    <Grid
      item
      key={props.template.id}
      sx={{
        width: '100%',
        // bgcolor:'purple',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        px: { xs: 1, sm: 2, md: 2, lg: 2 },
      }}
    >
      <Grid id="InputTestoRefrence" item xs={12} display={'flex'} justifyContent={'space-between'} sx={{ mb: 2 }}>
        <TextField
          sx={{ width: '45%' }}
          variant={'outlined'}
          value={template.title}
          label={'Title'}
          onChange={handleTitleChange}
          error={errors.title}
          disabled={disableButtons}
        />

        <TextField
          sx={{ width: '45%' }}
          variant={'outlined'}
          value={template.referencePeriod}
          label={'Reference period'}
          disabled={disableButtons}
          onChange={handleReferencePeriodChange}
          error={errors.referencePeriod}
        />
      </Grid>

      <Grid id="InputDescrizione" item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <TextField
          variant={'outlined'}
          fullWidth
          value={template.description}
          label={'Description'}
          onChange={handleDescriptionChange}
          error={errors.description}
          multiline
          maxRows={4}
          disabled={disableButtons}
        />
      </Grid>

      <Grid id="InputSaveButton" item display={'flex'} justifyContent={'center'} sx={{ mb: 2 }}>
        <SaveButton />
      </Grid>

      <Grid id="Alert" item xs={12} display="flex" justifyContent="center" sx={{ mb: 2 }}>
        <Collapse in={errorState}>
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              wordWrap: 'break-word',
              width: '100%',
              height: 'auto',
              maxWidth: { xs: 250, sm: 350 },
              mx: 'auto',
              justifyContent: 'center',
              '& .MuiAlert-message': {
                width: '100%',
                wordWrap: 'break-word',
              },
            }}
          >
            <Typography
              textAlign="justify"
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
              {erroreMessages}
            </Typography>
          </Alert>
        </Collapse>
      </Grid>

      <Grid id="GridQuestionList" item xs={12} md={12}>
        <QuestionList
          deleteQuestion={deleteQuestion}
          addQuestion={addQuestion}
          updateQuestion={updateQuestion}
          questions={template.questions}
          disableButtons={disableButtons}
        />
      </Grid>
    </Grid>
  )
}
