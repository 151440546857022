import { ButtonBaseOwnProps, Container, Paper, Tab, TabOwnProps, Tabs, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CommonProps } from '@mui/material/OverridableComponent'
import { JSX } from 'react/jsx-runtime'
import { useSearchParams } from 'react-router-dom'
import { CompilationContainer } from '../../../model/CompilationContainer'
import FullTemplate from '../templates/FullTemplate'
import { HistogramView } from './HistogramView'
import { HistoryView } from './HistoryView'
import { OpenQuestionsView } from './OpenQuestionsView'
import { PieView } from './PieView'
import { SubmittedTemplateOverview } from './SubmittedTemplateOverview'
import { isMobileOnly } from 'react-device-detect'
import { Template } from '../../../model/Template'

type SubmittedTemplateInfoProps = {
  submittedTemplate: CompilationContainer
}

export function SubmittedTemplateInfo(props: SubmittedTemplateInfoProps) {
  const [tabValue, setTabValue] = useState<number>(0)

  const [searchParams] = useSearchParams()
  const selectedSubmittedTemplateId = searchParams.get('id')

  function handleTabChange(_event: React.SyntheticEvent, newValue: number) {
    setTabValue(newValue)
  }

  useEffect(() => {
    setTabValue(0)
  }, [selectedSubmittedTemplateId])

  function getTab() {
    switch (tabValue) {
      case 0:
        return (
          <FullTemplate
            template={props.submittedTemplate.template}
            disableButtons={true}
            onTemplateChange={function (template: Template): void {
              throw new Error('Function not implemented.')
            }}
          />
        )
      case 1:
        return <SubmittedTemplateOverview submittedTemplate={props.submittedTemplate} />
      case 2:
        return <HistoryView submittedTemplateId={props.submittedTemplate.id} />
      case 3:
        return <HistogramView submittedTemplateId={props.submittedTemplate.id} />
      case 4:
        return <PieView submittedTemplateId={props.submittedTemplate.id} />
      case 5:
        return <OpenQuestionsView submittedTemplateId={props.submittedTemplate.id} />
      default:
        return null
    }
  }

  const noCompilations = props.submittedTemplate.numberOfCompilations === 0
  const isAnonymous = props.submittedTemplate.type === 'ANONYMOUS'
  const noClosedQuestions =
    props.submittedTemplate.template.questions.filter((question) => {
      return question.questionType === 'SINGLE_CHOICE' || question.questionType === 'MULTIPLE_CHOICE'
    }).length === 0
  const noOpenQuestions =
    props.submittedTemplate.template.questions.filter((question) => {
      return question.questionType === 'NUMERIC' || question.questionType === 'TEXTUAL'
    }).length === 0

  function CloneProps(props: { [x: string]: never; children: never }) {
    const { children, ...other } = props
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return children(other)
  }

  function disabledTabIf(
    label: string,
    value: number,
    condition: boolean,
    disabledTooltip: string,
    enabledTooltip?: string
  ) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <CloneProps>
        {(
          tabProps: JSX.IntrinsicAttributes & {
            href: string
          } & TabOwnProps &
            Omit<ButtonBaseOwnProps, 'classes'> &
            CommonProps &
            Omit<
              Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'> & {
                ref?:
                  | ((instance: HTMLAnchorElement | null) => void)
                  | React.RefObject<HTMLAnchorElement>
                  | null
                  | undefined
              },
              | 'children'
              | 'className'
              | 'style'
              | 'classes'
              | 'sx'
              | 'label'
              | 'tabIndex'
              | 'disabled'
              | 'value'
              | 'action'
              | 'centerRipple'
              | 'disableRipple'
              | 'disableTouchRipple'
              | 'focusRipple'
              | 'focusVisibleClassName'
              | 'LinkComponent'
              | 'onFocusVisible'
              | 'TouchRippleProps'
              | 'touchRippleRef'
              | 'disableFocusRipple'
              | 'icon'
              | 'iconPosition'
              | 'wrapped'
            >
        ) => (
          <Tooltip title={condition ? disabledTooltip : enabledTooltip ?? ''} arrow>
            <span>
              <Tab
                sx={{ justifyContent: 'space-between' }}
                {...tabProps}
                label={label}
                value={value}
                disabled={condition}
                wrapped
              />
            </span>
          </Tooltip>
        )}
      </CloneProps>
    )
  }

  return (
    <Container id="containerTab" disableGutters>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant={isMobileOnly ? 'scrollable' : 'standard'}
        scrollButtons={isMobileOnly ? 'auto' : false}
        allowScrollButtonsMobile={isMobileOnly}
        sx={{
          '& .MuiTabs-flexContainer': {
            justifyContent: isMobileOnly ? 'flex-start' : 'space-around',
          },
        }}
      >
        <Tab label={'Overview'} value={0} />
        <Tab label={'Configuration'} value={1} />
        {disabledTabIf(
          'History',
          2,
          noCompilations || isAnonymous,
          noCompilations ? 'No compilations' : "Can't show history for anonymous submitted templates"
        )}
        {disabledTabIf(
          'Histogram',
          3,
          noCompilations || noClosedQuestions,
          noCompilations ? 'No compilations' : 'No closed questions'
        )}
        {disabledTabIf(
          'Pie',
          4,
          noCompilations || noClosedQuestions,
          noCompilations ? 'No compilations' : 'No closed questions'
        )}
        {disabledTabIf(
          'Open Questions',
          5,
          noCompilations || noOpenQuestions,
          noCompilations ? 'No compilations' : 'No open questions'
        )}
      </Tabs>
      <Paper
        sx={{
          maxHeight: '85dvh',
          backgroundColor: 'inherit',
        }}
        elevation={0}
      >
        <Container id="ioSono?" sx={{ paddingTop: 3.5 }}>
          {getTab()}
        </Container>
      </Paper>
    </Container>
  )
}
