import FormControl from '@mui/material/FormControl'
import * as React from 'react'
import { useEffect } from 'react'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import { Dialog, DialogActions, DialogContent, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import {
  MultipleChoiceQuestion,
  NumericQuestion,
  Question,
  SingleChoiceQuestion,
  TextualQuestion,
  WeightedChoice,
} from '../../../../model/Question'
import NumericQuestionForm from './NumericQuestionForm'
import { SingleChoiceQuestionForm } from './SingleChoiceQuestionForm'
import { MultipleChoiceQuestionForm } from './MultipleChoiceQuestionForm'
import TextualQuestionForm from './TextualQuestionForm'
// import {
//     MultipleChoiceQuestion,
//     NumericQuestion,
//     Question,
//     SingleChoiceQuestion,
//     TextualQuestion, WeightedChoice
// } from "../../../model/Question.ts";
// import TextualQuestionForm from "./TextualQuestionForm.tsx";
// import NumericQuestionForm from "./NumericQuestionForm.tsx";
// import {SingleChoiceQuestionForm} from "./SingleChoiceQuestionForm.tsx";
// import {MultipleChoiceQuestionForm} from "./MultipleChoiceQuestionForm.tsx"

type DialogSelectProps = {
  setClose: (close: boolean) => void
  addQuestion?: (question: Question) => void
  questionToUpdate?: Question
  updateQuestion?: (question: Question, index: number) => void
  index?: number
  setCardQuestionType?: (questionType: string) => void
  setCardMandatoryLabel?: (mandatoryLabel: string) => void
}

export default function QuestionForm({
  setClose,
  addQuestion,
  questionToUpdate,
  index,
  updateQuestion,
  setCardQuestionType,
  setCardMandatoryLabel,
}: DialogSelectProps) {
  const [formType, setFormType] = React.useState<string>(questionToUpdate?.questionType ?? 'SINGLE_CHOICE')
  const [submit, setSubmit] = React.useState<boolean>(false)
  const [onSubmitErrors, setOnSubmitErrors] = React.useState<boolean>(false)
  let newQuestion: TextualQuestion | NumericQuestion | SingleChoiceQuestion | MultipleChoiceQuestion
  let newQuestionErrors: boolean = false
  const handleChangeQuestionTypeForm = (event: SelectChangeEvent<typeof formType>) => {
    setFormType(event.target.value)
  }

  const newQuestionCallBack = (
    question: TextualQuestion | NumericQuestion | SingleChoiceQuestion | MultipleChoiceQuestion,
    errors: boolean
  ) => {
    newQuestion = question
    newQuestionErrors = errors
  }

  const handleClose = () => {
    setClose(false)
  }

  useEffect(() => {
    if (submit) {
      let tempSubmitErrors = false

      if (newQuestionErrors || newQuestion.title.length === 0) {
        tempSubmitErrors = true
        console.log('error')
      } else if (newQuestion.questionType === 'SINGLE_CHOICE' || newQuestion.questionType === 'MULTIPLE_CHOICE') {
        const choices = (newQuestion as SingleChoiceQuestion | MultipleChoiceQuestion).choiceSelection.choices

        if (choices.length < 2 || choices.length > 5) {
          tempSubmitErrors = true
          console.log('error invalid choices MIN 2')
        } else {
          choices.forEach((choice) => {
            if (typeof choice !== 'string') {
              const asWeighted = choice as WeightedChoice
              if (asWeighted.choice.length === 0 || asWeighted.weight <= 0 || asWeighted.weight === undefined) {
                tempSubmitErrors = true
                console.log('error invalid choices' + (asWeighted.choice.length === 0 ? ' EMPTY' : ' WEIGHT'))
              }
            } else if (choice.length === 0) {
              tempSubmitErrors = true
              console.log('error invalid choices EMPTY')
            }
          })
        }
      }

      if (!tempSubmitErrors) {
        if (addQuestion) {
          addQuestion(newQuestion)
        } else if (updateQuestion) {
          updateQuestion(newQuestion, index as number)
        }

        if (setCardQuestionType && setCardMandatoryLabel) {
          let temp = newQuestion.questionType.toLowerCase().replace('_', ' ')
          temp = temp.charAt(0).toUpperCase() + temp.slice(1)
          setCardQuestionType(temp)
          setCardMandatoryLabel(newQuestion.isMandatory ? 'Mandatory' : 'Optional')
        }

        setClose(false)
      }
      setOnSubmitErrors(tempSubmitErrors)
      setSubmit(false)
    }
  }, [submit])

  function submitQuestion() {
    setSubmit(true)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown open={true} onClose={handleClose} fullWidth={true}>
        <DialogContent sx={{ scrollbarGutter: 'stable', overflowX: 'hidden' }}>
          <Box component="form" maxHeight={'70svh'}>
            <Grid container spacing={0} width={'100%'}>
              <Grid item md={5} xs={12} marginTop={2}>
                <Typography variant={'h6'} padding={0} margin={0} align={'center'}>
                  Create a New Question
                </Typography>
              </Grid>
              <Grid item md={3} xs={0}></Grid>
              <Grid item md={4} xs={12}>
                <FormControl sx={{ marginTop: 1 }} fullWidth>
                  <InputLabel htmlFor="demo-dialog-native">Type</InputLabel>
                  <Select
                    onChange={handleChangeQuestionTypeForm}
                    value={formType}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type"
                  >
                    <MenuItem value={'SINGLE_CHOICE'}>Single Choice</MenuItem>
                    <MenuItem value={'MULTIPLE_CHOICE'}>Multiple Choice</MenuItem>
                    <MenuItem value={'TEXTUAL'}>Textual</MenuItem>
                    <MenuItem value={'NUMERIC'}>Numeric</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {formType === 'NUMERIC' && (
                  <NumericQuestionForm
                    newQuestionCallBack={newQuestionCallBack}
                    questionToUpdate={questionToUpdate as NumericQuestion}
                    submit={submit}
                    onSubmitErrors={onSubmitErrors}
                    setSubmitErrors={setOnSubmitErrors}
                  />
                )}
                {formType === 'SINGLE_CHOICE' && (
                  <SingleChoiceQuestionForm
                    newQuestionCallBack={newQuestionCallBack}
                    questionToUpdate={questionToUpdate as SingleChoiceQuestion}
                    submit={submit}
                    onSubmitErrors={onSubmitErrors}
                    setSubmitErrors={setOnSubmitErrors}
                  />
                )}
                {formType === 'MULTIPLE_CHOICE' && (
                  <MultipleChoiceQuestionForm
                    newQuestionCallBack={newQuestionCallBack}
                    questionToUpdate={questionToUpdate as MultipleChoiceQuestion}
                    submit={submit}
                    onSubmitErrors={onSubmitErrors}
                    setSubmitErrors={setOnSubmitErrors}
                  />
                )}
                {formType === 'TEXTUAL' && (
                  <TextualQuestionForm
                    newQuestionCallBack={newQuestionCallBack}
                    questionToUpdate={questionToUpdate as TextualQuestion}
                    submit={submit}
                    onSubmitErrors={onSubmitErrors}
                    setSubmitErrors={setOnSubmitErrors}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              submitQuestion()
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
