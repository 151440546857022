import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Typography, Slide, DialogContent, Container, Button, DialogActions, Collapse } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import DeleteIcon from '@mui/icons-material/Delete'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { deletePrenotazioni } from '../../services/reservationServices'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Rome')

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const GiornateConPrenotazioni = (props) => {
  const { isOpen, onClose, giorni, prenotazioni } = props
  // const authentication = JSON.parse(sessionStorage.getItem("authentication"));
  const [hidden, setHidden] = useState({})

  giorni.forEach((giorno) => {
    Object.values(prenotazioni(giorno)).forEach((prenotazione) => {
      prenotazione.oraInizio = localTime(prenotazione.inizio)
      prenotazione.oraFine = localTime(prenotazione.fine)
    })
  })

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose()
          setHidden({})
        }}
        fullScreen
        TransitionComponent={Transition}
        transitionDuration={400}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          {' '}
          <b>Prenotazioni effettuate</b>
        </DialogTitle>
        <DialogContent>
          <Container sx={{ width: 'fit-content', minWidth: '50%' }}>
            {giorni.map((giorno, i) => (
              <Collapse key={giorno} in={!hidden[giorno]}>
                <Paper elevation={2} sx={{ marginTop: '10px', padding: '10px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5">{giornoFormat(giorno)}</Typography>
                    {!(prenotazioni(giorno)[0].inizio < new Date().getTime()) && (
                      <Button
                        onClick={() => {
                          setHidden((hidden) => ({ ...hidden, [giorno]: !hidden[giorno] }))
                          const listPrenotazioni = prenotazioni(giorno).map((prenotazione) => prenotazione.id)
                          // console.log("🚀 ~ GiornateConPrenotazioni ~ listPrenotazioni:", listPrenotazioni)
                          return deletePrenotazioni(listPrenotazioni)
                        }}
                      >
                        <DeleteIcon sx={{ fontSize: 25 }} color="error" />
                      </Button>
                    )}
                  </Box>
                  <Paper
                    elevation={0}
                    sx={{
                      marginTop: '20px',
                      width: 'fit-content',
                      margin: 'auto',
                    }}
                    variant="outlined"
                  >
                    <Table size="small">
                      <TableBody>
                        {prenotazioni(giorno).map((prenotazione) => (
                          <TableRow key={prenotazione.id}>
                            <TableCell>{prenotazione.postazioneId}</TableCell>
                            <TableCell>
                              {localTime(prenotazione.inizio)} - {localTime(prenotazione.fine)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Paper>
              </Collapse>
            ))}
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} sx={{ marginLeft: 'auto' }}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default GiornateConPrenotazioni

const giornoFormat = (localDate) => {
  const [anno, mese, giorno] = localDate.split('-').map((string) => Number(string))

  const mesiStr = [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
  ]

  return `${giorno} ${mesiStr[mese - 1]}`
}

const localTime = (timestamp) => dayjs(timestamp).format('HH:mm')
