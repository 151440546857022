import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useState } from 'react'
import { Question } from '../../../model/Question'
import { QuestionCard } from './cards/QuestionCard'
import QuestionForm from './form/QuestionForm'
import { useTheme } from '@mui/system'
import '../../../styles/Survey.css'

type QuestionListProps = {
  questions: Question[]
  deleteQuestion: (questionIndex: number) => void
  addQuestion: (question: Question) => void
  updateQuestion: (question: Question, index: number) => void
  disableButtons: boolean
}

export function QuestionList(props: QuestionListProps) {
  const theme = useTheme()
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'))

  function mapQuestions(questions: Question[]) {
    return questions.map((question: Question, index: number) => {
      return (
        <Grid item xs={12} sm={10} md={10} lg={6} key={index}>
          <QuestionCard
            index={index}
            question={question}
            onClickDelete={props.deleteQuestion}
            updateQuestion={props.updateQuestion}
            disableButtons={props.disableButtons}
          />
        </Grid>
      )
    })
  }

  const [openCreateQuestion, setOpenCreateQuestion] = useState(false)
  const handleClickOpen = () => {
    setOpenCreateQuestion(true)
  }

  const handleClose = () => {
    setOpenCreateQuestion(false)
  }

  return (
    <>
      <Grid sx={{ display: 'flex', mb: 2, justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Questions
        </Typography>
        {!props.disableButtons && (
          <Button variant="outlined" size="medium" onClick={handleClickOpen}>
            {isXsScreen ? <Add /> : 'Crea Domanda'}
          </Button>
        )}
        {openCreateQuestion && <QuestionForm setClose={handleClose} addQuestion={props.addQuestion} />}
      </Grid>

      {props.questions.length > 0 && (
        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'space-between' }}
          sx={{ mb: 2 }}
        >
          {mapQuestions(props.questions)}
        </Grid>
      )}
    </>
  )
}
