import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DataGrid, GridActionsCellItem, GridCellEditStopReasons, useGridApiContext } from '@mui/x-data-grid'
import { InputBase, Popper } from '@mui/material'
import Paper from '@mui/material/Paper'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import DeleteIcon from '@mui/icons-material/Delete'
import Swal from 'sweetalert2'
import DownloadCandidate from '../../components/hrGame/DownloadCandidate'
import { formatDate_dd_MM_yyyy_hh_mm_ss } from '../../utils'
import {
  hrGameCandidateComment,
  hrGameDeleteCandidate,
  hrGameGetCandidateForEventId,
  hrGameToggleCandidateFavourite,
} from '../../services/hrGameServices'

function isKeyboardEvent(event) {
  return !!event.key
}

function EditTextarea(props) {
  const { id, field, value, colDef, hasFocus } = props
  const [valueState, setValueState] = React.useState(value)
  const [anchorEl, setAnchorEl] = React.useState()
  const [inputRef, setInputRef] = React.useState(null)
  const apiRef = useGridApiContext()

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus()
    }
  }, [hasFocus, inputRef])

  const handleRef = React.useCallback((el) => {
    setAnchorEl(el)
  }, [])

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value
      setValueState(newValue)
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event)
      // console.log("Aggiornato valoree")
    },
    [apiRef, field, id]
  )

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: 'both' }, width: '100%' }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  )
}

export default function HREventDetail() {
  const [contacts, setContacts] = useState([])
  const [searchParams] = useSearchParams()
  const eventId = searchParams.get('eventId')

  useEffect(() => {
    hrGameGetCandidateForEventId(eventId)
      .then((response) => {
        setContacts(response)
      })
      .catch((e) => {
        console.log('Errore: ', e)
      })
  }, [eventId])

  const toggleFavourite = (event_row) => {
    console.log('toggleFavourite: ', event_row.row.id)
    hrGameToggleCandidateFavourite(event_row.row.id)
      .then((toggleFavouriteResponse) => {
        setContacts((prevContacts) => {
          return prevContacts.map((contact) => {
            if (contact.id === event_row.row.id) {
              // Aggiorna solo l'utente interessato con il nuovo valore di `favourite`
              return { ...contact, favourite: !contact.favourite }
            }
            return contact
          })
        })
      })
      .catch((error) => {
        console.error("Errore durante l'aggiornamento della favourite:", error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Errore durante l'aggiornamento della preferenza",
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }
  const handleClickDelete = (event_row) => {
    console.log('handleClickDelete: ', event_row)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Call API e mostrare result
        hrGameDeleteCandidate(event_row.row.id)
          .then((response) => {
            const newContacts = contacts.filter((contact) => contact.email !== event_row.row.email)
            setContacts(newContacts)
            // Lo rimuovo anche dai dati locali
            Swal.fire({
              title: 'Deleted!',
              text: 'Your file has been deleted.',
              icon: 'success',
            })
          })
          .catch((error) => {
            console.error('Si è verificato un errore:', error)
            Swal.fire({
              title: 'Error!',
              text: "Errore durante la cancellazione dell'utente.",
              icon: 'error',
            })
          })
      }
    })
  }
  const handleUpdateComment = (newData) => {
    console.log('handleUpdateComment: ', newData)
    hrGameCandidateComment({ candidateId: newData.id, comment: newData.comment })
      .then((response) => {
        // DONE
      })
      .catch((error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Errore nel salvataggio del comment',
          showConfirmButton: false,
          timer: 1500,
        })
        console.error('Error updating comment:', error)
      })
  }
  const multilineColumn = {
    type: 'string',
    renderEditCell: (params) => <EditTextarea {...params} />,
  }
  const columns = [
    {
      field: 'favourite',
      headerName: 'Preferito',
      flex: 1,
      // valueGetter: (params) => params.row.favourite ? '⭐' : <StarIcon/>
      renderCell: (params) =>
        params.row.favourite ? (
          <GridActionsCellItem icon={<StarIcon />} onClick={() => toggleFavourite(params)} label="Favourite" />
        ) : (
          <GridActionsCellItem
            icon={<StarBorderIcon />}
            onClick={() => toggleFavourite(params)}
            label="Not Favourite"
          />
        ),
    },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'firstName', headerName: 'Nome', flex: 1 },
    { field: 'lastName', headerName: 'Cognome', flex: 1 },
    { field: 'position', headerName: 'Posizione', flex: 1 },
    { field: 'role', headerName: 'Ruolo', flex: 1 },
    {
      field: 'comment',
      headerName: 'Commento',
      flex: 4,
      editable: true,
      sortable: false,
      ...multilineColumn,
    },
    { field: 'score', headerName: 'Score', flex: 1 },
    {
      field: 'playTime',
      headerName: 'Data partita',
      flex: 2,
      valueGetter: (params) => formatDate_dd_MM_yyyy_hh_mm_ss(params.row.playTime),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Azioni',
      sortable: false,
      minWidth: 150,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={() => handleClickDelete(params)} label="Delete" />,
      ],
    },
  ]
  return (
    <>
      <h1>HR Event Detail</h1>
      <DownloadCandidate utenti={contacts} nomeEvento={eventId} />
      <div style={{ height: '70vh' }}>
        <DataGrid
          getRowId={(row) => row.id}
          rows={contacts}
          columns={columns}
          onCellEditStop={(params, event) => {
            console.log('Finito di editare la cella: ', params, event)

            if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
              return
            }
            if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
              event.defaultMuiPrevented = true
            }
          }}
          processRowUpdate={(updatedRow, originalRow) => {
            // Update
            if (originalRow.comment !== updatedRow.comment) {
              // Commento aggiornato
              console.log('Commento aggiornato')
              handleUpdateComment(updatedRow)
            }
            return { ...originalRow, ...updatedRow }
          }}
          onProcessRowUpdateError={(a) => console.log('onProcessRowUpdateError: ', a)}
        />
      </div>
    </>
  )
}
