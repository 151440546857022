import React, { useEffect, useState } from 'react'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { formatDate_dd_MM_yyyy } from '../../utils'
import InfoIcon from '@mui/icons-material/Info'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import CircleIcon from '@mui/icons-material/Circle'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import {
  hrGameDeletePosition,
  hrGameDeleteRole,
  hrGameGetEvent,
  hrGameGetPosition,
  hrGameGetRole,
  hrGamePostEvent,
  hrGamePostPosition,
  hrGamePostRole,
  hrGameSetEventActive,
} from '../../services/hrGameServices'

export default function HREvents() {
  const [events, setEvents] = useState([])
  const [positions, setPositions] = useState([])
  const [roles, setRoles] = useState([])
  const navigate = useNavigate()

  const [newPosition, setNewPosition] = useState('')
  const [newRole, setNewRole] = useState('')

  const [modalNewEventOpen, setModalNewEventOpen] = React.useState(false)

  const handleClickOpenModalNewEvent = () => {
    setModalNewEventOpen(true)
  }

  const handleCloseOpenModalNewEvent = () => {
    setModalNewEventOpen(false)
  }

  const handleClickInfoEvent = (event_row) => {
    navigate('/hrGame/eventDetails?eventId=' + event_row.id)
  }

  const handleClickSetActiveEvent = (event_row) => {
    hrGameSetEventActive(event_row.row.id)
      .then((setActiveResponse) => {
        setEvents(setActiveResponse)
      })
      .catch((error) => {
        console.error("Errore durante l'aggiornamento evento active:", error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Errore durante l'aggiornamento dell'evento attivo!",
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1.5,
    },
    {
      field: 'active',
      headerName: 'Attivo',
      flex: 1,
      // valueGetter: (params) => params.row.active ? '🟢' : '🔴'
      renderCell: (params) =>
        params.row.active ? (
          <GridActionsCellItem
            icon={<ExpandCircleDownIcon />}
            sx={{ color: 'green' }}
            onClick={() => console.log(params)}
            label="Favourite"
          />
        ) : (
          <GridActionsCellItem
            icon={<CircleIcon />}
            sx={{ color: 'gray' }}
            onClick={() => handleClickSetActiveEvent(params)}
            label="Imposta come attivo"
          />
        ),
    },
    {
      field: 'date',
      headerName: 'Data',
      type: 'string',
      flex: 1,
      valueGetter: (params) => formatDate_dd_MM_yyyy(params.row.date),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Azioni',
      sortable: false,
      minWidth: 150,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ color: 'green' }}
          icon={<InfoIcon />}
          onClick={() => handleClickInfoEvent(params)}
          label="Info"
        />,
      ],
    },
  ]

  // Load all data
  useEffect(() => {
    // Load Events
    hrGameGetEvent()
      .then((eventResponse) => {
        setEvents(eventResponse)
      })
      .catch((error) => {
        console.error('Error fetching event:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Errore durante il caricamento degli eventi!',
          showConfirmButton: false,
          timer: 1500,
        })
      })

    // Load Positions
    hrGameGetPosition()
      .then((positionResponse) => {
        setPositions(positionResponse)
      })
      .catch((error) => {
        console.error('Error fetching positions:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Errore durante il caricamento delle posizioni!',
          showConfirmButton: false,
          timer: 1500,
        })
      })

    // Load Roles
    hrGameGetRole()
      .then((roleResponse) => {
        setRoles(roleResponse)
      })
      .catch((error) => {
        console.error('Error fetching roles:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Errore durante il caricamento dei ruoli!',
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }, [])

  const handleChangeNewPositionValue = (role) => {
    setNewPosition(role.target.value)
  }
  const handleChangeNewRoleValue = (role) => {
    setNewRole(role.target.value)
  }

  const handleCreateEvent = (name, date) => {
    if (name.trim() === '' || date === '') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "Nome e data dell'evento non può essere vuoto!",
        showConfirmButton: false,
        timer: 1500,
      })
      return
    }

    const newEventData = {
      name: name,
      date: new Date(date).toISOString(),
    }
    hrGamePostEvent(newEventData)
      .then((newEventResponse) => {
        setEvents(newEventResponse)
      })
      .catch((error) => {
        console.error('Error create position:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Errore durante l'inserimento dell'evento!",
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  const handleCreatePosition = () => {
    if (newPosition.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Il nome della posizione non può essere vuoto!',
        showConfirmButton: false,
        timer: 1500,
      })
      return
    }
    const newPositionData = { positionName: newPosition }
    hrGamePostPosition(newPositionData)
      .then((newPositions) => {
        if (!newPositions) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Posizione già inserita!',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          setPositions(newPositions)
        }
      })
      .catch((error) => {
        // 409 - Position already exist
        console.error('Error updating position:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Errore durante l'inserimento dela posizione!",
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  const handleCreateRole = () => {
    if (newRole.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Il nome del ruolo non può essere vuoto!',
        showConfirmButton: false,
        timer: 1500,
      })
      return
    }
    const newRoleData = { roleName: newRole }
    hrGamePostRole(newRoleData)
      .then((newRoles) => {
        console.log('Response newRoles: ', newRoles)
        setRoles(newRoles)
      })
      .catch((error) => {
        // 409 - Role already exist
        console.error('Error updating roles:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Errore durante l'inserimento del ruolo!",
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  const handleDeletePosition = (positionId) => {
    console.log('handleDeletePosition: ' + positionId)
    hrGameDeletePosition(positionId)
      .then((newPositions) => {
        setPositions(newPositions)
      })
      .catch((error) => {
        console.error('Error deleting positions:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Errore durante eliminazione posizione!',
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  const handleDeleteRole = (roleId) => {
    console.log('handleDeleteRole: ' + roleId)
    hrGameDeleteRole(roleId)
      .then((newRoles) => {
        setRoles(newRoles)
      })
      .catch((error) => {
        console.error('Error deleting roles:', error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Errore durante eliminazione ruolo!',
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }
  return (
    <>
      <h1>HR Events</h1>
      <h3>Configurazioni</h3>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-position-content"
          id="panel-position-header"
        >
          Configura Posizioni
        </AccordionSummary>
        <AccordionDetails>
          <Grid item md={12}>
            <TextField
              id="outlined-basic"
              label="Posizione"
              variant="outlined"
              value={newPosition}
              onChange={handleChangeNewPositionValue}
            />
            <Button variant="contained" onClick={() => handleCreatePosition()}>
              Add
            </Button>
            <Grid item md={12}>
              {positions.map((position, index) => (
                <Chip
                  key={'position-' + index}
                  label={position.name}
                  variant="outlined"
                  onDelete={() => handleDeletePosition(position.id)}
                />
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-ruoli-content" id="panel-ruoli-header">
          Configura Ruoli
        </AccordionSummary>
        <AccordionDetails>
          <Grid item md={12}>
            <TextField
              id="outlined-basic"
              label="Ruolo"
              variant="outlined"
              value={newRole}
              onChange={handleChangeNewRoleValue}
            />
            <Button variant="contained" onClick={() => handleCreateRole()}>
              Add
            </Button>
            <Grid item md={12}>
              {roles.map((role, index) => (
                <Chip
                  key={'role-' + index}
                  label={role.name}
                  variant="outlined"
                  onDelete={() => handleDeleteRole(role.id)}
                />
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <div style={{ height: '70vh' }}>
        <h3>Eventi</h3>
        <Button variant="outlined" onClick={handleClickOpenModalNewEvent}>
          Crea Evento
        </Button>
        <DataGrid rows={events} columns={columns} />
      </div>
      <Dialog
        open={modalNewEventOpen}
        onClose={handleCloseOpenModalNewEvent}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries(formData.entries())
            console.log('formJson: ', formJson)
            const name = formJson.name
            const date = formJson.date
            handleCreateEvent(name, date)
            handleCloseOpenModalNewEvent()
          },
        }}
      >
        <DialogTitle>Nuovo Evento</DialogTitle>
        <DialogContent>
          <DialogContentText>Inserisci i dettagli dell'evento</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="date"
            name="date"
            label="Date"
            type="date"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenModalNewEvent}>Annulla</Button>
          <Button variant="contained" type="submit">
            Crea
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
