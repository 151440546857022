import React from 'react'
import { Box, Typography } from '@mui/material'
import {
  MultipleChoiceQuestion,
  NumericQuestion,
  Question,
  SingleChoiceQuestion,
  TextualQuestion,
} from '../../../../model/Question'
import { AnswerType } from '../TemplateCompilation'
import { TextualAnswer } from './TextualAnswer'
import { NumericAnswer } from './NumericAnswer'
import { SingleChoiceAnswer } from './SingleChoiceAnswer'
import { MultipleChoiceAnswer } from './MultipleChoiceAnswer'
// import {TextualAnswer} from "./TextualAnswer.tsx";
// import {TextualQuestion} from "../../../model/Question.ts";
// import {AnswerType} from "../TemplateCompilation.tsx";
// import {NumericAnswer} from "./NumericAnswer.tsx";
// import {NumericQuestion} from "../../../model/Question.ts";
// import {SingleChoiceQuestion} from "../../../model/Question.ts";
// import { SingleChoiceAnswer } from "./SingleChoiceAnswer.tsx";
// import {MultipleChoiceAnswer} from "./MultipleChoiceAnswer.tsx";
// import {MultipleChoiceQuestion} from "../../../model/Question.ts";
// import {Question} from "../../../model/Question.ts";

type AnswerProps = {
  question: Question
  value: AnswerType
  onChange: (value: AnswerType, index: number) => void
  index: number
  error: boolean
  onError: (error: boolean, index: number) => void
}

export function Answer(props: AnswerProps) {
  function getQuestionNode(question: Question) {
    return (() => {
      switch (question.questionType) {
        case 'TEXTUAL':
          return (
            <TextualAnswer
              question={question as TextualQuestion}
              value={props.value as string}
              onChange={props.onChange}
              index={props.index}
              error={props.error}
              onError={props.onError}
            />
          )
        case 'NUMERIC':
          return (
            <NumericAnswer
              question={question as NumericQuestion}
              value={props.value as string}
              onChange={props.onChange}
              index={props.index}
              error={props.error}
              onError={props.onError}
            />
          )
        case 'SINGLE_CHOICE':
          return (
            <SingleChoiceAnswer
              question={question as SingleChoiceQuestion}
              value={props.value as string}
              onChange={props.onChange}
              index={props.index}
              error={props.error}
              onError={props.onError}
            />
          )
        case 'MULTIPLE_CHOICE':
          return (
            <MultipleChoiceAnswer
              question={question as MultipleChoiceQuestion}
              value={props.value as string[]}
              onChange={props.onChange}
              index={props.index}
              error={props.error}
              onError={props.onError}
            />
          )
      }
    })()
  }

  return (
    <Box>
      <Typography paddingTop={1} sx={{ wordBreak: 'break-word' }} gutterBottom variant={'h6'}>
        {props.question.title}
      </Typography>
      {getQuestionNode(props.question)}
    </Box>
  )
}
