import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
} from '@mui/material'
import { Box } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { createCompilationContainer } from '../../../services/surveyCompilationServices'
import { ApiError } from '../../../errors/ApiError'
import { publishApiError } from '../../../services/eventSercices'
import { DateTimeRangePicker } from '../DateTimeRangePicker'
// import {createCompilationContainer} from "../../services/CompilationService.ts";
// import {ApiError} from "../../errors/ApiError.ts";
// import {publishApiError} from "../../services/EventService.ts";
// import {DateTimeRangePicker} from "../DateTimeRangePicker.tsx";

type CreateCompilationContainerProps = {
  onClose: () => void
  open: boolean
  templateId: string
}

export function CreateCompilationContainer(props: CreateCompilationContainerProps) {
  // const [startDate, setStartDate] = useState<Dayjs>(dayjs().add(10, 'minute')) // 10 minuti dopo l'ora attuale
  const [startDate, setStartDate] = useState<Dayjs>(dayjs())
  const [endDate, setEndDate] = useState<Dayjs>(startDate.add(30, 'minute'))
  const [dateError, setDateError] = useState<boolean>(false)
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false)

  const navigate = useNavigate()

  function handleAnonymousOptionCheck(event: React.ChangeEvent<HTMLInputElement>) {
    setIsAnonymous(event.target.checked)
  }

  async function handleCreateContainer() {
    if (dateError) {
      return
    }
    createCompilationContainer({
      templateId: props.templateId,
      allowedUsers: [],
      type: isAnonymous ? 'ANONYMOUS' : 'DEFAULT',
      beginningDate: startDate,
      endingDate: endDate,
    })
      .then((response) => {
        navigate(`/survey/submitted-templates?id=${response.id}`)
      })
      .catch((error: ApiError) => {
        publishApiError(error.details)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        props.onClose()
      })
  }

  useEffect(() => {
    // setStartDate(dayjs().add(10, 'minute'))
    const now = dayjs()
    setStartDate(now)
    setEndDate(dayjs().add(40, 'minute'))
    setDateError(false)
    setIsAnonymous(false)
  }, [props.open])

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Box>
        <DialogTitle>Submit template</DialogTitle>
        <DialogContent sx={{ paddingBottom: '0px' }}>
          <Grid container marginTop={1} direction={'column'} spacing={1}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <DateTimeRangePicker
                  firstDate={startDate}
                  secondDate={endDate}
                  onSecondDateError={setDateError}
                  onFirstDateChange={setStartDate}
                  onSecondDateChange={setEndDate}
                  firstDateLabel={'Start date'}
                  secondDateLabel={'End date'}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={dateError}>
                <Alert variant={'filled'} severity={'error'}>
                  The end date must be after the start date
                </Alert>
              </Collapse>
              <FormControlLabel
                control={<Checkbox onChange={handleAnonymousOptionCheck} />}
                label="Anonymous"
                value={isAnonymous}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateContainer} disabled={dateError}>
            Avvia questinario
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
