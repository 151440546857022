import * as React from 'react'
import { Box, Collapse, Grid, IconButton, List, ListItem, ListItemText } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { TransitionGroup } from 'react-transition-group'
import cover from '../../assets/calendar.jpg'

export default function ListChiusure(props) {
  const { value, onValueChange } = props

  return (
    <Grid item display={'flex'}>
      {Array.isArray(value) && value.length > 0 ? (
        <List>
          <TransitionGroup>
            {value.map((item, i) => (
              <Collapse key={item}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      title="Elimina"
                      onClick={() => {
                        const newValue = [...value]
                        newValue.splice(i, 1)
                        onValueChange(newValue)
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  }
                >
                  <ListItemText primary={formatGiorno(item)} />
                </ListItem>
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      ) : (
        <Box
          sx={{
            height: '400px',
            width: '400px',
            display: 'flex',
            backgroundImage: `url(${cover})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            mixBlendMode: 'darken',
          }}
        ></Box>
      )}
    </Grid>
  )
}

const formatGiorno = (localDate) => {
  const [anno, mese, giorno] = localDate.split('-').map((x) => Number(x))
  const nomeMese = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ][mese - 1]

  return `${giorno} ${nomeMese} ${anno}`
}
