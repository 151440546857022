import React from 'react'
import { Button, DialogActions, Dialog, DialogTitle, Box } from '@mui/material'

type ConfirmDialogProps = {
  openValue: boolean
  onConfirm?: () => void
  onClose: () => void
  message: string
}

export default function ConfirmDialog({ onClose, openValue, onConfirm, message }: ConfirmDialogProps) {
  function handleConfirm() {
    onClose()
    onConfirm?.()
  }

  return (
    <Dialog
      open={openValue}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ padding: 1, paddingTop: 0 }}>
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions sx={{ paddingTop: 0 }}>
          <Button variant={'contained'} color={'primary'} onClick={onClose}>
            Close
          </Button>
          <Button variant={'contained'} color={'error'} onClick={handleConfirm}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
