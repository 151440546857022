import { TextField } from '@mui/material'
import React from 'react'

type TitleFieldProps = {
  title?: string
  setTitleCallBack: (title: string) => void
  titleError?: boolean
}

export function TitleField({ title, setTitleCallBack, titleError }: TitleFieldProps) {
  const [questionTitle] = React.useState<string>(title ?? '')

  function handleTitleField(event: React.ChangeEvent<HTMLInputElement>) {
    setTitleCallBack(event.target.value)
  }

  return (
    <TextField
      label="Question"
      defaultValue={questionTitle}
      multiline
      placeholder={'Insert question questionTitle'}
      sx={{
        width: '100%',
      }}
      onChange={handleTitleField}
      error={titleError}
      autoFocus
    />
  )
}
