import React from 'react'
import { createRoot } from 'react-dom/client'
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from '@azure/msal-browser'
import { loginRequest, msalConfig } from './authConfig'
import { MsalProvider, useMsal } from '@azure/msal-react'
import App from './App.jsx'
import './styles/App.css'
import { AuthProvider } from './components/authentication/AuthProvider'
import MsalAuthLoader from './MsalAuthLoader'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig)

const tokenCheckInterval = 5 * 60 * 1000 // 5 minutes

function TokenRenewal() {
  const { instance } = useMsal()

  React.useEffect(() => {
    const checkTokenExpiration = () => {
      instance
        .acquireTokenSilent({
          scopes: ['user.read'],
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect(loginRequest).catch((error) => console.log(error))
            // instance.loginRedirect() // Redirect to login page if silent token acquisition fails
          } else {
            console.error('Token renewal error: ', error)
          }
        })
    }

    const intervalId = setInterval(checkTokenExpiration, tokenCheckInterval)
    return () => clearInterval(intervalId)
  }, [instance])

  return null
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
    // Reload for avoid safari and mobile broswer issue
    // window.location.reload()

    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts()

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0])
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    // TODO: Gestire fallimento login
    console.log('Errore login: ' + JSON.stringify(event))
  }
})
// console.log("🚀 ~ msalInstance.addEventCallback ~ msalInstance:", msalInstance)

createRoot(document.querySelector('#root')).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthLoader>
        <TokenRenewal />
        <AuthProvider>
          <App instance={msalInstance} />
        </AuthProvider>
      </MsalAuthLoader>
    </MsalProvider>
  </React.StrictMode>
)
