import { useEffect, useState } from 'react'
import { Box, Container, Divider, Paper, Stack, Typography } from '@mui/material'
import { HistogramGraph } from '../../../model/Graph'
import { getGraphs } from '../../../services/surveyCompilationServices'
import { ApiError } from '../../../errors/ApiError'
import { publishApiError } from '../../../services/eventSercices'
import { Histogram } from '../compilations/graphs/Histogram'
// import {Histogram} from "../compilations/graphs/Histogram.tsx";
// import {getGraphs} from "../../services/CompilationService.ts";
// import {HistogramGraph} from "../../model/Graph.ts";
// import {ApiError} from "../../errors/ApiError.ts";
// import {publishApiError} from "../../services/EventService.ts";

type HistogramViewProps = {
  submittedTemplateId: string
}

export function HistogramView(props: HistogramViewProps) {
  const [graphs, setGraphs] = useState<HistogramGraph[] | undefined>(undefined)

  useEffect(() => {
    getGraphs(props.submittedTemplateId, 'HISTOGRAM')
      .then((graphs) => {
        setGraphs(graphs as HistogramGraph[])
      })
      .catch((error: ApiError) => {
        publishApiError(error.details)
      })
      .catch((error: Error) => {
        console.error(error)
      })
  }, [props.submittedTemplateId, setGraphs])

  return (
    <Paper
      sx={{
        // overflowY: 'scroll',
        maxHeight: '70dvh',
        backgroundColor: 'inherit',
      }}
      elevation={0}
    >
      <Stack spacing={2} divider={<Divider orientation="horizontal" variant={'middle'} />}>
        {graphs
          ? graphs.map((graph: HistogramGraph, index: number) => {
              return (
                <Box key={index}>
                  <Container sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <Typography gutterBottom>{graph.questionTitle}</Typography>
                  </Container>
                  <Histogram values={graph.values} questionTitle={graph.questionTitle} type={'HISTOGRAM'} key={index} />
                </Box>
              )
            })
          : null}
      </Stack>
    </Paper>
  )
}
