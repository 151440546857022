import React, { useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Link, Outlet } from 'react-router-dom'
import ti_logo from '../assets/ti_logo.svg'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LogoutIcon from '@mui/icons-material/Logout'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ScheduleIcon from '@mui/icons-material/Schedule'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import EventSeatIcon from '@mui/icons-material/EventSeat'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'

import Tooltip from '@mui/material/Tooltip'
import { AdminPanelSettings, CalendarMonth, GridView, PlayCircleOutline, VideogameAsset } from '@mui/icons-material'
import SchoolIcon from '@mui/icons-material/School'
import { appRoles } from '../authConfig'
// import NotificationsIcon from '@mui/icons-material/Notifications'
import Swal from 'sweetalert2'
import '../styles/Absence.css'
import '../styles/App.css'
import { Grid } from '@mui/material'
import { useAuth } from './authentication/AuthProvider'
import { isMobile } from 'react-device-detect'

const drawerWidth = 250

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: 'rgba(225, 225, 225, 0.9)',
    scrollbarWidth: 'none', // questa per Firefox
    '&::-webkit-scrollbar': {
      display: 'none', // questa per Chrome, Safari, and Edge
    },
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      backgroundColor: 'rgba(225, 225, 225, 0.9)',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      backgroundColor: 'rgba(225, 225, 225, 0.9)',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
}))

export const BaseLayout = (props) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(isMobile ? false : true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleTimeSheetClick = () => {
    Swal.fire({
      title: 'Vai alla timesheet...',
      text: `Stai utilizzando una connessione interna o esterna all'azienda ?`,
      showDenyButton: true,
      showCancelButton: false,
      showCloseButton: true,
      toast: false,
      position: 'top-start',
      customClass: {
        container: 'my-sweet-alert',
      },
      confirmButtonText: 'Connessione interna',
      denyButtonText: 'Connessione esterna',
      denyButtonColor: '#5596a1',
    }).then((result) => {
      if (result.isConfirmed) {
        window.open('http://192.168.3.10:5555/TimeSheet-10.0/pages/login57.xhtml', '_blank') // Ts-interna
      } else if (result.isDenied) {
        window.open('https://timesheet.telematicainformatica.com/TimeSheet-10.0/pages/login57.xhtml', '_blank') // Ts-esterna
      }
    })
  }

  const logout = () => {
    // console.log('logout: ', props)
    //props.instance.logoutPopup({ postLogoutRedirectUri: '/', mainWindowRedirectUri: '/' })
    props.instance
      .logoutRedirect({ postLogoutRedirectUri: '/', mainWindowRedirectUri: '/' })
      .then((r) => console.log('logoooout redirect'))
  }

  const SidebarItems = () => {
    return (
      <>
        <List>
          <SidebarItem
            ItemIcon={GridView}
            itemKey={'dashboard'}
            itemText={'Dashboard'}
            tooltipTitle={'Dashboard'}
            linkTo={'/dashboard'}
          />
        </List>
        <Divider />
        <List>
          <SidebarItem
            ItemIcon={SchoolIcon}
            itemKey={'e-learning'}
            itemText={'E-learning'}
            tooltipTitle={'E-learning'}
            linkTo={'/education'}
          />
        </List>
        <Divider />
        <List>
          <SidebarItem
            ItemIcon={WorkHistoryIcon}
            itemKey={'timesheet'}
            itemText={'TimeSheet'}
            tooltipTitle={'TimeSheet'}
            linkTo={'#'}
            onClick={handleTimeSheetClick}
          />
        </List>
        {/* /////////
        da integrare
        <Divider />
        <List>
          <SidebarItem
            ItemIcon={NotificationsIcon}
            itemKey={'notification'}
            itemText={'Notifiche'}
            tooltipTitle={'Notifiche'}
            linkTo={'/notification'}

          />
        </List>
        ///////////// */}
        <Divider />
        <List>
          <SidebarItem
            ItemIcon={ScheduleIcon}
            itemKey={'permessi'}
            itemText={'Permessi/Ferie'}
            tooltipTitle={'Permessi/Ferie'}
            linkTo={'/absence'}
          />
          <SidebarItem
            ItemIcon={AdminPanelSettings}
            itemKey={'adminPermessi'}
            itemText={'Admin Permessi/Ferie'}
            tooltipTitle={'Admin Permessi/Ferie'}
            linkTo={'/adminAbsence'}
            roles={[appRoles.AdminAbsence]}
          />
          <SidebarItem
            ItemIcon={CalendarMonth}
            itemKey={'calendario'}
            itemText={'Calendario'}
            tooltipTitle={'Calendario'}
            linkTo={'/calendar'}
            roles={[appRoles.AdminAbsence]}
          />
        </List>
        <Divider />
        <List>
          <SidebarItem
            ItemIcon={EventSeatIcon}
            itemKey={'reservation'}
            itemText={'Prenota postazione'}
            tooltipTitle={'Prenota postazione'}
            linkTo={'/reservation/map'}
          />
          <SidebarItem
            ItemIcon={LibraryBooksIcon}
            itemKey={'manage reservation'}
            itemText={'Gestisci prenotazioni'}
            tooltipTitle={'Gestisci prenotazioni'}
            linkTo={'/reservation/gestisci-prenotazioni'}
          />
          <SidebarItem
            ItemIcon={ControlCameraIcon}
            itemKey={'manage workstations'}
            itemText={'Modifica postazioni'}
            tooltipTitle={'Modifica postazioni'}
            linkTo={'/reservation/edit-map'}
            roles={[appRoles.AdminReservation]}
          />
          <SidebarItem
            ItemIcon={EventBusyIcon}
            itemKey={'extraordinary closures'}
            itemText={'Chiusure settimanali'}
            tooltipTitle={'Chiusure settimanali'}
            linkTo={'/reservation/chiusure-settimanali'}
            roles={[appRoles.AdminReservation]}
          />
        </List>
        <Divider />
        <List>
          <SidebarItem
            ItemIcon={QuestionAnswerIcon}
            itemKey={'survey'}
            itemText={'Questionari'}
            tooltipTitle={'Questionari'}
            linkTo={'/survey'}
          />
          <SidebarItem
            ItemIcon={PlayCircleOutline}
            itemKey={'submitted-survey'}
            itemText={'Questionari avviati'}
            tooltipTitle={'Questionari avviati'}
            linkTo={'/survey/submitted-templates'}
            roles={[appRoles.AdminSurvey]}
          />
        </List>
        <Divider />
        <SidebarItem
          ItemIcon={VideogameAsset}
          itemKey={'hrGame'}
          itemText={'HR Game'}
          tooltipTitle={'HR Game'}
          linkTo={'/hrGame'}
          roles={['HRGameAdmin']}
        />
        <Divider />
        <List>
          <SidebarItem
            ItemIcon={LogoutIcon}
            itemKey={'logout'}
            itemText={''}
            tooltipTitle={'Logout'}
            linkTo={'/'}
            onClick={logout}
            roles={[]}
          />
        </List>
      </>
    )
  }

  const SidebarItem = ({ ItemIcon, itemKey, itemText, tooltipTitle, linkTo, onClick, roles }) => {
    const { roles: userRoles } = useAuth()

    const [isAuthorized, setIsAuthorized] = useState(false)

    useEffect(() => {
      if (roles && roles.length > 0) {
        let intersection = roles.filter((role) => userRoles.includes(role))
        setIsAuthorized(intersection.length > 0)
      } else {
        setIsAuthorized(true)
      }
    }, [userRoles, roles])

    return (
      <>
        {isAuthorized ? (
          <Tooltip title={tooltipTitle} placement="right">
            <Link to={linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem
                key={itemKey}
                onClick={() => {
                  onClick && onClick()
                  handleDrawerClose()
                }}
              >
                <ListItemIcon>
                  <ItemIcon />
                </ListItemIcon>
                <ListItemText primary={itemText} />
              </ListItem>
            </Link>
          </Tooltip>
        ) : null}
      </>
    )
  }

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
        }}
      >
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              <Link to="/">
                <img src={ti_logo} className="ti-logo" alt="Telematica Informatica" />
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <SidebarItems />
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 0,
            ml: {
              xs: `calc(${theme.spacing(7)} + 1px)`,
              sm: `calc(${theme.spacing(7)} + 1px)`,
              md: `calc(${theme.spacing(7)} + 1px)`,
              lg: `calc(${theme.spacing(7)} + 1px)`,
              xl: `calc(${theme.spacing(7)} + 1px)`,
            },
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '',
          }}
        >
          <DrawerHeader />
          {/* {props.children} */}
          <Outlet />
        </Box>
      </Grid>
    </>
  )
}
