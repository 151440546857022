import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../../authConfig'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Checkbox, FormGroup } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ScrollRestoration } from 'react-router-dom'

const NotificationContent = () => {
  const { accounts } = useMsal()
  // console.log(accounts[0].username);

  return (
    <>
      <Grid item height={'100vh'} display={'flex'} flexDirection={'column'}>
        <Grid display={'flex'} flexDirection={'column'} ml={'2.5em'} mt={'2.5em'}>
          <Typography variant="subtitle1" gutterBottom>
            {/* User: <b>servizi@telematicainformatica.it</b> */}
            User: <b>{accounts[0].username}</b>
          </Typography>
          <hr />
          <FormControl component="fieldset" className="form-request">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="h5" variant="h5">
                    Seleziona le notifiche che vuoi ricevere:
                  </Typography>
                  <FormGroup sx={{ justifyContent: 'flex-start' }}>
                    <FormControlLabel control={<Checkbox />} label="Comunicazioni aziendali" />
                    <FormControlLabel control={<Checkbox />} label="Richieste permesso/ferie" />
                    <Button sx={{ justifyContent: 'flex-start' }}>Salva</Button>
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </Grid>
      </Grid>
      <ScrollRestoration />
    </>
  )
}

export const Notification = () => {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
      <NotificationContent />
    </MsalAuthenticationTemplate>
  )
}
