import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { DataGrid } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import { getAbsences, getClientiCommesse, postAbsence } from '../../services/absenceServices'
import { v4 as uuidv4 } from 'uuid'
import { addDays, formatISO, isToday, set } from 'date-fns'
import { formatTableEndDateOrDuration, formatTableStartDate, formatTableStatus } from '../../utils'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import DateRange from 'react-date-range/dist/components/DateRange'
import Swal from 'sweetalert2'
import '../../styles/Absence.css'
import { useMobileOrientation, isMobileOnly } from 'react-device-detect'
import TIicon from '../../assets/icon-loading.png'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import itLocale from 'date-fns/locale/it'
// import {it} from 'date-fns/locale/it';
import { it } from 'date-fns/locale'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import dayjs, { utc } from 'dayjs'
import advancedFormat from 'dayjs/plugin/timezone'
import timezone from 'dayjs/plugin/timezone'
import { ScrollRestoration } from 'react-router-dom'
import { useAuth } from '../../components/authentication/AuthProvider'
import { Checkbox, FormLabel, Typography } from '@mui/material'
import useOrientationEffects from '../../hook/useOrentiationEffetc'
import styled from '@emotion/styled'

const StyledCheckbox = styled(Checkbox)(({ theme, checked }) => ({
  color: checked ? theme.palette.primary.main : theme.palette.error.main,
  '&.Mui-checked': {
    color: 'green',
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({theme, checked }) => ({
  color: checked ? 'inherit' : theme.palette.error.main,
}));

export default function Absence() {
  const theme = useTheme()
  const { isLandscape } = useMobileOrientation()
  const { username } = useAuth()

  const isLowerThanMDLayout = useMediaQuery(theme.breakpoints.down('md'))
  // const [previousOrientation, setPreviousOrientation] = useState(isLandscape);
  const [isSending, setIsSending] = useState(false)

  const [open, setOpen] = useState(false)

  const [absenceType, setAbsenceType] = useState('ferie')

  const [checked, setChecked] = useState(false);

  // DATE
  const [holidayStartDate, setHolidayStartDate] = useState(new Date())
  const [holidayEndDate, setHolidayEndDate] = useState(new Date())

  // Da rimpiazzare sopra
  const [stateDate, setStateDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection',
    },
  ])

  const [monthToDisplay, setMonthToDisplay] = useState(2)

  dayjs.extend(utc)
  dayjs.extend(advancedFormat)
  dayjs.extend(timezone)
  // dayjs.tz.setDefault('Europe/Rome');

  useEffect(() => {
    if (isLowerThanMDLayout) {
      setMonthToDisplay(1)
    } else {
      setMonthToDisplay(2)
    }
  }, [isLowerThanMDLayout])

  useEffect(() => {
    /*
    Example of stateDate content
    [
      {
          "startDate": "2022-05-16T22:00:00.000Z",
          "endDate": "2022-05-18T22:00:00.000Z",
          "key": "selection"
      }
    ]
     */
    setHolidayStartDate(stateDate[0].startDate)
    setHolidayEndDate(stateDate[0].endDate)
  }, [stateDate])

  const [note, setNote] = useState('')
  const [absenceListData, setAbsenceListData] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [totalRows, setTotalRows] = React.useState(0)
  const [hoursOff, setHoursOff] = useState(1)
  const [minutesOff, setMinutesOff] = useState(0)
  const [permissionType, setPermissionType] = useState('PERMESSO')

  const initialTime = new Date()
  if (initialTime.getHours() < 8 || initialTime.getHours() >= 18) {
    initialTime.setHours(8, 0, 0, 0)
  }
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedTime, setSelectedTime] = useState(initialTime)

  const [selectedCliente, setSelectedCliente] = useState('')
  const [selectedCommessa, setSelectedCommessa] = useState('')

  const getMinTime = (date) => {
    const minTime = new Date(date)
    minTime.setHours(8, 0, 0, 0)
    return minTime
  }

  const getMaxTime = (date) => {
    const maxTime = new Date(date)
    maxTime.setHours(18, 0, 0, 0)
    return maxTime
  }

  const handleClickOpen = () => {
    setChecked(false);
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    resetFields();
  }
  const handleChangeAbsenceType = (event) => {
    setAbsenceType(event.target.value)
    setChecked(false);
    setNote('')
  }
  const handleChangeStartDate = (newValue) => {
    setHolidayStartDate(newValue)
  }
  const handleChangeEndDate = (newValue) => {
    setHolidayEndDate(newValue)
  }
  const handleChangeHoursOff = (newValue) => {
    setHoursOff(newValue.target.value)
  }
  const handleChangeMinutesOff = (newValue) => {
    setMinutesOff(newValue.target.value)
  }
  const handleChangePermissionType = (newValue) => {
    setPermissionType(newValue.target.value)
  }
  // const handleChangeHoursOffDate = (newValue) => {
  //   setHoursOffDate(newValue)
  // }
  const handleChangeNote = (event) => {
    setNote(event.target.value)
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const resetFields = () => {
    setAbsenceType('ferie')
    const newDate = new Date()
    setHolidayStartDate(newDate)
    setHolidayEndDate(newDate)
    // setHoursOffDate(newDate);
    setSelectedDate(newDate)
    setSelectedTime(newDate)
    setSelectedCliente('')
    setSelectedCommessa('')
    setHoursOff(1)
    setMinutesOff(0)
    setPermissionType('PERMESSO')
    setNote('')
    setChecked(false)
    setStateDate([
      {
        startDate: newDate,
        endDate: addDays(newDate, 1),
        key: 'selection',
      },
    ])
  }

  const [loading, setLoading] = useState(false)

  useOrientationEffects(isLandscape)

  const handleRefreshRows = () => {
    setLoading(true)
    const queryParams = {
      page,
      size: rowsPerPage,
    }

    getAbsences(queryParams)
      .then((data) => {
        // console.log("🚀 ~ .then ~ data:", data)
        setAbsenceListData(data.content || [])
        setTotalRows(data.totalElements || 0)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Failed to fetch absences:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    handleRefreshRows()
  }, [page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [clientiCommesse, setClientiCommesse] = useState([])

  useEffect(() => {
    getClientiCommesse(username).then((response) => setClientiCommesse(response.commesse))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeCliente = (event) => {
    const [cliente, commessa] = event.target.value.split('-')
    setSelectedCliente(cliente)
    setSelectedCommessa(commessa)
  }

  const computeValue = () => {
    return selectedCliente && selectedCommessa ? `${selectedCliente}-${selectedCommessa}` : ''
  }

  const handleSendData = () => {
    if (!selectedCliente || !selectedCommessa) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: 'I campi Cliente e Commessa sono obbligatori.',
        confirmButtonColor: '#dc3545',
        customClass: {
          container: 'sweet-alert-container',
        },
      })
      return
    }

    const clienteCommessa = clientiCommesse.find(
      (item) => item.cu_cliente === selectedCliente && item.cu_commessa === selectedCommessa
    )

    const cu_desc_cliente = clienteCommessa ? clienteCommessa.cu_desc_cliente : ''
    const cu_desc_commessa = clienteCommessa ? clienteCommessa.cu_desc_commessa : ''

    setIsSending(true)
    Swal.fire({
      title: 'Attendere prego',
      html: `<img src="${TIicon}" class="my-custom-spinner" alt="Caricamento..." >`,
      allowOutsideClick: false,
      showConfirmButton: false,
      ...(isMobileOnly
        ? {
          customClass: {
            container: 'sweet-alert-container',
            popup: 'sweet-popup-custom-width-mobile',
          },
        }
        : {
          customClass: {
            container: 'sweet-alert-container',
            htmlContainer: 'swal2-html-container',
            popup: 'sweet-popup-custom-width',
          },
        }),
    })

    const getCombinedDateTime = () => {
      return new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTime.getHours(),
        selectedTime.getMinutes()
      )
    }

    let absence = {
      id: uuidv4(),
      owner: username,
      type: absenceType,
      startDate: null,
      endDate: null,
      permissionType: null,
      permissionDurationHours: null,
      permissionDurationMinutes: null,
      note: note,
      cu_cliente: selectedCliente,
      cu_commessa: selectedCommessa,
      cu_desc_cliente: cu_desc_cliente,
      cu_desc_commessa: cu_desc_commessa,
    }

    if (absenceType === 'ferie') {
      absence.startDate = formatISO(set(holidayStartDate, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }))
      absence.endDate = formatISO(set(holidayEndDate, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }))
      absence.permissionType = null
      absence.permissionDurationHours = null
      absence.permissionDurationMinutes = null
    } else {
      const combinedDateTime = getCombinedDateTime()
      absence.startDate = formatISO(combinedDateTime)
      absence.endDate = null
      absence.permissionType = permissionType
      absence.permissionDurationHours = hoursOff
      absence.permissionDurationMinutes = minutesOff
    }

    postAbsence(absence)
      .then((result) => {
        setIsSending(false)
        setOpen(false)
        if (result.res === 'success') {
          setAbsenceListData([result.absence, ...absenceListData])
          resetFields()
          Swal.fire({
            toast: true,
            position: 'center',
            icon: 'success',
            title: 'Fatto',
            text: 'Richiesta salvata correttamente!',
            confirmButtonColor: '#289b38',
            customClass: {
              container: 'sweet-alert-container',
            },
          })
        } else {
          Swal.fire({
            toast: true,
            position: 'center',
            icon: 'error',
            title: 'Errore',
            text: 'Si è verificato un problema! ' + JSON.stringify(result.errors),
            footer: "Se l'errore persiste contatta l'amministratore",
          })
        }
      })
      .catch((error) => {
        // eventuali errori nella chiamata fetch o nel server
        setIsSending(false)
        setOpen(false)
        Swal.fire({
          toast: true,
          position: 'center',
          icon: 'error',
          title: 'Errore',
          text: "Si è verificato un errore durante l'invio della richiesta: " + error.toString(),
          footer: "Se l'errore persiste contatta l'amministratore",
        })
      })
  }

  const columns = [
    {
      field: 'type',
      headerName: 'Tipo',
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'startDate',
      headerName: 'Inizio',
      valueGetter: formatTableStartDate,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'endDate',
      headerName: 'Fine/Durata',
      valueGetter: formatTableEndDateOrDuration,
      flex: 1,
      minWidth: 100,
    },
    { field: 'note', headerName: 'Note', type: 'string', flex: 1, minWidth: 100 },
    {
      field: 'status',
      headerName: 'Stato',
      valueGetter: formatTableStatus,
      flex: 1,
      minWidth: 100,
    },
    { field: 'actions', headerName: 'Azioni', flex: 0.5, minWidth: 100 },
  ]

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    if (isToday(newDate)) {
      setSelectedTime(new Date())
    } else {
      setSelectedTime(new Date(newDate.setHours(8, 0, 0, 0)))
    }
  }

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime)
  }

  const shouldDisableTime = (timeValue, clockType) => {
    const now = new Date()
    if (isToday(selectedDate)) {
      if (clockType === 'hours' && timeValue < now.getHours()) {
        return true
      } else if (clockType === 'minutes') {
        return selectedTime.getHours() === now.getHours() && timeValue < now.getMinutes()
      }
    }
    return false
  }

  return (
    <Grid
      // container
      xs={12}
      sx={{
        mt: '2.5em',
        mb: 2,
        ml: { xs: 1 },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        bgcolor: '',
      }}
    >
      <Grid xs={11.5}>
        <h2>Ferie - Permessi</h2>
        <div>
          <Button variant="outlined" onClick={handleClickOpen}>
            Crea una nuova richiesta
          </Button>
        </div>

        <h3>Le tue richieste</h3>
        <div style={{ height: '70vh' }}>
          <DataGrid
            rows={absenceListData || []}
            columns={columns}
            loading={loading}
            getRowClassName={(params) => `absence-row-${params.row.status}`}
            pagination
            pageSize={rowsPerPage}
            rowsPerPageOptions={[5, 15, 25, 100]}
            rowCount={totalRows}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            getRowId={(row) => row._id}
          />
        </div>
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Nuova richiesta</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-label="tipology"
                  name="controlled-radio-buttons-group"
                  className="radio-request-type"
                  value={absenceType}
                  onChange={handleChangeAbsenceType}
                >
                  <FormControlLabel value="ferie" control={<Radio />} label="Ferie" />
                  <FormControlLabel value="permesso" control={<Radio />} label="Permesso" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Box>
          <FormControl
            component="fieldset"
            className="form-request"
            sx={{
              width: '100%',
              p: 2,
              mb: 2,
              borderColor: 'lightgrey',
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: 1,
            }}
          >
            <FormLabel component="legend" sx={{ color: 'gray', mb: 2, '&.Mui-focused': { color: 'gray' } }}>
              Cliente/commesse
            </FormLabel>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={8} md={10}>
                  <TextField
                    id="client"
                    className="fullWidth"
                    required
                    select
                    label="Cliente - Commessa"
                    value={computeValue()}
                    onChange={handleChangeCliente}
                    fullWidth
                  >
                    {Array.isArray(clientiCommesse) ? (
                      clientiCommesse.map((item, index) => (
                        <MenuItem key={index} value={`${item.cu_cliente}-${item.cu_commessa}`}>
                          {item.cu_desc_cliente} - {item.cu_desc_commessa}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>Caricamento dati...</MenuItem>
                    )}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
          <FormControl
            component="fieldset"
            className="form-request"
            sx={{
              width: '100%',
              p: 2,
              borderColor: 'lightgrey',
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: 1,
            }}
          >
            {absenceType === 'ferie' && (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} alignItems="center" justifyContent="center">
                  <Grid
                    width={'100%'}
                    item
                    xs={12}
                    md={12}
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <DateRange
                      className="dataRangeWidth"
                      locale={it}
                      dateDisplayFormat="dd/MM/yyyy"
                      editableDateInputs={true}
                      onChange={(item) => setStateDate([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={stateDate}
                      months={monthToDisplay}
                      direction="horizontal"
                      scroll={{ enabled: false }}
                      minDate={new Date()}
                      rangeColors={['#289b38']}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Note"
                      className="fullWidth"
                      variant="outlined"
                      multiline
                      rows={1}
                      value={note}
                      onChange={handleChangeNote}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            <FormLabel component="legend" sx={{ color: 'gray', mb: 2, '&.Mui-focused': { color: 'gray' } }}>
              Configurazione
            </FormLabel>
            {absenceType === 'permesso' && (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={8} md={4}>
                      <DatePicker
                        label="Data"
                        className="fullWidth"
                        disablePast={true}
                        inputFormat="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <TimePicker
                        label="Ora inizio"
                        className="fullWidth"
                        value={selectedTime}
                        onChange={handleTimeChange}
                        minutesStep={15}
                        minTime={getMinTime(selectedDate)}
                        maxTime={getMaxTime(selectedDate)}
                        shouldDisableTime={shouldDisableTime}
                        renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                        ampm={false}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <TextField
                        id="permissionDurationHours"
                        className="fullWidth"
                        select
                        label="Ore"
                        value={hoursOff}
                        onChange={handleChangeHoursOff}
                      >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <TextField
                        id="permissionDurationMinutes"
                        className="fullWidth"
                        select
                        label="Minuti"
                        value={minutesOff}
                        onChange={handleChangeMinutesOff}
                      >
                        <MenuItem value={0}>00</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <TextField
                        id="permissionType"
                        className="fullWidth"
                        select
                        label="Tipologia"
                        value={permissionType}
                        onChange={handleChangePermissionType}
                      >
                        <MenuItem value={'PERMESSO'}>PERMESSO</MenuItem>
                        <MenuItem value={'PERM.ESAMI UNIVERSITARI'}> PERM.ESAMI UNIVERSITARI</MenuItem>
                        <MenuItem value={'PERMESSO 104'}>PERMESSO 104</MenuItem>
                        <MenuItem value={'DONAZIONE'}>DONAZIONE SANGUE</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={8} md={6}>
                      <TextField
                        label="Note"
                        variant="outlined"
                        className="note"
                        multiline
                        rows={1}
                        value={note}
                        onChange={handleChangeNote}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </LocalizationProvider>
            )}
          </FormControl>
          <Grid item xs={8} md={10}>
            <StyledFormControlLabel
              control={
                <StyledCheckbox
                  checked={checked}
                  onChange={handleChange}
                  name="absenceDeclaration"
                />
              }
              label={
                <Typography>
                  Assenza concordata con il referente
                </Typography>
              }
              checked={checked}
            />
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isSending}>
            Annulla
          </Button>
          <Button onClick={handleSendData} disabled={isSending || selectedCliente === '' || !checked }>
            Invia
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollRestoration />
    </Grid>
    // </div>
  )
}
